import React, { useEffect, useState } from "react";

import InfantTable from "./Components/InfantTable";
import { useLocation } from "react-router-dom";

import PatientTable from "./Components/PatientTable";
export default function Patient() {
  let { search } = useLocation();

  const [filtered, setFiltered] = useState("patient");
  const onFilter = (e) => {
    let { value } = e.target;
    setFiltered(value);
    if (search === "?infant") {
      if (value === "patient") {
        setFiltered("patient");
      } else if (value === "census") {
        setFiltered("census");
      }
    }
  };
  function capitalize(str) {
    return str.charAt(0).toUpperCase() + str.slice(1);
  }

  useEffect(() => {
    if (search == "?infant") setFiltered("infant");
  }, [search]);

  return (
    <>
      <div className="app-main__inner">
        <div className="app-page-title pt-10">
          <div className="page-title-wrapper">
            <div className="page-title-heading">
              <div className="card-body p-0">
                <div className="mr-2 btn-group">
                  <h5 className="page-title">
                    {capitalize(filtered)} List {} |  {" "}
                    <span className="font-14">Filter By</span>
                  </h5>
                </div>
                <div
                  className="mr-2 btn-group"
                  style={{ marginTop: "-5px", width: "210px" }}
                >
                  <select
                    onChange={onFilter}
                    name="select"
                    id="exampleSelect"
                    className="form-control custom-sort"
                  >
                    <option value="patient">Normal Patient</option>
                    <option
                      value="infant"
                      selected={search == "?infant" ? "Selected" : ""}
                    >
                      Infant
                    </option>
                    <option
                      value="census"
                      selected={search == "?census" ? "Selected" : ""}
                    >
                      Census
                    </option>
                  </select>
                </div>
              </div>
            </div>
          </div>
        </div>
        {filtered === "patient" ? <PatientTable /> : <InfantTable />}
      </div>
    </>
  );
}
