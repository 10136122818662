import {
  PATIENT_DELETED,
  PATIENT_FETCH,
  PATIENT_FETCHED,
  PATIENT_SINGLE_FETCH,
  PATIENT_SINGLE_FETCHED,
  PATIENT_UPDATE,
  PATIENT_UPDATE_FAILURE,
  PATIENT_UPDATE_SUCCESS,
} from "../constants/types";

const initialValue = {
  patients: [],
  total: 0,
  loading: false,
  patient: undefined,
  updating: false,
};

function reducer(state = initialValue, action) {
  switch (action.type) {
    case PATIENT_FETCH:
      return {
        loading: true,
      };
    case PATIENT_FETCHED:
      return {
        ...state,
        loading: false,
        patients: action.payload.patients,
        total: action.payload.total,
      };
    case PATIENT_SINGLE_FETCH:
      return {
        loading: true,
      };
    case PATIENT_SINGLE_FETCHED:
      return {
        ...state,
        loading: false,
        patient: action.payload.data,
      };

    case PATIENT_UPDATE:
      return {
        ...state,
        updating: true,
      };

    case PATIENT_UPDATE_SUCCESS:
      return {
        ...state,
        updating: false,
      };
    case PATIENT_UPDATE_FAILURE:
      return {
        ...state,
        updating: false,
      };

    case PATIENT_DELETED:
      const deleted = state.patients.filter(
        (item) => item.patientId !== action.payload
      );
      const total = state.total - 1;

      return {
        ...state,
        patients: deleted,
        total,
      };
    default:
      return {
        ...state,
      };
  }
}

export default reducer;
