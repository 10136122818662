import React from "react";
import CensusTable from "./Components/CensusTable";

export default function Census() {
  return (
    <>
      {" "}
      <div className="app-main__inner">
        <div className="app-page-title pt-10">
          <div className="page-title-wrapper">
            <div className="page-title-heading">
              <div className="card-body p-0">
                <div className="mr-2 btn-group">
                  <h5 className="page-title">Census List </h5>
                </div>
              </div>
            </div>
          </div>
        </div>
        <CensusTable />
      </div>
    </>
  );
}
