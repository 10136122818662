import {
  PATIENT_LOCATION_FETCH,
  PATIENT_LOCATION_FETCHED,
  CENSUS_LOCATION_FETCH,
  CENSUS_LOCATION_FETCHED,
  CENSUS_CHART_FETCH,
  CENSUS_CHART_FETCHED
} from "./../constants/types";

const initialValue = {
  patient: {
    loading: false,
    data: null,
  },
  census: {
    loading: false,
    data: null,
  },
  chart: {
    loading: false,
    data: null,
  }
};

function reducer(state = initialValue, action) {
  switch (action.type) {
    case PATIENT_LOCATION_FETCH:
      return {
        ...state,
        patient: {
          loading: true,
        },
      };
    case PATIENT_LOCATION_FETCHED:
      return {
        ...state,
        patient: {
          loading: false,
          data: action.payload.patients,
        },
      };

    case CENSUS_LOCATION_FETCH:
      return {
        ...state,
        census: {
          loading: true,
        },
      };
    case CENSUS_LOCATION_FETCHED:
      return {
        ...state,
        census: {
          loading: false,
          data: action.payload.census,
        },
      };
    case CENSUS_CHART_FETCH:
      return {
        ...state,
        chart: {
          loading: true,
        }
      }
    case CENSUS_CHART_FETCHED: 
    return {
      ...state,
      chart: {
        loading: false,
        data: action.payload.chart
      }
    }
    default:
      return {
        ...state,
      };
  }
}

export default reducer;


