import React, { useState } from "react";
import CHWInfantTable from "./CHWInfantTable";
import CHWPatientTable from "./CHWPatientTable";
import { Link } from "react-router-dom";
import CHWCensusTable from "./CHWCensusTable";
import { useSelector } from "react-redux";
export default function CHWPatientList() {
  const { total } = useSelector((state) => state.staff);

  const [filtered, setFiltered] = useState("patient");
  const onFilter = (e) => {
    let { value } = e.target;
    setFiltered(value);
  };
  function capitalize(str) {
    return str.charAt(0).toUpperCase() + str.slice(1);
  }
  return (
    <>
      <div className="app-main__inner">
        <div className="app-page-title pt-10">
          <div className="page-title-wrapper">
            <div className="page-title-heading">
              <div className="card-body p-0">
                <h5 className="page-title">
                  <Link to="/staff">Staff List</Link> &emsp;{" "}
                  <i className="fas fa-angle-right"></i> &emsp;
                  {capitalize(filtered)} List {total && `(${total})` }   |  {" "}
                  <span className="font-14">Filter By</span>
                  <div
                    className="mr-2 btn-group"
                    style={{ marginTop: "-5px", width: "210px" }}
                  >
                    <select
                      onChange={onFilter}
                      name="select"
                      id="exampleSelect"
                      className="form-control custom-sort"
                    >
                      <option value="patient">Normal Patient</option>
                      <option value="infant">Infant</option>
                      <option value="census">Census</option>
                    </select>
                  </div>
                </h5>
              </div>
            </div>
          </div>
        </div>
        {filtered === "patient" && <CHWPatientTable />}
        {filtered === "infant" && <CHWInfantTable />}
        {filtered === "census" && <CHWCensusTable />}

      </div>
    </>
  );
}
