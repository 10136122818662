import React from "react";
import { Outlet, Link, useLocation } from "react-router-dom";
import Footer from "./Footer";
import logo from "./../assets/images/logo/logo.png";
import { useDispatch } from "react-redux";
import { logout } from "../redux/actions/auth.action";
export default function Nav() {
  const location = useLocation();
  const dispatch = useDispatch();
  const path = location.pathname;

  const userLogout = () => {
    dispatch(logout());
  };
  return (
    <>
      <div className="app-main">
        <div className="app-sidebar sidebar-shadow">
          <div className="scrollbar-sidebar">
            <div className="app-sidebar__inner">
              <div>
                <img
                  className="logo-non-hover"
                  src={logo}
                  style={{ maxWidth: "40px", marginTop: "-80px" }}
                />
              </div>
              <ul className="vertical-nav-menu mt--20">
                <li className="app-sidebar__heading">Medidoc Menu</li>
                <li className="dashboard">
                  <Link
                    to="/"
                    data-placement="right"
                    title="Dashboard"
                    className={path === "/" ? "mm-active" : ""}
                  >
                    <i className="metismenu-icon flaticon-home"></i>
                    <p>Dashboard</p>
                  </Link>
                </li>
                <li>
                  <Link
                    to="/patient"
                    data-placement="right"
                    title="Patients"
                    className={
                      path === "/patient" ||
                      path.includes("/patient_detail") ||
                      path.includes("/infant_detail") ||
                      path.includes("/patient/pupdate") ||
                      path.includes("/patient/iupdate")
                        ? "mm-active"
                        : ""
                    }
                  >
                    <i className="metismenu-icon flaticon-user"></i>
                    Patients
                  </Link>
                </li>

                <li>
                  <Link
                    to="/staff"
                    data-toggle="tooltip"
                    data-placement="right"
                    title="Staffs"
                    className={
                      path === "/staff" ||
                      path.includes("/create") ||
                      path.includes("/spatient_detail") ||
                      path.includes("/sinfant_detail") ||
                      path.includes("/patient_list") ||
                      path.includes("/update") ||
                      path.includes("/staff/pupdate") ||
                      path.includes("staff/iupdate")
                        ? "mm-active"
                        : ""
                    }
                  >
                    <i className="metismenu-icon flaticon-doctor"></i>
                    Staffs
                  </Link>
                </li>
                <li>
                  <Link
                    to="/census"
                    data-toggle="tooltip"
                    data-placement="right"
                    title="Census"
                    className={
                      path === "/census" || path.includes("/census")
                        ? "mm-active"
                        : ""
                    }
                  >
                    <i className="metismenu-icon flaticon-doctor-2"></i>
                    Census
                  </Link>
                </li>
                {/* <li>
                  <Link
                    to="/message"
                    data-toggle="tooltip"
                    data-placement="right"
                    title="Message"
                    className={path === "/message" ? "mm-active" : ""}
                  >
                    <i className="metismenu-icon flaticon-conversation"></i>
                    Message
                  </Link>
                </li> */}

                {/* <li>
                  {" "}
                  <Link
                    to="/payment"
                    data-toggle="tooltip"
                    data-placement="right"
                    title="Payment"
                    className={path === "/payment" ? "mm-active" : ""}
                  >
                    <i className="metismenu-icon flaticon-money-bag"></i>
                    Payment
                  </Link>
                </li> */}
                {/* <li>
                  {" "}
                  <Link
                    to="/schedule"
                    data-toggle="tooltip"
                    data-placement="right"
                    title="Schedule"
                    className={path === "/schedule" ? "mm-active" : ""}
                  >
                    <i className="metismenu-icon flaticon-calendar"></i>
                    Schedule
                  </Link>
                </li> */}

                <li className="app-sidebar__heading">Other</li>
                <li>
                  {" "}
                  <Link
                    to="/analytics"
                    data-toggle="tooltip"
                    data-placement="right"
                    title="Analytics"
                    className={path === "/analytics" ? "mm-active" : ""}
                  >
                    <i className="metismenu-icon flaticon-nucleus"></i>
                    Analytics
                  </Link>
                </li>
                <li>
                  {" "}
                  <Link
                    to="/settings"
                    data-toggle="tooltip"
                    data-placement="right"
                    title="Settings"
                    className={path === "/settings" ? "mm-active" : ""}
                  >
                    <i className="metismenu-icon flaticon-gear"></i>
                    Settings
                  </Link>
                </li>
                <li>
                  {" "}
                  <Link
                    to="#"
                    data-toggle="tooltip"
                    data-placement="right"
                    title="Logout"
                    onClick={userLogout}
                  >
                    <i className="metismenu-icon flaticon-logout"></i>
                    Logout
                  </Link>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div className="app-main__outer mt--60">
          <Outlet />
          <Footer />
        </div>
      </div>
    </>
  );
}
