import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchCensusSingle,
  updateCensus,
} from "../../../redux/actions/census.action";
import { useParams, Link } from "react-router-dom";
import { useForm } from "react-hook-form";

export default function CUpdate() {
  const { singleCensus, updating } = useSelector((state) => state.census);

  const { id } = useParams();
  const dispatch = useDispatch();
  const { register, handleSubmit, reset } = useForm();

  const [gender, setGender] = useState();

  const onSubmit = async (data) => {
    const finalData = {};
    finalData.resident = {
      resident_first_name: data.resident_first_name,
      resident_middle_name: data.resident_middle_name,
      resident_last_name: data.resident_last_name,
      longitude: data.longitude,
      latitude: data.latitude,
    };
    finalData.residentAddress = {
      resident_city: data.resident_city,
      resident_district: data.resident_district,
      resident_province: data.resident_province,
      resident_village: data.resident_village,
      resident_ward: data.resident_ward,
    };
    finalData.residentDetail = {
      resident_age: data.resident_age,
      resident_is_resident_living_there: data.resident_is_resident_living_there,
      resident_marital_status: data.resident_marital_status,
      resident_gender: gender,
    };
    finalData.residentHead = {
      resident_head_first_name: data.resident_head_first_name,
      resident_head_last_name: data.resident_head_last_name,
      resident_head_middle_name: data.resident_head_middle_name,
      resident_relation_with_househead: data.resident_relation_with_househead,
    };
    finalData.residentDetailFinal = {
      resident_ethnicity: data.resident_ethnicity,
      resident_no_of_people: data.resident_no_of_people,
      resident_taken_interview_previously:
        data.resident_taken_interview_previously,
      resident_respondent_aggreement: data.resident_respondent_aggreement,
    };
    finalData.residentId = data.residentId;

    dispatch(updateCensus(finalData));
  };
  useEffect(() => {
    reset(
      singleCensus
        ? {
            creator: singleCensus?.user?.chw_name || "",

            //resident
            resident_first_name:
              singleCensus?.resident?.resident_first_name || "",
            resident_last_name:
              singleCensus?.resident?.resident_last_name || "",
            resident_middle_name:
              singleCensus?.resident?.resident_middle_name || "",
            longitude: singleCensus?.resident?.longitude || "",
            latitude: singleCensus?.resident?.latitude || "",

            //residentAddress
            resident_city: singleCensus?.residentAddress?.resident_city || "",
            resident_district:
              singleCensus?.residentAddress?.resident_district || "",
            resident_province:
              singleCensus?.residentAddress?.resident_province || "",
            resident_village:
              singleCensus?.residentAddress?.resident_village || "",
            resident_ward: singleCensus?.residentAddress?.resident_ward || "",

            //residentDetail
            resident_age: singleCensus?.residentDetail?.resident_age || "",
            resident_is_resident_living_there:
              singleCensus?.residentDetail?.resident_is_resident_living_there,
            resident_marital_status:
              singleCensus?.residentDetail?.resident_marital_status,

            residentId: singleCensus?.residentId,
            enteredDateTime:
              singleCensus?.enteredDateTime?.dateTime?.date?.year +
              "-" +
              singleCensus?.enteredDateTime?.dateTime?.date?.month +
              "-" +
              singleCensus?.enteredDateTime?.dateTime?.date?.day,
            residentAssignId: singleCensus?.residentAssignId,

            //residentHead
            resident_head_first_name:
              singleCensus?.residentHead?.resident_head_first_name,
            resident_head_last_name:
              singleCensus?.residentHead?.resident_head_last_name,
            resident_head_middle_name:
              singleCensus?.residentHead?.resident_head_middle_name,
            resident_relation_with_househead:
              singleCensus?.residentHead?.resident_relation_with_househead,

            //final
            resident_ethnicity:
              singleCensus?.residentDetailFinal?.resident_ethnicity,
            resident_no_of_people:
              singleCensus?.residentDetailFinal?.resident_no_of_people,
            resident_taken_interview_previously:
              singleCensus?.residentDetailFinal
                ?.resident_taken_interview_previously,
            resident_respondent_aggreement:
              singleCensus?.residentDetailFinal?.resident_respondent_aggreement,
          }
        : {}
    );
    setGender(singleCensus?.residentDetail?.resident_gender);
  }, [singleCensus]);

  useEffect(() => {
    dispatch(fetchCensusSingle(id, true));
  }, [dispatch]);

  return (
    <>
      <div className="app-main__inner">
        <div className="app-page-title pt-10">
          <div className="page-title-wrapper">
            <div className="page-title-heading">
              <div className="card-body p-0">
                <ul className="body-tabs body-tabs-layout tabs-animated body-tabs-animated nav">
                  <li className="nav-item">
                    <h5
                      style={{
                        marginTop: "16px",
                        fontSize: "18px",
                        fontWeight: "700",
                        paddingBottom: "10px",
                      }}
                    >
                      <Link to="/census">Census</Link> &emsp;
                      <i className="fas fa-angle-right"></i> &emsp;
                    </h5>
                  </li>
                  <li className="nav-item">
                    <Link
                      role="tab"
                      className="nav-link active font-small-14 bold"
                      id="tab-0"
                      data-toggle="tab"
                      to="#"
                    >
                      <span>Update</span>
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
            <div className="page-title-actions pt-20">
              <div className="d-inline-block">
                <div className="d-inline-block">
                  <Link to="/census" className="mb-2 mr-2 btn btn-light">
                    <span className="btn-icon-wrapper pr-2 opacity-7">
                      <i className="fas fa-chevron-left"></i>
                    </span>
                    Cancel
                  </Link>
                  {updating ? (
                    <>
                      <Link
                        className="mb-2 mr-2 btn btn-success"
                        to=""
                        onClick={handleSubmit(onSubmit)}
                        disabled
                      >
                        <span className="btn-icon-wrapper pr-2 opacity-7">
                          <i className="fas fa-check"></i>
                        </span>
                        Saving Changes
                      </Link>
                    </>
                  ) : (
                    <>
                      <Link
                        className="mb-2 mr-2 btn btn-success"
                        to=""
                        onClick={handleSubmit(onSubmit)}
                      >
                        <span className="btn-icon-wrapper pr-2 opacity-7">
                          <i className="fas fa-check"></i>
                        </span>
                        Save Changes
                      </Link>
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="app" style={{ height: "90vh !important" }}>
        <form className="wrapper">
          <div className="conversation-area">
            <div className="p-20 pb-10">
              <h6
                className="page-title"
                style={{
                  fontSize: "18px",
                  fontWeight: "700",
                  paddingBottom: "10px",
                  paddingLeft: "10px",
                }}
              >
                Resident Meta Info
              </h6>
            </div>
            <div className="mb-3 mt--20 ml-10">
              <div className="card-body">
                <label>Resident Profile</label>
                <div className="row">
                  <div className="col-md-6">
                    <div className="d-flex flex-column flex-sm-row justify-content-between mb-3">
                      <div className="text-left text-sm-left mb-2 mb-sm-0">
                        <div className="pb-20">
                          <label htmlFor="article-title">Assign Id</label>
                          <input
                            type="text"
                            className="form-control"
                            disabled
                            {...register("residentAssignId")}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="d-flex flex-column flex-sm-row justify-content-between mb-3">
                      <div className="text-left text-sm-left mb-2 mb-sm-0">
                        <div className="pb-20">
                          <label htmlFor="article-title">Resident Id</label>
                          <input
                            type="text"
                            className="form-control"
                            disabled
                            {...register("residentId")}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="position-relative form-group">
                  <label htmlFor="article-owner" className="">
                    Added By
                  </label>
                  <input
                    name="owner"
                    id="article-owner"
                    type="text"
                    className="form-control"
                    {...register("creator")}
                    disabled
                  />
                </div>

                <div className="position-relative form-group">
                  <label className="">Added Date</label>
                  <input
                    type="text"
                    className="form-control"
                    {...register("enteredDateTime")}
                    disabled
                  />
                </div>
                <div className="position-relative form-group">
                  <label className="">Latitude</label>
                  <input
                    type="text"
                    className="form-control"
                    {...register("latitude")}
                    disabled
                  />
                </div>
                <div className="position-relative form-group">
                  <label className="">Longitude</label>
                  <input
                    type="text"
                    className="form-control"
                    {...register("longitude")}
                    disabled
                  />
                </div>
              </div>
            </div>
          </div>
          <div
            className="chat-area"
            style={{ background: "white", paddingLeft: "20px" }}
          >
            <div className="chat-area-header">
              <div className="row w-100">
                <div className="px-0 pb-0 mb-3">
                  <div className="form-card p-1333">
                    <div className="row">
                      <div className="col-md-12">
                        <h6 className="text-uppercase">Resident Information</h6>
                        <hr />
                      </div>
                      <div className="col-md-4 pb-20">
                        <label htmlFor="article-title">First Name</label>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Enter first name"
                          {...register("resident_first_name")}
                        />
                      </div>
                      <div className="col-md-4 pb-20">
                        <label htmlFor="article-title">Middle Name</label>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Enter middle name"
                          {...register("resident_middle_name")}
                        />
                      </div>
                      <div className="col-md-4 pb-20">
                        <label htmlFor="article-title">Last Name</label>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Enter last name"
                          required=""
                          {...register("resident_last_name")}
                        />
                      </div>
                      <div className="col-md-4 pb-20">
                        <label htmlFor="article-expiry" className="">
                          Age
                        </label>
                        <input
                          name="article-expiry"
                          id="resident_age"
                          type="number"
                          className="form-control"
                          {...register("resident_age")}
                        />
                      </div>
                      <div className="col-md-4 pb-20">
                        <label htmlFor="article-expiry" className="">
                          Marital Status
                        </label>
                        <select
                          id="resident-marital-status"
                          className="form-control"
                          {...register("resident_marital_status")}
                        >
                          <option value="Married_or_Living_Together">
                            Married or Living Together
                          </option>
                          <option value="Divorced_or_Separated">
                            Divorced/ Separated
                          </option>
                          <option value="Widowed">Widowed</option>
                          <option value="Never_married_and_never_lived_together">
                            Never-married and never lived together
                          </option>
                        </select>
                      </div>
                      <div className="col-md-4 pb-20">
                        <label htmlFor="article-expiry" className="">
                          Is Resident Living There?
                        </label>
                        <select
                          id="resident-living-there"
                          className="form-control"
                          {...register("resident_is_resident_living_there")}
                        >
                          <option value="YES">Yes</option>
                          <option value="NO">No</option>
                        </select>
                      </div>
                      <div className="col-md-6">
                        <label>Gender at Birth</label>
                        <div className="custom-radio custom-control">
                          <input
                            type="radio"
                            id="g-male"
                            value="Male"
                            checked={gender == "Male"}
                            name="customRadio"
                            className="custom-control-input"
                            onChange={(e) => setGender(e.currentTarget.value)}
                          />
                          <label
                            className="custom-control-label"
                            htmlFor="g-male"
                          >
                            Male
                          </label>
                        </div>
                        <div className="custom-radio custom-control">
                          <input
                            type="radio"
                            id="g-femlae"
                            value="Female"
                            checked={gender == "Female"}
                            name="customRadio"
                            className="custom-control-input"
                            onChange={(e) => setGender(e.currentTarget.value)}
                          />
                          <label
                            className="custom-control-label"
                            htmlFor="g-femlae"
                          >
                            Female
                          </label>
                        </div>
                        <div className="custom-radio custom-control">
                          <input
                            type="radio"
                            id="g-femlae"
                            value="Female"
                            checked={gender == "Other"}
                            name="customRadio"
                            className="custom-control-input"
                            onChange={(e) => setGender(e.currentTarget.value)}
                          />
                          <label
                            className="custom-control-label"
                            htmlFor="g-femlae"
                          >
                            Other
                          </label>
                        </div>
                      </div>
                      <div className="col-md-12 pt-30">
                        <h6 className="text-uppercase">
                          Resident Head Information
                        </h6>
                        <hr />
                      </div>
                      <div className="col-md-4 pb-20">
                        <label htmlFor="article-title">First Name</label>
                        <input
                          type="text"
                          className="form-control"
                          {...register("resident_head_first_name")}
                        />
                      </div>
                      <div className="col-md-4 pb-20">
                        <label htmlFor="article-title">Middle Name</label>
                        <input
                          type="text"
                          className="form-control"
                          {...register("resident_head_middle_name")}
                        />
                      </div>
                      <div className="col-md-4 pb-20">
                        <label htmlFor="article-title">Last Name</label>
                        <input
                          type="text"
                          className="form-control"
                          {...register("resident_head_last_name")}
                        />
                      </div>
                      <div className="col-md-4 pb-20">
                        <label htmlFor="article-title">
                          Relation with Resident Head
                        </label>
                        <select
                          id="resident-living-there"
                          className="form-control"
                          {...register("resident_relation_with_househead")}
                        >
                          <option value="HEAD">Head</option>
                          <option value="Wife_or_Husband">
                            Wife or Husband
                          </option>
                          <option value="Son_or_Daughter">
                            Son or Daughter
                          </option>
                          <option value="SonInLaw_or_DaughterInLaw">
                            Son-In-Law or Daughter-In-Law
                          </option>
                          <option value="Grandchild">Grandchild</option>
                          <option value="Brother_or_Sister">
                            Brother or Sister
                          </option>
                          <option value="Other_Relative">Other Relative</option>
                          <option value="Adopted_or_Foster_or_Stepchild">
                            Adopted/Foster/Stepchild
                          </option>
                          <option value="Not_Related">Not Related</option>
                          <option value="Dont_know">Don't know</option>
                        </select>
                      </div>
                      <div className="col-md-12 pt-20">
                        <h6 className="text-uppercase">Address Information</h6>
                        <hr />
                      </div>
                      <div className="col-md-4 pb-20">
                        <label htmlFor="municipality" className="">
                          Province
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          {...register("resident_province")}
                        />
                      </div>
                      <div className="col-md-4 pb-20">
                        <label htmlFor="municipality" className="">
                          District
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          {...register("resident_district")}
                        />
                      </div>
                      <div className="col-md-4 pb-20">
                        <label htmlFor="municipality" className="">
                          Municipality
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          {...register("resident_city")}
                        />
                      </div>
                      <div className="col-md-4 pb-20">
                        <label htmlFor="article-title">Village</label>
                        <input
                          type="text"
                          className="form-control"
                          {...register("resident_village")}
                        />
                      </div>
                      <div className="col-md-4 pb-20">
                        <label htmlFor="article-title">Ward</label>
                        <input
                          type="number"
                          className="form-control"
                          {...register("resident_ward")}
                        />
                      </div>
                    </div>
                  </div>

                  <div className="form-card p-1333">
                    <div className="row">
                      <div className="col-md-12 pt-20">
                        <h6 className="text-uppercase">Final Information</h6>
                        <hr />
                      </div>
                      <div className="col-md-4 pb-20">
                        <label htmlFor="article-title">
                          Resident Ethnicity
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Enter first name"
                          {...register("resident_ethnicity")}
                        />
                      </div>
                      <div className="col-md-4 pb-20">
                        <label htmlFor="article-title">No of people</label>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Enter last name"
                          {...register("resident_no_of_people")}
                        />
                      </div>
                      <div className="col-md-4 pb-20">
                        <label htmlFor="article-title">
                          Respondent Aggreement
                        </label>
                        <select
                          id="resident-living-there"
                          className="form-control"
                          {...register("resident_respondent_aggreement")}
                        >
                          <option value="YES">Yes</option>
                          <option value="NO">No</option>
                        </select>
                      </div>
                      <div className="col-md-4 pb-20">
                        <label htmlFor="article-title">
                          Taken Interview Previously
                        </label>
                        <select
                          id="resident-living-there"
                          className="form-control"
                          {...register("resident_taken_interview_previously")}
                        >
                          <option value="YES">Yes</option>
                          <option value="NO">No</option>
                        </select>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
    </>
  );
}
