import {
  INFANT_DELETED,
  INFANT_FETCH,
  INFANT_FETCHED,
  INFANT_SINGLE_FETCH,
  INFANT_SINGLE_FETCHED,
  INFANT_UPDATE,
  INFANT_UPDATE_FAILURE,
  INFANT_UPDATE_SUCCESS,
} from "../constants/types";

const initialValue = {
  infants: [],
  total: 0,
  infant: undefined,
  loading: false,
  updating: false,
};

function reducer(state = initialValue, action) {
  switch (action.type) {
    case INFANT_FETCH:
      return {
        loading: true,
      };
    case INFANT_FETCHED:
      return {
        ...state,
        loading: false,
        infants: action.payload.infantList,
        total: action.payload.total,
      };
    case INFANT_SINGLE_FETCH:
      return {
        loading: true,
      };
    case INFANT_SINGLE_FETCHED:
      return {
        ...state,
        loading: false,
        infant: action.payload.data,
      };
    case INFANT_DELETED:
      const deleted = state.infants.filter(
        (item) => item.infantId !== action.payload
      );
      const total = state.total - 1;
      return {
        ...state,
        infants: deleted,
        total,
      };

    case INFANT_UPDATE:
      return {
        ...state,
        updating: true,
      };

    case INFANT_UPDATE_SUCCESS:
      return {
        ...state,
        updating: false,
      };
    case INFANT_UPDATE_FAILURE:
      return {
        ...state,
        updating: false,
      };

    default:
      return {
        ...state,
      };
  }
}

export default reducer;
