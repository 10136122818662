import React from "react";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { updatePassword } from "../../../redux/actions/doctor.action";
export default function ChangePassword() {
  const { updating } = useSelector((state) => state.doctor);
  const dispatch = useDispatch();
  const formSchema = Yup.object().shape({
    old_password: Yup.string().required("Old Password is required"),
    new_password: Yup.string()
      .required("New Password is required")
      .min(4, "New Password length should be at least 4 characters"),
    confirm_password: Yup.string()
      .required("Confirm Password is required")
      .oneOf([Yup.ref("new_password")], "Passwords must and should match"),
  });
  const validationOpt = { resolver: yupResolver(formSchema) };

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm(validationOpt);

  const onChangePassword = async (data) => {
    dispatch(updatePassword(data));
  };

  let errorStyle = {
    borderWidth: "1px 1px 1px 10px",
    borderStyle: "solid",
    borderColor:
      "rgb(191, 22, 80) rgb(191, 22, 80) rgb(191, 22, 80) rgb(236, 89, 144)",
    borderImage: "initial",
    background: "rgb(251, 236, 242)",
  };

  return (
    <form onSubmit={handleSubmit(onChangePassword)}>
      <div className="form-group">
        <label className="d-block">Change Password</label>
        <input
          type="password"
          className="form-control"
          placeholder="Enter your old password"
          {...register("old_password")}
          style={errors.old_password ? errorStyle : {}}
        />{" "}
        {errors.old_password && (
          <span className="errorText">{errors.old_password?.message}</span>
        )}
        <input
          type="password"
          className="form-control mt-1"
          placeholder="New password"
          {...register("new_password")}
          style={errors.new_password ? errorStyle : {}}
        />{" "}
        {errors.new_password && (
          <span className="errorText">{errors.new_password?.message}</span>
        )}
        <input
          type="password"
          className="form-control mt-1"
          placeholder="Confirm new password"
          {...register("confirm_password")}
          style={errors.confirm_password ? errorStyle : {}}
        />{" "}
        {errors.confirm_password && (
          <span className="errorText">{errors.confirm_password?.message}</span>
        )}
      </div>
      {updating ? (
        <button type="submit" className="btn btn-primary" disabled>
          Changing Password
        </button>
      ) : (
        <button type="submit" className="btn btn-primary">
          Change Password
        </button>
      )}
    </form>
  );
}
