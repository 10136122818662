import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchPatientSingle,
  updatePatient,
} from "../../../redux/actions/patient.action";
import { useParams, Link, useLocation, useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import { NepaliDatePicker } from "nepali-datepicker-reactjs";
import formatDate from "./../../../utils/formatDate";

const NepaliDate = require("nepali-date");
export default function PUpdate() {
  const { patient, updating } = useSelector((state) => state.patient);
  let { search } = useLocation();

  const { id } = useParams();
  const dispatch = useDispatch();
  const { register, handleSubmit, reset } = useForm();

  const [gender, setGender] = useState();
  const [date, setDate] = useState();

  const onSubmit = async (data) => {
    data.gender = gender;
    var patientDob = new NepaliDate(data.patientDob).format("DD/MM/YYYY");
    data.patientDob = patientDob;
    console.log(data);
    dispatch(updatePatient(data));
  };
  useEffect(() => {
    reset(
      patient
        ? {
            patientFirstName: patient?.patientFirstName || "",
            patientLastName: patient?.patientLastName || "",
            patientMiddleName: patient?.patientMiddleName || "",
            patientAddedDate: patient?.patientAddedDate || "",
            patientAge: patient?.patientAge || "",
            longitude: patient?.longitude || "",
            latitude: patient?.latitude || "",
            pid: patient?.patientId || "",
            patientDob: formatDate(patient?.patientDob) || "",
            creator: patient?.user?.chw_name || "",
            patientFullName: patient?.patientFullName || "",
            patientHouseno: patient?.patientHouseno || "",
            patientMunicipality: patient?.patientMunicipality || "",
            patientPhone: patient?.patientPhone || "",
            patientSpouseFullName: patient?.patientSpouseFullName || "",
            patientVillagename: patient?.patientVillagename || "",
            patientspousefirstname: patient?.patientspousefirstname || "",
            patientspouselastname: patient?.patientspouselastname || "",
            patientwardno: patient?.patientwardno || "",
            image: patient?.image || "",
          }
        : {}
    );
    setGender(patient?.patientGender);
    setDate(formatDate(patient?.patientDob));
  }, [patient]);

  const onDateChange = (date) => {
    var currentYear = new NepaliDate().getYear();
    var currentMonth = new NepaliDate().getMonth();
    var currentDate = new NepaliDate().getDate();
    var bornYear = new NepaliDate(date).format("YYYY");
    var bornMonth = new NepaliDate(date).format("MM");
    var bornDate = new NepaliDate(date).format("DD");
    /* get raw year between dates */
    let year = currentYear - bornYear;

    /* calculate exact year */
    if (
      bornMonth > currentMonth ||
      (bornMonth == currentMonth && bornDate > currentDate)
    ) {
      year--;
    }
    reset({
      patientDob: date || "",
      patientAge: year || "",
      patientFirstName: patient?.patientFirstName || "",
      patientLastName: patient?.patientLastName || "",
      patientMiddleName: patient?.patientMiddleName || "",
      patientAddedDate: patient?.patientAddedDate || "",
      longitude: patient?.longitude || "",
      latitude: patient?.latitude || "",
      pid: patient?.patientId || "",
      creator: patient?.user?.chw_name || "",
      patientFullName: patient?.patientFullName || "",
      patientHouseno: patient?.patientHouseno || "",
      patientMunicipality: patient?.patientMunicipality || "",
      patientPhone: patient?.patientPhone || "",
      patientSpouseFullName: patient?.patientSpouseFullName || "",
      patientVillagename: patient?.patientVillagename || "",
      patientspousefirstname: patient?.patientspousefirstname || "",
      patientspouselastname: patient?.patientspouselastname || "",
      patientwardno: patient?.patientwardno || "",
      image: patient?.image || "",
    });
  };

  useEffect(() => {
    dispatch(fetchPatientSingle(id));
  }, [dispatch]);
  const navigate = useNavigate();

  const onPatientClick = () => {
    navigate(-1);
  };
  return (
    <>
      <div className="app-main__inner">
        <div className="app-page-title pt-10">
          <div className="page-title-wrapper">
            <div className="page-title-heading">
              <div className="card-body p-0">
                <ul className="body-tabs body-tabs-layout tabs-animated body-tabs-animated nav">
                  <li className="nav-item">
                    <h5
                      style={{
                        marginTop: "16px",
                        fontSize: "18px",
                        fontWeight: "700",
                        paddingBottom: "10px",
                      }}
                    >
                      {search == "?pstaff" ? (
                        <>
                          <Link to="/staff">Staff List</Link> &emsp;
                          <i className="fas fa-angle-right"></i> &emsp;
                          <Link to="" onClick={onPatientClick}>
                            Patient List{" "}
                          </Link>{" "}
                          &emsp; <i className="fas fa-angle-right"></i> &emsp;
                        </>
                      ) : (
                        <>
                          <Link to="/patient">Patients</Link>&emsp;
                          <i className="fas fa-angle-right"></i>
                        </>
                      )}
                    </h5>
                  </li>
                  <li className="nav-item">
                    <Link
                      role="tab"
                      className="nav-link active font-small-14 bold"
                      id="tab-0"
                      data-toggle="tab"
                      to="#"
                    >
                      <span>Update</span>
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
            <div className="page-title-actions pt-20">
              <div className="d-inline-block">
                <div className="d-inline-block">
                  <Link to="/patient" className="mb-2 mr-2 btn btn-light">
                    <span className="btn-icon-wrapper pr-2 opacity-7">
                      <i className="fas fa-chevron-left"></i>
                    </span>
                    Cancel
                  </Link>
                  {updating ? (
                    <>
                      <Link
                        className="mb-2 mr-2 btn btn-success"
                        to=""
                        onClick={handleSubmit(onSubmit)}
                        disabled
                      >
                        <span className="btn-icon-wrapper pr-2 opacity-7">
                          <i className="fas fa-check"></i>
                        </span>
                        Saving Changes
                      </Link>
                    </>
                  ) : (
                    <>
                      <Link
                        className="mb-2 mr-2 btn btn-success"
                        to=""
                        onClick={handleSubmit(onSubmit)}
                      >
                        <span className="btn-icon-wrapper pr-2 opacity-7">
                          <i className="fas fa-check"></i>
                        </span>
                        Save Changes
                      </Link>
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="app" style={{ height: "90vh !important" }}>
        <form className="wrapper">
          <div className="conversation-area">
            <div className="p-20 pb-10">
              <h6
                className="page-title"
                style={{
                  fontSize: "18px",
                  fontWeight: "700",
                  paddingBottom: "10px",
                  paddingLeft: "10px",
                }}
              >
                Patient Meta Info
              </h6>
            </div>
            <div className="mb-3 mt--20 ml-10">
              <div className="card-body">
                <label>Patient Profile</label>
                <div className="row">
                  <div className="col-md-6">
                    <div style={{ width: "140px" }}>
                      {patient?.image ? (
                        <img
                          src={patient?.image}
                          style={{
                            height: "140px",
                          }}
                        />
                      ) : (
                        <div
                          className="d-flex justify-content-center align-items-center rounded"
                          style={{
                            height: "140px",
                            backgroundColor: "rgb(233, 236, 239)",
                          }}
                        >
                          <span
                            style={{
                              color: "rgb(166, 168, 170)",
                              font: "bold 8pt Arial",
                            }}
                          >
                            140x140
                          </span>
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="d-flex flex-column flex-sm-row justify-content-between mb-3">
                      <div className="text-left text-sm-left mb-2 mb-sm-0">
                        <div className="pb-20">
                          <label htmlFor="article-title">Patient Id</label>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="DH-01AY"
                            disabled
                            {...register("pid")}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="position-relative form-group">
                  <label htmlFor="article-owner" className="">
                    Added By
                  </label>
                  <input
                    name="owner"
                    id="article-owner"
                    type="text"
                    className="form-control"
                    {...register("creator")}
                    disabled
                  />
                </div>

                <div className="position-relative form-group">
                  <label className="">Added Date</label>
                  <input
                    type="text"
                    className="form-control"
                    {...register("patientAddedDate")}
                    disabled
                  />
                </div>
                <div className="position-relative form-group">
                  <label className="">Latitude</label>
                  <input
                    type="text"
                    className="form-control"
                    {...register("latitude")}
                    disabled
                  />
                </div>
                <div className="position-relative form-group">
                  <label className="">Longitude</label>
                  <input
                    type="text"
                    className="form-control"
                    {...register("longitude")}
                    disabled
                  />
                </div>
              </div>
            </div>
          </div>
          <div
            className="chat-area"
            style={{ background: "white", paddingLeft: "20px" }}
          >
            <div className="chat-area-header">
              <div className="row w-100">
                <div className="px-0 pb-0 mb-3">
                  <div className="form-card p-1333">
                    <div className="row">
                      <div className="col-md-12">
                        <h6 className="text-uppercase">Patient Information</h6>
                        <hr />
                      </div>
                      <div className="col-md-4 pb-20">
                        <label htmlFor="article-title">First Name</label>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Enter first name"
                          {...register("patientFirstName")}
                        />
                      </div>
                      <div className="col-md-4 pb-20">
                        <label htmlFor="article-title">Middle Name</label>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Enter middle name"
                          {...register("patientMiddleName")}
                        />
                      </div>
                      <div className="col-md-4 pb-20">
                        <label htmlFor="article-title">Last Name</label>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Enter last name"
                          required=""
                          {...register("patientLastName")}
                        />
                      </div>
                      <div className="col-md-4 pb-20">
                        <label htmlFor="article-expiry" className="">
                          Date of Birth (B.S.)
                        </label>
                        <NepaliDatePicker
                          inputClassName="form-control"
                          value={date}
                          onChange={(value) => onDateChange(value)}
                          options={{
                            calenderLocale: "ne",
                            valueLocale: "en",
                          }}
                        />
                      </div>
                      <div className="col-md-4 pb-20">
                        <label htmlFor="article-expiry" className="">
                          Age
                        </label>
                        <input
                          name="article-expiry"
                          id="patientAge"
                          type="number"
                          className="form-control"
                          disabled
                          {...register("patientAge")}
                        />
                      </div>
                      <div className="col-md-6">
                        <label>Gender at Birth</label>
                        <div className="custom-radio custom-control">
                          <input
                            type="radio"
                            id="g-male"
                            value="Male"
                            checked={gender == "Male"}
                            name="customRadio"
                            className="custom-control-input"
                            onChange={(e) => setGender(e.currentTarget.value)}
                          />
                          <label
                            className="custom-control-label"
                            htmlFor="g-male"
                          >
                            Male
                          </label>
                        </div>

                        <div className="custom-radio custom-control">
                          <input
                            type="radio"
                            id="g-femlae"
                            value="Female"
                            checked={gender == "Female"}
                            name="customRadio"
                            className="custom-control-input"
                            onChange={(e) => setGender(e.currentTarget.value)}
                          />
                          <label
                            className="custom-control-label"
                            htmlFor="g-femlae"
                          >
                            Female
                          </label>
                        </div>
                      </div>
                      <div className="col-md-12 pt-30">
                        <h6 className="text-uppercase">Contact Information</h6>
                        <hr />
                      </div>
                      <div className="col-md-6 pb-20">
                        <label htmlFor="article-title">Contact Number</label>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Enter contact number"
                          {...register("patientPhone")}
                        />
                      </div>
                      <div className="col-md-12 pt-20">
                        <h6 className="text-uppercase">Address Information</h6>
                        <hr />
                      </div>
                      <div className="col-md-4 pb-20">
                        <label htmlFor="municipality" className="">
                          Municipality
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          {...register("patientMunicipality")}
                        />
                      </div>
                      <div className="col-md-4 pb-20">
                        <label htmlFor="article-title">Village</label>
                        <input
                          type="text"
                          className="form-control"
                          {...register("patientVillagename")}
                        />
                      </div>
                      <div className="col-md-4 pb-20">
                        <label htmlFor="article-title">Ward</label>
                        <input
                          type="number"
                          className="form-control"
                          {...register("patientwardno")}
                        />
                      </div>
                      <div className="col-md-4 pb-20">
                        <label htmlFor="article-title">House Number</label>
                        <input
                          type="text"
                          className="form-control"
                          {...register("patientHouseno")}
                        />
                      </div>
                    </div>
                  </div>

                  <div className="form-card p-1333">
                    <div className="row">
                      <div className="col-md-12 pt-20">
                        <h6 className="text-uppercase">Relative Information</h6>
                        <hr />
                      </div>
                      <div className="col-md-4 pb-20">
                        <label htmlFor="article-title">Spouse First Name</label>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Enter first name"
                          {...register("patientspousefirstname")}
                        />
                      </div>

                      <div className="col-md-4 pb-20">
                        <label htmlFor="article-title">Spouse Last Name</label>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Enter last name"
                          {...register("patientspouselastname")}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
    </>
  );
}
