import React, { useEffect } from "react";
import "./style.css";
import Highcharts from "highcharts";
import { useDispatch, useSelector } from "react-redux";
import { fetchDashInfo } from "../../redux/actions/dash.action";
import moment from "moment";
import DashChart from "./Components/DashChart";
require("highcharts/highcharts-more")(Highcharts);

export default function Dashboard() {
  const dispatch = useDispatch();
  const { patient, chw, infant, sm, cd, censusTotal, censusChart, loading } =
    useSelector((state) => state.dash);
  var currentDate = moment(new Date()).format("DD-MM-YYYY");

  useEffect(() => {
    dispatch(fetchDashInfo());
  }, []);
  return (
    <>
      <div className="app-main__inner">
        <div className="app-page-title pt-10">
          <div className="page-title-wrapper">
            <div className="page-title-heading">
              <div className="card-body p-0">
                <div className="mr-2 btn-group">
                  <h5 className="page-title">Dashboard</h5>
                </div>
              </div>
            </div>
            <div className="page-title-actions pt-20">
              {/* <div className=" d-inline-block">
                <button
                  type="button"
                  aria-haspopup="true"
                  aria-expanded="false"
                  data-toggle="dropdown"
                  className="mb-2 mr-2 btn btn-light btn-round"
                >
                  <span className="btn-icon-wrapper opacity-7">
                    <i className="fas fa-bell mt-06"></i>
                  </span>
                </button>

                <div
                  tabindex="-1"
                  role="menu"
                  aria-hidden="true"
                  className="dropdown-menu p-0 m-0 w-400"
                >
                  <div className="notify-nav nav-test">
                    <div className="dropdown show">
                      <div className="dropdown-menu show">
                        <div className="sk-nfl">
                          <div className="sk-nh">
                            <h2 className="sk-nht">Notifications</h2>
                            <div className="sk-nhi">
                              <a href="#">
                                <i className="fas fa-cog font-18"></i>
                              </a>
                            </div>
                          </div>
                          <div className="sk-nb sk-nl">
                            <div className="sk-nli">
                              <a href="#">
                                <div className="sk-nli-new"></div>
                                <div className="sk-nli-img">
                                  <img
                                    src="./assets/images/avatars/2.png"
                                    alt="Avatar"
                                  />
                                </div>
                                <div className="sk-nli-text">
                                  <div className="sk-nli-msg">
                                    <b>Lorem Ipsum</b> commented: "Sed do
                                    eiusmod tempor incididunt ut labore et
                                    dolore magna aliqua."
                                  </div>
                                  <div className="sk-nli-metadata">
                                    3 days ago
                                  </div>
                                </div>
                              </a>
                            </div>
                            <div className="sk-nli">
                              <a href="#">
                                <div className="sk-nli-new"></div>
                                <div className="sk-nli-img">
                                  <img
                                    src="./assets/images/avatars/3.png"
                                    alt="Avatar"
                                  />
                                </div>
                                <div className="sk-nli-text">
                                  <div className="sk-nli-msg">
                                    <b>Omnis Iste</b> commented: "Magni dolores
                                    eos qui."
                                  </div>
                                  <div className="sk-nli-metadata">
                                    5 days ago
                                  </div>
                                </div>
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div> */}
              {/* <div className="d-inline-block">
                <a
                  href="./patient_add.php"
                  className="mb-2 mr-2 btn btn-primary color-white"
                >
                  <span className="btn-icon-wrapper pr-2 opacity-7">
                    <i className="fas fa-plus"></i>
                  </span>
                  Add New Patient
                </a>
              </div> */}
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-md-7">
            <div className="card mb-3 h-500 bg-plot">
              <h6 className="p-20 bold pb-10 text-white">Census Statistics</h6>
              <figure className="highcharts-figure pl-10">
                {censusChart && <DashChart censusChart={censusChart} />}
              </figure>
            </div>
          </div>
          <div className="col-md-5 pl-0">
            <div className="card mb-3 h-500 p-20">
              <div className="row justify-content-between">
                <div className="col-md-6">
                  <h6 className="bold pb-10 font-14">Total Patient Count</h6>
                  <h2 className="bold font-44">{patient}</h2>
                  <p className="font-12 text-mute">
                    Last Data Sync on {currentDate}
                  </p>
                  <a className="mb-2 mr-2 btn btn-light btn-more">
                    <span className="btn-icon-wrapper pr-2 opacity-7">
                      <i className="fas fa-arrow-right"></i>
                    </span>
                    View More
                  </a>
                </div>
                <div className="col-md-6">
                  <h6 className="bold pb-10 font-14">Total CHW Count</h6>
                  <h2 className="bold font-44">{chw}</h2>
                  <p className="font-12 text-mute">
                    Last Data Sync on {currentDate}
                  </p>
                  <a className="mb-2 mr-2 btn btn-light btn-more">
                    <span className="btn-icon-wrapper pr-2 opacity-7">
                      <i className="fas fa-arrow-right"></i>
                    </span>
                    View More
                  </a>
                </div>
                <div className="col-md-5">
                  <h6 className="bold pb-10 font-14 mt-3">
                    Total census Count
                  </h6>
                  <h2 className="bold font-44">{censusTotal}</h2>
                  <p className="font-12 text-mute">
                    Last Data Sync on {currentDate}
                  </p>
                  <a className="mb-2 mr-2 btn btn-light btn-more">
                    <span className="btn-icon-wrapper pr-2 opacity-7">
                      <i className="fas fa-arrow-right"></i>
                    </span>
                    View More
                  </a>
                </div>
                <div
                  className="col-md-6 mt-3 mr-3"
                  style={{ background: "#e9ecef3d", borderRadius: "12px" }}
                >
                  <h6 className="bold pb-10 font-14 pt-10 mt-2">
                    <a href="#">
                      Organization Information&nbsp;
                      <i className="fas fa-arrow-right"></i>
                    </a>
                  </h6>
                  <div className="text-mute pb-10 font-12">
                    <p className="mb-3">
                      <i className="fas fa-hospital mr-2 font-14"></i>Karma
                      Health, Gangadi, Damodar Rd, Rajpur Gaunpalika, Ward No. 6
                    </p>
                    <p>
                      <i className="fas fa-phone mr-2 font-14"></i>(+977)
                      9857840044 | (+977) 9851232680
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-4 pr-0">
            <div className="card">
              <h6 className="p-20 bold pb-0">Aggregated Programs</h6>
              <div className="row p-20 pr-0 pt-10 pb-20">
                <div className="col-md-12" style={{ paddingLeft: "30px" }}>
                  <div className="row dashboard-appointment">
                    <div className="col-md-6 pt-10 pb-10">
                      <div className="font-12 text-mute">SM</div>
                      <div className="bold font-12">Safe Motherhood</div>
                    </div>
                    <div className="col-md-6 text-right">
                      <div className="font-12 text-muted">
                        <p className="opacity-5 font-10 mb-0 mt-20">{sm}</p>
                      </div>
                    </div>
                  </div>
                  <div className="row dashboard-appointment">
                    <div className="col-md-6 pt-10 pb-10">
                      <div className="font-12 text-mute">NCD</div>
                      <div className="bold font-12">
                        Non Communicable Disease
                      </div>
                    </div>
                    <div className="col-md-6 text-right">
                      <div className="font-12 text-muted">
                        <p className="opacity-5 font-10 mb-0 mt-20">{cd}</p>
                      </div>
                    </div>
                  </div>
                  <div className="row dashboard-appointment">
                    <div className="col-md-6 pt-10 pb-10">
                      <div className="font-12 text-mute">IH</div>
                      <div className="bold font-12">Infant Health</div>
                    </div>
                    <div className="col-md-6 text-right">
                      <div className="font-12 text-muted">
                        <p className="opacity-5 font-10 mb-0 mt-20">{infant}</p>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className="col-md-12 mb-0 mt-10"
                  style={{ paddingTop: "5px" }}
                >
                  <a className="mr-2 btn btn-light btn-more w-200">
                    <span className="btn-icon-wrapper pr-2 opacity-7">
                      <i className="fas fa-arrow-right"></i>
                    </span>
                    View More
                  </a>
                </div>
              </div>
            </div>
          </div>

          <div className="col-md-4 pr-0">
            <div className="card">
              <h6 className="p-20 bold pb-0">Safe Motherhood Program</h6>
              <div className="row p-20 pr-0 pt-10 pb-20">
                <div className="col-md-12" style={{ paddingLeft: "30px" }}>
                  <div className="row dashboard-appointment">
                    <div className="col-md-6 pt-10 pb-10">
                      <div className="font-12 text-mute">ANC Visit</div>
                      <div className="bold font-12">ANC Visit Protocol</div>
                    </div>
                    <div className="col-md-6 text-right">
                      <div className="font-12 text-muted">
                        <p className="opacity-5 font-10 mb-0 mt-20">621</p>
                      </div>
                    </div>
                  </div>
                  <div className="row dashboard-appointment">
                    <div className="col-md-6 pt-10 pb-10">
                      <div className="font-12 text-mute">Danger Risks</div>
                      <div className="bold font-12">Total Count</div>
                    </div>
                    <div className="col-md-6 text-right">
                      <div className="font-12 text-muted">
                        <p className="opacity-5 font-10 mb-0 mt-20">217</p>
                      </div>
                    </div>
                  </div>
                  <div className="row dashboard-appointment">
                    <div className="col-md-6 pt-10 pb-10">
                      <div className="font-12 text-mute">Home Visits</div>
                      <div className="bold font-12">Total CHW Home Visits</div>
                    </div>
                    <div className="col-md-6 text-right">
                      <div className="font-12 text-muted">
                        <p className="opacity-5 font-10 mb-0 mt-20">40</p>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className="col-md-12 mb-0 mt-10"
                  style={{ paddingTop: "5px" }}
                >
                  <a className="mr-2 btn btn-light btn-more w-200">
                    <span className="btn-icon-wrapper pr-2 opacity-7">
                      <i className="fas fa-arrow-right"></i>
                    </span>
                    View More
                  </a>
                </div>
              </div>
            </div>
          </div>

          <div className="col-md-4 pr-0">
            <div className="card">
              <h6 className="p-20 bold pb-0">Non Communicable Disease</h6>
              <div className="row p-20 pr-0 pt-10 pb-20">
                <div className="col-md-12" style={{ paddingLeft: "30px" }}>
                  <div className="row dashboard-appointment">
                    <div className="col-md-6 pt-10 pb-10">
                      <div className="font-12 text-mute">Count</div>
                      <div className="bold font-12">Prevalence of NCD</div>
                    </div>
                    <div className="col-md-6 text-right">
                      <div className="font-12 text-muted">
                        <p className="opacity-5 font-10 mb-0 mt-20">621</p>
                      </div>
                    </div>
                  </div>
                  <div className="row dashboard-appointment">
                    <div className="col-md-6 pt-10 pb-10">
                      <div className="font-12 text-mute">Danger Risks</div>
                      <div className="bold font-12">Total Count</div>
                    </div>
                    <div className="col-md-6 text-right">
                      <div className="font-12 text-muted">
                        <p className="opacity-5 font-10 mb-0 mt-20">217</p>
                      </div>
                    </div>
                  </div>
                  <div className="row dashboard-appointment">
                    <div className="col-md-6 pt-10 pb-10">
                      <div className="font-12 text-mute">
                        Health Institution
                      </div>
                      <div className="bold font-12">
                        Regularity of institution visit
                      </div>
                    </div>
                    <div className="col-md-6 text-right">
                      <div className="font-12 text-muted">
                        <p className="opacity-5 font-10 mb-0 mt-20">40</p>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className="col-md-12 mb-0 mt-10"
                  style={{ paddingTop: "5px" }}
                >
                  <a className="mr-2 btn btn-light btn-more w-200">
                    <span className="btn-icon-wrapper pr-2 opacity-7">
                      <i className="fas fa-arrow-right"></i>
                    </span>
                    View More
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
