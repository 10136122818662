import { DASH_INFO_FETCH, DASH_INFO_FETCHED } from "../constants/types";

const initialValue = {
  patient: 0,
  chw: 0,
  infant: 0,
  loading: false,
  sm: 0,
  cd: 0,
};

function reducer(state = initialValue, action) {
  switch (action.type) {
    case DASH_INFO_FETCH:
      return {
        loading: true,
      };
    case DASH_INFO_FETCHED:
      let censusChart  = action.payload?.censusChart?.data;

      censusChart.x = censusChart.x.map(d => {
        return d.slice(0, 10);
      });
      return {
        ...state,
        loading: false,
        patient: action.payload?.presult?.data?.details?.totalP,
        sm: action.payload.presult?.data?.details?.totalSM,
        cd: action.payload.presult?.data?.details?.totalCD,
        infant: action.payload?.iresult?.data?.details,
        chw: action.payload.chwresult?.data?.details,
        censusTotal: action.payload.censusresult?.data?.total,
        censusChart:  censusChart
      };

    default:
      return {
        ...state,
      };
  }
}

export default reducer;
