import { combineReducers } from "redux";
import patientReducer from "./patient.reducer";
import authReducer from "./auth.reducer";
import infantReducer from "./infant.reducer";
import staffReducer from "./staff.reducer";
import censusReducer from "./census.reducer";
import doctorReducer from "./doctor.reducer";
import dashReducer from "./dash.reducer";
import analyticsReducer from "./analytics.reducer";

const rootReducer = combineReducers({
  patient: patientReducer,
  auth: authReducer,
  infant: infantReducer,
  staff: staffReducer,
  census: censusReducer,
  doctor: doctorReducer,
  dash: dashReducer,
  analytics: analyticsReducer
});

export default rootReducer;
