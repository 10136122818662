import React, { useState, useEffect } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import "nepali-datepicker-reactjs/dist/index.css";
import toast from "../../../utils/toast";
import { updateCHW, fetchCHWSingle } from "../../../redux/actions/staff.action";
import { NepaliDatePicker } from "nepali-datepicker-reactjs";
import formatDate from "./../../../utils/formatDate";
import reformatDateString from "../../../utils/reformatDateString";
import moment from "moment";
var adbs = require("ad-bs-converter");

export default function StaffUpdate() {
  const { staff, updating } = useSelector((store) => store.staff);
  const navigate = useNavigate();
  const [date, setDate] = useState();

  const [fileImg, setFileImg] = useState(null);

  const [image, setImage] = useState(null);
  const [file, setFile] = useState(null);
  const [gender, setGender] = useState();

  const { id } = useParams();

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchCHWSingle(id));
  }, []);

  const onSubmit = async (data) => {
    var formatedDobBS = moment(new Date(date)).format("DD/MM/YYYY");
    var formatedDobAD = moment(new Date(data.chw_dob)).format("DD/MM/YYYY");
    data.chw_dob_nepali = formatedDobBS;
    data.chw_dob = formatedDobAD;

    let formData = new FormData();
    for (var key in data) {
      formData.append(key, data[key]);
    }
    if (image) formData.append("image", fileImg[0]);
    if (file) formData.append("file", file[0]);
    dispatch(updateCHW(formData, id));
  };

  let errorStyle = {
    borderWidth: "1px 1px 1px 10px",
    borderStyle: "solid",
    borderColor:
      "rgb(191, 22, 80) rgb(191, 22, 80) rgb(191, 22, 80) rgb(236, 89, 144)",
    borderImage: "initial",
    background: "rgb(251, 236, 242)",
  };
  useEffect(() => {
    reset(
      staff
        ? {
            chw_identifier: staff?.chw_identifier || "",

            chw_name: staff?.chw_name || "",
            chw_firstname: staff?.chw_firstname || "",
            chw_middlename: staff?.chw_middlename || "",
            chw_lastname: staff?.chw_lastname || "",
            chw_firstname_nepali: staff?.chw_firstname_nepali || "",
            chw_middlename_nepali: staff?.chw_middlename_nepali || "",
            chw_lastname_nepali: staff?.chw_lastname_nepali || "",

            chw_doc_type: staff?.chw_doc_type,
            chw_doc_number: staff?.chw_doc_number,
            chw_doc_file: staff?.chw_doc_file,
            chw_gender: staff?.chw_gender,
            chw_contact: staff?.chw_contact,
            chw_alt_contact: staff?.chw_alt_contact,
            chw_address_country: staff?.chw_address_country,
            chw_address_province: staff?.chw_address_province,
            chw_address_district: staff?.chw_address_district,
            chw_address_municipality: staff?.chw_address_municipality,
            chw_address_ward: staff?.chw_address_ward,
            chw_edu_undergraduate: staff?.chw_edu_undergraduate,
            chw_edu_postgraduate: staff?.chw_edu_postgraduate,
            chw_emergency_contact_firstname:
              staff?.chw_emergency_contact_firstname,
            chw_emergency_contact_middlename:
              staff?.chw_emergency_contact_middlename,
            chw_emergency_contact_lastname:
              staff?.chw_emergency_contact_lastname,
            chw_emergency_contact_relation:
              staff?.chw_emergency_contact_relation,
            chw_emergency_contact_number: staff?.chw_emergency_contact_number,

            phone: staff?.phone,
            creator: staff?.chw_creator,
            chw_creator_hospital: staff?.chw_creator_hospital,

            chw_dob: formatDate(staff?.chw_dob),
            chw_age: staff?.chw_age,

            chw_hospital_id: staff?.chw_hospital_id,
            chw_notes: staff?.chw_notes,
          }
        : {}
    );
    setImage(staff?.image);
    setGender(staff?.chw_gender);
    setDate(reformatDateString(staff?.chw_dob_nepali));
  }, [staff]);

  const fileSelect = () => {
    document.getElementById("staffimg").click();
  };
  const handleFile = (e) => {
    if (e.target.files && e.target.files[0]) {
      setFileImg(e.target.files);
      setImage(URL.createObjectURL(e.target.files[0]));
    }
  };
  const handleVerificationFile = (e) => {
    if (e.target.files[0].size > 2097152) {
      console.log("Maximum upload size of verification ID is 2MB.");
    } else {
      setFile(e.target.files);
    }
  };
  const onADChange = () => {
    //get selected AD date
    let dateAD = document.getElementById("staffDOB").value;
    //convert AD TO BS
    const dateBS = adbs.ad2bs(moment(new Date(dateAD)).format("YYYY/MM/DD")).en;
    const dobBS = dateBS.year + "-" + dateBS.month + "-" + dateBS.day;
    var formatedDobAD = moment(new Date(dateAD)).format("MM/DD/YYYY");
    const age = getAge(formatedDobAD);

    //update AD date and Age in form
    setDate(dobBS);
    reset({
      chw_age: age,
    });
  };
  const onBSChange = (dateBS) => {
    //convert BS TO ADs
    const dateAD = adbs.bs2ad(moment(new Date(dateBS)).format("YYYY/MM/DD"));
    //add before 0 when month and year less than 10
    const month = dateAD.month > 9 ? dateAD.month : "0" + dateAD.month;
    const day = dateAD.day > 9 ? dateAD.day : "0" + dateAD.day;
    const dobAD = dateAD.year + "-" + month + "-" + day;
    setDate(dateBS);
    //format date for calculating age YYYY-MM-DD to MM/DD/YYYY
    var formatedDobAD = moment(new Date(dobAD)).format("MM/DD/YYYY");
    const age = getAge(formatedDobAD);

    //update AD date and Age in form
    reset({
      chw_dob: dobAD,
      chw_age: age,
    });
  };

  function getAge(dateString) {
    var now = new Date();
    var yearNow = now.getYear();
    var monthNow = now.getMonth();
    var dateNow = now.getDate();
    var dob = new Date(
      dateString.substring(6, 10),
      dateString.substring(0, 2) - 1,
      dateString.substring(3, 5)
    );
    var yearDob = dob.getYear();
    var monthDob = dob.getMonth();
    var dateDob = dob.getDate();
    var age = {};

    var yearAge = yearNow - yearDob;

    if (monthNow >= monthDob) var monthAge = monthNow - monthDob;
    else {
      yearAge--;
      var monthAge = 12 + monthNow - monthDob;
    }

    if (dateNow >= dateDob) var dateAge = dateNow - dateDob;
    else {
      monthAge--;
      var dateAge = 31 + dateNow - dateDob;

      if (monthAge < 0) {
        monthAge = 11;
        yearAge--;
      }
    }

    age = {
      years: yearAge,
      months: monthAge,
      days: dateAge,
    };

    return age.years;
  }

  return (
    <>
      <div className="app-main__inner">
        <div className="app-page-title pt-10">
          <div className="page-title-wrapper">
            <div className="page-title-heading">
              <div className="card-body p-0">
                <ul className="body-tabs body-tabs-layout tabs-animated body-tabs-animated nav">
                  <li className="nav-item">
                    <h5
                      style={{
                        marginTop: "16px",
                        fontSize: "18px",
                        fontWeight: "700",
                        paddingBottom: "10px",
                      }}
                    >
                      <Link to="/staff">Staff List</Link> &emsp;{" "}
                      <i className="fas fa-angle-right"></i> &emsp;
                    </h5>
                  </li>
                  <li className="nav-item">
                    <Link
                      role="tab"
                      className="nav-link active font-small-14 bold"
                      id="tab-0"
                      data-toggle="tab"
                      to="#"
                    >
                      <span>Update</span>
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
            <div className="page-title-actions pt-20">
              <div className="d-inline-block">
                <div class="d-inline-block">
                  <Link to="/staff" className="mb-2 mr-2 btn btn-light">
                    <span class="btn-icon-wrapper pr-2 opacity-7">
                      <i class="fas fa-chevron-left"></i>
                    </span>
                    Cancel
                  </Link>
                  {updating ? (
                    <>
                      <Link
                        className="mb-2 mr-2 btn btn-success"
                        to=""
                        onClick={handleSubmit(onSubmit)}
                        disabled
                      >
                        <span className="btn-icon-wrapper pr-2 opacity-7">
                          <i className="fas fa-check"></i>
                        </span>
                        Saving Changes
                      </Link>
                    </>
                  ) : (
                    <>
                      <Link
                        className="mb-2 mr-2 btn btn-success"
                        to=""
                        onClick={handleSubmit(onSubmit)}
                      >
                        <span className="btn-icon-wrapper pr-2 opacity-7">
                          <i className="fas fa-check"></i>
                        </span>
                        Save Changes
                      </Link>
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="app" style={{ height: "90vh !important" }}>
        <form class="wrapper">
          <div class="conversation-area">
            <div class="p-20 pb-10">
              <h6
                class="page-title"
                style={{
                  fontSize: "18px",
                  fontWeight: "700",
                  paddingBottom: "10px",
                  paddingLeft: "10px",
                }}
              >
                Update Staff Meta
              </h6>
            </div>
            <div class="mb-3 mt--20 ml-10">
              <div class="card-body">
                <label>Staff Profile</label>
                <div class="row">
                  <div class="col-md-6">
                    <div style={{ width: "140px" }}>
                      {image ? (
                        <img
                          id="staff-image"
                          src={image}
                          style={{
                            height: "140px",
                          }}
                        />
                      ) : (
                        <div
                          class="d-flex justify-content-center align-items-center rounded"
                          style={{
                            height: "140px",
                            backgroundColor: "rgb(233, 236, 239)",
                          }}
                        >
                          <span
                            style={{
                              color: "rgb(166, 168, 170)",
                              font: "bold 8pt Arial",
                            }}
                          >
                            140x140
                          </span>
                        </div>
                      )}
                    </div>
                  </div>
                  <div class="col-md-6">
                    <div class="d-flex flex-column flex-sm-row justify-content-between mb-3">
                      <div class="text-left text-sm-left mb-2 mb-sm-0">
                        <div class="pb-20">
                          <label for="article-title">Staff Identifier</label>
                          <input
                            type="text"
                            class="form-control"
                            placeholder="DH-01AY"
                            required=""
                            disabled
                            {...register("chw_identifier")}
                          />
                        </div>

                        <div class="mt-2">
                          <input
                            id="staffimg"
                            type="file"
                            accept="image/*"
                            hidden
                            onChange={handleFile}
                          />
                          <button
                            class="btn btn-primary"
                            type="button"
                            onClick={() => fileSelect()}
                          >
                            <i class="fa fa-fw fa-camera"></i>
                            <span>Upload Photo</span>
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="position-relative form-group">
                  <label for="article-owner" class="">
                    Creator
                  </label>
                  <input
                    name="owner"
                    id="article-owner"
                    disabled
                    type="text"
                    class="form-control"
                    {...register("creator")}
                  />
                </div>
                <div class="position-relative form-group">
                  <label class="">Creator Hospital</label>
                  <input
                    placeholder="Dhulikhel Hospital"
                    type="text"
                    class="form-control"
                    disabled
                  />
                </div>
                <div class="position-relative form-group">
                  <label class="">Hospital ID</label>
                  <input
                    placeholder="DH-001"
                    type="text"
                    class="form-control"
                    disabled
                    // {...register("chw_hospital_id")}
                  />
                </div>
                <div class="position-relative form-group">
                  <label for="exampleText" class="">
                    Notes (if any)
                  </label>
                  <textarea
                    name="text"
                    id="exampleText"
                    class="form-control"
                    style={{ height: "140px" }}
                    {...register("chw_notes")}
                  ></textarea>
                </div>
              </div>
            </div>
          </div>
          <div
            class="chat-area"
            style={{ background: "white", paddingLeft: "20px" }}
          >
            <div class="chat-area-header">
              <div class="row w-100">
                <div class="px-0 pb-0 mb-3">
                  <div class="form-card p-1333">
                    <div class="row">
                      <div class="col-md-12">
                        <h6 class="text-uppercase">Staff Information</h6>
                        <hr />
                      </div>
                      <div class="col-md-4 pb-20">
                        <label for="article-title">First Name</label>
                        <input
                          type="text"
                          class="form-control"
                          placeholder="Enter first name"
                          required=""
                          lang="ne"
                          {...register("chw_firstname")}
                        />
                      </div>
                      <div class="col-md-4 pb-20">
                        <label for="article-title">Middle Name</label>
                        <input
                          type="text"
                          class="form-control"
                          placeholder="Enter middle name"
                          required=""
                          {...register("chw_middlename")}
                        />
                      </div>
                      <div class="col-md-4 pb-20">
                        <label for="article-title">Last Name</label>
                        <input
                          type="text"
                          class="form-control"
                          placeholder="Enter last name"
                          required=""
                          {...register("chw_lastname")}
                        />
                      </div>
                      <div class="col-md-4 pb-20">
                        <label for="article-title">पहिलो नाम</label>
                        <input
                          type="text"
                          class="form-control"
                          placeholder="पहिलो नाम"
                          required=""
                          id="fname"
                          {...register("chw_firstname_nepali")}
                        />
                      </div>
                      <div class="col-md-4 pb-20">
                        <label for="article-title">बीचको नाम</label>
                        <input
                          type="text"
                          class="form-control"
                          placeholder="बीचको नाम"
                          required=""
                          id="mname"
                          {...register("chw_middlename_nepali")}
                        />
                      </div>
                      <div class="col-md-4 pb-20">
                        <label for="article-title">थर</label>
                        <input
                          type="text"
                          class="form-control"
                          placeholder="थर"
                          required=""
                          id="lname"
                          {...register("chw_lastname_nepali")}
                        />
                      </div>
                      <div class="col-md-4 pb-20">
                        <label for="article-expiry" class="">
                          Date of Birth (A.D.)
                        </label>
                        <input
                          name="article-expiry"
                          id="staffDOB"
                          type="date"
                          class="form-control"
                          {...register("chw_dob")}
                          onChange={onADChange}
                        />
                      </div>
                      <div class="col-md-4 pb-20">
                        <label for="date1" class="">
                          Date of Birth (B.S.)
                        </label>
                        <NepaliDatePicker
                          inputClassName="form-control"
                          value={date}
                          onChange={(value) => onBSChange(value)}
                          options={{
                            calenderLocale: "ne",
                            valueLocale: "en",
                          }}
                        />
                      </div>
                      <div class="col-md-4 pb-20">
                        <label for="article-expiry" class="">
                          Age
                        </label>
                        <input
                          name="article-expiry"
                          id="article-expiry"
                          type="number"
                          class="form-control"
                          disabled
                          {...register("chw_age")}
                        />
                      </div>
                      <div class="col-md-4 pb-20">
                        <label for="article-title">Document Type</label>

                        <select
                          id="resident-marital-status"
                          className="form-control"
                          {...register("chw_doc_type")}
                        >
                          <option value="citizenship">Citizenship</option>
                          <option value="driving_license">
                            Driving License
                          </option>
                          <option value="passport">Passport</option>
                        </select>
                      </div>
                      <div class="col-md-4 pb-20">
                        <label for="article-title">License / ID Number</label>
                        <input
                          type="text"
                          class="form-control"
                          placeholder="DH-01AY"
                          required=""
                          {...register("chw_doc_number")}
                        />
                      </div>
                      <div class="col-md-4">
                        <div class="position-relative form-group">
                          <label for="exampleFile" class="">
                            ID Verification File
                          </label>
                          <input
                            name="file"
                            accept="image/*"
                            id="exampleFile"
                            type="file"
                            class="form-control-file"
                            onChange={handleVerificationFile}
                          />
                          <small class="form-text text-muted">
                            ** Maximum upload size of verification ID is 2MB.
                          </small>
                        </div>
                      </div>
                      <div class="col-md-6">
                        <label>Gender at Birth</label>
                        <div class="custom-radio custom-control">
                          <input
                            type="radio"
                            id="g-male"
                            value="Male"
                            class="custom-control-input"
                            checked={gender == "Male"}
                            onChange={(e) => setGender(e.currentTarget.value)}
                          />
                          <label class="custom-control-label" for="g-male">
                            Male
                          </label>
                        </div>
                        <div class="custom-radio custom-control">
                          <input
                            type="radio"
                            id="g-femlae"
                            value="Female"
                            class="custom-control-input"
                            checked={gender == "Female"}
                            onChange={(e) => setGender(e.currentTarget.value)}
                          />
                          <label class="custom-control-label" for="g-femlae">
                            Female
                          </label>
                        </div>
                      </div>
                      <div class="col-md-12 pt-30">
                        <h6 class="text-uppercase">Contact Information</h6>
                        <hr />
                      </div>
                      <div class="col-md-6 pb-20">
                        <label for="article-title">Contact Number</label>
                        <input
                          type="number"
                          class="form-control"
                          placeholder="Enter phone"
                          required=""
                          {...register("chw_contact")}
                        />{" "}
                      </div>
                      <div class="col-md-6 pb-20">
                        <label for="article-title">
                          Alternate Contact Number
                        </label>
                        <input
                          type="number"
                          class="form-control"
                          placeholder="Enter alternate number"
                          required=""
                          {...register("chw_alt_contact")}
                        />
                      </div>{" "}
                      <div class="col-md-12 pt-30">
                        <h6 class="text-uppercase">Login Information</h6>
                        <hr />
                      </div>
                      <div class="col-md-6 pb-20">
                        <label for="article-title">Mobile Number*</label>
                        <input
                          type="number"
                          class="form-control"
                          placeholder="Enter phone"
                          required=""
                          {...register("phone")}
                        />{" "}
                      </div>
                      <div class="col-md-6 pb-20">
                        <label for="article-title">Password*</label>
                        <input
                          type="password"
                          className="form-control"
                          placeholder="Enter password"
                          {...register("password")}
                        />
                      </div>
                      <div class="col-md-12 pt-20">
                        <h6 class="text-uppercase">Address Information</h6>
                        <hr />
                      </div>
                      <div class="col-md-4 pb-20">
                        <label for="country" class="">
                          Country
                        </label>
                        <select
                          name="select"
                          id="country"
                          class="form-control"
                          {...register("chw_address_country")}
                        >
                          <option>Nepal</option>
                        </select>
                      </div>
                      <div class="col-md-4 pb-20">
                        <label for="province" class="">
                          Province
                        </label>
                        <select
                          {...register("chw_address_province")}
                          name="select"
                          id="province"
                          class="form-control"
                        >
                          <option>1</option>
                          <option>2</option>
                          <option>3</option>
                          <option>4</option>
                          <option>5</option>
                          <option>6</option>
                          <option>7</option>
                        </select>
                      </div>
                      <div class="col-md-4 pb-20">
                        <label for="district" class="">
                          District
                        </label>
                        <select
                          name="select"
                          id="district"
                          class="form-control"
                          {...register("chw_address_district")}
                        >
                          <option>Dang</option>
                        </select>
                      </div>
                      <div class="col-md-4 pb-20">
                        <label for="municipality" class="">
                          Municipality
                        </label>
                        <select
                          name="select"
                          id="municipality"
                          class="form-control"
                          {...register("chw_address_municipality")}
                        >
                          <option>Dhulikhel</option>
                          <option>Kathmandu</option>
                          <option>Lalitpur</option>
                          <option>Bhaktapur</option>
                        </select>
                      </div>
                      <div class="col-md-4 pb-20">
                        <label for="article-title">Ward</label>
                        <input
                          type="number"
                          class="form-control"
                          placeholder="1"
                          required=""
                          {...register("chw_address_ward")}
                        />
                      </div>
                    </div>
                  </div>

                  <div class="form-card p-1333">
                    <div class="row">
                      <div class="col-md-12 pt-20">
                        <h6 class="text-uppercase">Education Information</h6>
                        <hr />
                      </div>
                      <div class="col-md-4 pb-20">
                        <label for="undergrad">Undergraduate</label>
                        <input
                          type="text"
                          class="form-control"
                          id="undergrad"
                          placeholder=""
                          required=""
                          {...register("chw_edu_undergraduate")}
                        />
                      </div>
                      <div class="col-md-4">
                        <label for="postgrad">Postgraduate</label>
                        <input
                          type="text"
                          class="form-control"
                          id="postgrad"
                          placeholder=""
                          required=""
                          {...register("chw_edu_postgraduate")}
                        />
                      </div>
                    </div>
                  </div>

                  <div class="form-card p-1333">
                    <div class="row">
                      <div class="col-md-12 pt-20">
                        <h6 class="text-uppercase">
                          Emergency Contact Information
                        </h6>
                        <hr />
                      </div>
                      <div class="col-md-4 pb-20">
                        <label for="article-title">First Name</label>
                        <input
                          type="text"
                          class="form-control"
                          placeholder="Enter first name"
                          required=""
                          {...register("chw_emergency_contact_firstname")}
                        />
                      </div>
                      <div class="col-md-4 pb-20">
                        <label for="article-title">Middle Name</label>
                        <input
                          type="text"
                          class="form-control"
                          placeholder="Enter middle name"
                          required=""
                          {...register("chw_emergency_contact_middlename")}
                        />
                      </div>
                      <div class="col-md-4 pb-20">
                        <label for="article-title">Last Name</label>
                        <input
                          type="text"
                          class="form-control"
                          placeholder="Enter last name"
                          required=""
                          {...register("chw_emergency_contact_lastname")}
                        />
                      </div>

                      <div class="col-md-4 pb-20">
                        <label for="article-title">Relationship</label>
                        <input
                          type="text"
                          class="form-control"
                          placeholder="Enter relationship"
                          required=""
                          {...register("chw_emergency_contact_relation")}
                        />
                      </div>
                      <div class="col-md-4 pb-20">
                        <label for="article-title">
                          Emergency Contact Number
                        </label>
                        <input
                          type="number"
                          class="form-control"
                          placeholder="Enter contact number"
                          required=""
                          {...register("chw_emergency_contact_number")}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
    </>
  );
}
