import {
  LOGOUT_USER,
  LOGIN_SUCCESS,
  LOGIN_FAIL,
  LOGIN_START,
} from "../constants/types";
import http from "../../utils/http";
import toast from "../../utils/toast";

export const login = (data) => (dispatch) => {
  dispatch({ type: LOGIN_START });
  http
    .POST("login", data)
    .then((res) => {
      dispatch({ type: LOGIN_SUCCESS, payload: res.data });
    })
    .catch((error) => {
      toast.error(error);
      dispatch({ type: LOGIN_FAIL, message: error.response?.data.message });
    });
};

export const logout = () => (dispatch) => {
  dispatch({ type: LOGOUT_USER });
};

export const getSession = (data) => (dispatch) => {};
