import React, { useEffect, useState } from "react";
import axios from "axios";
import Highcharts from "highcharts";
import { treemap } from "highcharts";
import { useDispatch, useSelector } from "react-redux";
import { fetchCensusChart, fetchCensusLocation } from "../../redux/actions/analytics.action";
import Map from "./Map";
require("highcharts/highcharts-more")(Highcharts);

export default function Analytics() {
  const [data, setData] = useState([]);
  const { census } = useSelector((state) => state.analytics);
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(fetchCensusLocation());
  }, [dispatch]);

  useEffect(() => {
    dispatch(fetchCensusChart());
  }, [dispatch]);

  useEffect(() => {
    Highcharts.chart("heatmap", {
      colorAxis: {
        minColor: "#FFFFFF",
        maxColor: Highcharts.getOptions().colors[0],
      },
      credits: {
        enabled: false,
      },
      series: [
        {
          type: treemap,
          layoutAlgorithm: "stripes",
          data: [
            {
              name: "A",
              value: 6,
              colorValue: 1,
            },
            {
              name: "B",
              value: 6,
              colorValue: 2,
            },
            {
              name: "C",
              value: 4,
              colorValue: 3,
            },
            {
              name: "D",
              value: 3,
              colorValue: 4,
            },
            {
              name: "E",
              value: 2,
              colorValue: 5,
            },
            {
              name: "F",
              value: 2,
              colorValue: 6,
            },
            {
              name: "G",
              value: 1,
              colorValue: 7,
            },
          ],
        },
      ],
      title: {
        text: "",
      },
      chart: {
        zoomType: "x",
        height: "200",
      },
    });

    axios
      .get(
        "https://cdn.jsdelivr.net/gh/highcharts/highcharts@v7.0.0/samples/data/usdeur.json"
      )
      .then((res) => {
        // console.log(res);
        setData(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  useEffect(() => {
    Highcharts.chart("patientTotal", {
      chart: {
        zoomType: "x",
        height: "200",
        width: "380",
      },
      credits: {
        enabled: false,
      },
      title: {
        text: "",
      },
      xAxis: {
        type: "datetime",
        labels: {
          enabled: false,
        },
      },
      yAxis: {
        title: {
          text: "",
        },
        labels: {
          enabled: false,
        },
      },
      legend: {
        enabled: false,
      },
      plotOptions: {
        area: {
          fillColor: {
            linearGradient: {
              x1: 0,
              y1: 0,
              x2: 0,
              y2: 1,
            },
            stops: [
              [0, Highcharts.getOptions().colors[0]],
              [
                1,
                Highcharts.color(Highcharts.getOptions().colors[0])
                  .setOpacity(0)
                  .get("rgba"),
              ],
            ],
          },
          marker: {
            radius: 2,
          },
          lineWidth: 1,
          states: {
            hover: {
              lineWidth: 1,
            },
          },
          threshold: null,
        },
      },

      series: [
        {
          type: "area",
          name: "Total Patient",
          data: data,
        },
      ],
    });
  }, [data]);
  return (
    <div className="app" style={{ width: "100%", height: "100vh" }}>
      <div className="wrapper">
        <div className="conversation-area">
          <div className="text-center chat-area-header pl-10">
            <h6 className="page-title">Patient Gain</h6>
          </div>
          <div className="row pl-20">
            <figure className="highcharts-figure mb-05">
              <div id="patientTotal"></div>
            </figure>
          </div>
          <div className="font-12 pl-20 bb-3 analytics-text">
            <p className="mb-0">Total Patients: 32,105</p>
            <p>Updated 4 hrs ago</p>
          </div>

          <div className="p-20 bb-3">
            <h6 className="page-title font-14">Medicord Coverage</h6>
            <p className="pt-10">
              ASHA in partnership with Karma Health's 40+ staff, including over
              10 CHWs, provide integrated care delivery to a catchment
              population of over 265,000. Possible has seen over 1 million
              facility-based visits to date, and made over 470,000 home visits
              to deliver care and counseling.
            </p>

            <div className="d-inline-block pt-10 mb-0">
              <a className="mr-2 btn btn-light font-12 text-left" href="#">
                Rajpur
              </a>
              <a className="mr-2 btn btn-light font-12 text-left" href="#">
                Bela
              </a>
              <a className="mr-2 btn btn-light font-12 text-left" href="#">
                Dang
              </a>
            </div>
          </div>

          <div className="p-20">
            <h6 className="page-title font-14">Human Resource Summary</h6>
            <h1>1</h1>
            <table style={{ width: "100%" }}>
              <tbody>
                <tr>
                  <td>Clinicians</td>
                  <td>1</td>
                </tr>
                <tr>
                  <td>Researchers</td>
                  <td>0</td>
                </tr>
                <tr>
                  <td>Nurses</td>
                  <td>0</td>
                </tr>
                <tr>
                  <td>System Admin</td>
                  <td>0</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>

        <div className="chat-area">
          <div className="chat-area-header d-block">
            <div className="card-header card-header-tab-animation">
              <ul className="nav">
                <li className="nav-item">
                  <a
                    data-toggle="tab"
                    href="#census"
                    className="nav-link nav-analytics show active"
                  >
                    <i className="font-16 fas fa-first-aid mr-2"></i>
                      Census
                  </a>
                </li>
              </ul>
            </div>
          </div>
          <div className="chat-area-main">
            <div className="tab-content">
              <div
                className="tab-pane active show"
                id="public-health"
                role="tabpanel"
              >
                <div id="container-map" style={{ height: "70vh" }}>
                  {census.data && <Map data={census.data} />}
                </div>

                <div className="mb-3 card card-fly br-05">
                  <div className="p-20 ">
                    <div className="card-header card-header-tab-animation bb-1 br-0">
                      <ul className="nav mlr--20">
                        <li className="nav-item text-left">
                          <a
                            data-toggle="tab"
                            href="#tab-eg115-0"
                            className="bold nav-link show active mb-0"
                          >
                            <p className="font-12 mb-05 mt--10">
                             
                            </p>
                            <p className="ml-0 mb-2 mr-2 badge badge-warning">
                              LOW
                            </p>
                          </a>
                        </li>
                        <li className="nav-item">
                          <a
                            data-toggle="tab"
                            href="#tab-eg115-1"
                            className="bold mb-0 nav-link show"
                          >
                            <p className="font-12 mb-0">Total census data.</p>
                            <p className="ml-0 mb-2 mr-2 font-24 text-mute">
                              {census?.data?.length}
                            </p>
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>

                  <div className="card-body">
                    <div className="tab-content">
                      <div
                        className="tab-pane show active"
                        id="tab-eg115-0"
                        role="tabpanel"
                      >
                        <figure className="highcharts-figure">
                          <div id="heatmap"></div>
                        </figure>
                      </div>
                      <div
                        className="tab-pane show"
                        id="tab-eg115-1"
                        role="tabpanel"
                      >
                        <p>
                          Like Aldus PageMaker including versions of Lorem. It
                          has survived not only five centuries, but also the
                          leap into electronic typesetting, remaining
                          essentially unchanged.{" "}
                        </p>
                      </div>
                      <div
                        className="tab-pane show"
                        id="tab-eg115-2"
                        role="tabpanel"
                      >
                        <p>
                          Lorem Ipsum has been the industry's standard dummy
                          text ever since the 1500s, when an unknown printer
                          took a galley of type and scrambled it to make a type
                          specimen book. It has survived not only five
                          centuries, but also the leap into electronic
                          typesetting, remaining essentially unchanged.{" "}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div
                className="tab-pane show"
                id="maternal-care"
                role="tabpanel"
              ></div>
              <div
                className="tab-pane show"
                id="chronic-care"
                role="tabpanel"
              ></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
