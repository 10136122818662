import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchInfantSingle,
  updateInfant,
} from "../../../redux/actions/infant.action";
import { useParams, Link, useNavigate, useLocation } from "react-router-dom";
import { useForm } from "react-hook-form";
import { NepaliDatePicker } from "nepali-datepicker-reactjs";
import moment from "moment";
import reformatDateString from "../../../utils/reformatDateString";
var adbs = require("ad-bs-converter");

export default function IUpdate() {
  const { infant, updating } = useSelector((state) => state.infant);

  const { id } = useParams();
  const dispatch = useDispatch();
  const [date, setDate] = useState();
  const [age, setAge] = useState();

  const { register, handleSubmit, reset } = useForm();

  const [gender, setGender] = useState();

  const onSubmit = async (data) => {
    data.infantModifyDate = new Date().toLocaleString();
    if (age) {
      data.infantAgeInDays = age.days;
      data.infantAgeInMonth = age.months;
      data.infantAgeInYear = age.years;
    }
    var formatedDobBS = moment(new Date(date)).format("DD/MM/YYYY");
    var formatedDobAD = moment(new Date(data.infantDobEnglish)).format(
      "DD/MM/YYYY"
    );

    data.infantDobNepali = formatedDobBS;
    data.infantDobEnglish = formatedDobAD;
    data.infantGender = gender;
    dispatch(updateInfant(data));
  };

  const onBSChange = (dateBS) => {
    //convert BS TO ADs
    const dateAD = adbs.bs2ad(moment(new Date(dateBS)).format("YYYY/MM/DD"));
    //add before 0 when month and year less than 10
    const month = dateAD.month > 9 ? dateAD.month : "0" + dateAD.month;
    const day = dateAD.day > 9 ? dateAD.day : "0" + dateAD.day;
    const dobAD = dateAD.year + "-" + month + "-" + day;
    setDate(dateBS);
    //format date for calculating age YYYY-MM-DD to MM/DD/YYYY
    var formatedDobAD = moment(new Date(dobAD)).format("MM/DD/YYYY");
    const age = getAge(formatedDobAD);
    setAge(age.age);
    //update AD date and Age in form
    reset({
      infantDobEnglish: dobAD,
      infantAge: age.ageString || "",
      longitude: infant?.longitude || "",
      latitude: infant?.latitude || "",
      infantModifyDate: infant?.infantModifyDate,
      infantAddedDate: infant?.infantAddedDate || "",
      creator: infant?.user?.chw_name || "",
      infantId: infant?.infantId || "",
    });
  };

  const onADChange = () => {
    //get selected AD date
    let dateAD = document.getElementById("infantDob").value;
    //convert AD TO BS
    const dateBS = adbs.ad2bs(moment(new Date(dateAD)).format("YYYY/MM/DD")).en;
    const dobBS = dateBS.year + "-" + dateBS.month + "-" + dateBS.day;
    var formatedDobAD = moment(new Date(dateAD)).format("MM/DD/YYYY");
    const age = getAge(formatedDobAD);
    setAge(age.age);

    //update AD date and Age in form
    setDate(dobBS);
    reset({
      infantAge: age.ageString || "",
      longitude: infant?.longitude || "",
      latitude: infant?.latitude || "",
      infantModifyDate: infant?.infantModifyDate,
      infantAddedDate: infant?.infantAddedDate || "",
      creator: infant?.user?.chw_name || "",
      infantId: infant?.infantId || "",
    });
  };

  function getAge(dateString) {
    var now = new Date();
    var yearNow = now.getYear();
    var monthNow = now.getMonth();
    var dateNow = now.getDate();
    var dob = new Date(
      dateString.substring(6, 10),
      dateString.substring(0, 2) - 1,
      dateString.substring(3, 5)
    );
    var yearDob = dob.getYear();
    var monthDob = dob.getMonth();
    var dateDob = dob.getDate();
    var age = {};
    var ageString = "";
    var yearString = "";
    var monthString = "";
    var dayString = "";

    var yearAge = yearNow - yearDob;

    if (monthNow >= monthDob) var monthAge = monthNow - monthDob;
    else {
      yearAge--;
      var monthAge = 12 + monthNow - monthDob;
    }

    if (dateNow >= dateDob) var dateAge = dateNow - dateDob;
    else {
      monthAge--;
      var dateAge = 31 + dateNow - dateDob;

      if (monthAge < 0) {
        monthAge = 11;
        yearAge--;
      }
    }

    age = {
      years: yearAge,
      months: monthAge,
      days: dateAge,
    };

    if (age.years > 1) yearString = " years";
    else yearString = " year";
    if (age.months > 1) monthString = " months";
    else monthString = " month";
    if (age.days > 1) dayString = " days";
    else dayString = " day";

    if (age.years > 0 && age.months > 0 && age.days > 0)
      ageString =
        age.years +
        yearString +
        ", " +
        age.months +
        monthString +
        ", and " +
        age.days +
        dayString +
        " old.";
    else if (age.years == 0 && age.months == 0 && age.days > 0)
      ageString = "Only " + age.days + dayString;
    else if (age.years > 0 && age.months == 0 && age.days == 0)
      ageString = age.years + yearString;
    else if (age.years > 0 && age.months > 0 && age.days == 0)
      ageString = age.years + yearString + " and " + age.months + monthString;
    else if (age.years == 0 && age.months > 0 && age.days > 0)
      ageString = age.months + monthString + " and " + age.days + dayString;
    else if (age.years > 0 && age.months == 0 && age.days > 0)
      ageString = age.years + yearString + " and " + age.days + dayString;
    else if (age.years == 0 && age.months > 0 && age.days == 0)
      ageString = age.months + monthString;
    else ageString = "Oops! Could not calculate age!";

    return { ageString, age };
  }

  useEffect(() => {
    reset(
      infant
        ? {
            infantFirstName: infant?.infantFirstName || "",
            infantLastName: infant?.infantLastName || "",
            infantAddedDate: infant?.infantAddedDate || "",
            infantAge: infant?.infantAge || "",
            longitude: infant?.longitude || "",
            latitude: infant?.latitude || "",
            infantId: infant?.infantId || "",
            infantDobEnglish:
              reformatDateString(infant?.infantDobEnglish) || "",
            creator: infant?.user?.chw_name || "",
            infantFullName: infant?.infantFullName || "",
            infantHouseno: infant?.infantHouseno || "",
            infantPhone: infant?.infantPhone || "",
            infantmotherfirstname: infant?.infantmotherfirstname || "",
            infantVillagename: infant?.infantVillagename || "",
            infantmotherlastname: infant?.infantmotherlastname || "",

            infantwardno: infant?.infantwardno || "",
            image: infant?.image || "",
            infantModifyDate: infant?.infantModifyDate,
          }
        : {}
    );

    setGender(infant?.infantGender);
    setDate(reformatDateString(infant?.infantDobNepali));
  }, [infant]);

  useEffect(() => {
    dispatch(fetchInfantSingle(id));
  }, [dispatch]);
  const navigate = useNavigate();
  let { search } = useLocation();

  const onPatientClick = () => {
    navigate(-1);
  };
  return (
    <>
      <div className="app-main__inner">
        <div className="app-page-title pt-10">
          <div className="page-title-wrapper">
            <div className="page-title-heading">
              <div className="card-body p-0">
                <ul className="body-tabs body-tabs-layout tabs-animated body-tabs-animated nav">
                  <li className="nav-item">
                    <h5
                      style={{
                        marginTop: "16px",
                        fontSize: "18px",
                        fontWeight: "700",
                        paddingBottom: "10px",
                      }}
                    >
                      {search == "?istaff" ? (
                        <>
                          <Link to="/staff">Staff List</Link> &emsp;
                          <i className="fas fa-angle-right"></i> &emsp;
                          <Link to="" onClick={onPatientClick}>
                            Infant List
                          </Link>
                          &emsp; <i className="fas fa-angle-right"></i> &emsp;
                        </>
                      ) : (
                        <>
                          <Link to="/patient?infant">Infant List</Link> &emsp;
                          <i className="fas fa-angle-right"></i>
                        </>
                      )}
                    </h5>
                  </li>
                  <li className="nav-item">
                    <Link
                      role="tab"
                      className="nav-link active font-small-14 bold"
                      id="tab-0"
                      data-toggle="tab"
                      to="#"
                    >
                      <span>Update</span>
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
            <div className="page-title-actions pt-20">
              <div className="d-inline-block">
                <div className="d-inline-block">
                  <Link to="/infant" className="mb-2 mr-2 btn btn-light">
                    <span className="btn-icon-wrapper pr-2 opacity-7">
                      <i className="fas fa-chevron-left"></i>
                    </span>
                    Cancel
                  </Link>
                  {updating ? (
                    <>
                      <Link
                        className="mb-2 mr-2 btn btn-success"
                        to=""
                        onClick={handleSubmit(onSubmit)}
                        disabled
                      >
                        <span className="btn-icon-wrapper pr-2 opacity-7">
                          <i className="fas fa-check"></i>
                        </span>
                        Saving Changes
                      </Link>
                    </>
                  ) : (
                    <>
                      <Link
                        className="mb-2 mr-2 btn btn-success"
                        to=""
                        onClick={handleSubmit(onSubmit)}
                      >
                        <span className="btn-icon-wrapper pr-2 opacity-7">
                          <i className="fas fa-check"></i>
                        </span>
                        Save Changes
                      </Link>
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="app" style={{ height: "90vh !important" }}>
        <form className="wrapper">
          <div className="conversation-area">
            <div className="p-20 pb-10">
              <h6
                className="page-title"
                style={{
                  fontSize: "18px",
                  fontWeight: "700",
                  paddingBottom: "10px",
                  paddingLeft: "10px",
                }}
              >
                Infant Meta Info
              </h6>
            </div>
            <div className="mb-3 mt--20 ml-10">
              <div className="card-body">
                <div className="row">
                  <div className="col-md-6">
                    <div className="d-flex flex-column flex-sm-row justify-content-between mb-3">
                      <div className="text-left text-sm-left mb-2 mb-sm-0">
                        <div className="pb-20">
                          <label htmlFor="article-title">infant Id</label>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="DH-01AY"
                            disabled
                            {...register("infantId")}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="position-relative form-group">
                  <label htmlFor="article-owner" className="">
                    Added By
                  </label>
                  <input
                    name="owner"
                    id="article-owner"
                    type="text"
                    className="form-control"
                    {...register("creator")}
                    disabled
                  />
                </div>
                <div className="position-relative form-group">
                  <label className="">Added Date</label>
                  <input
                    type="text"
                    className="form-control"
                    {...register("infantAddedDate")}
                    disabled
                  />
                </div>{" "}
                <div className="position-relative form-group">
                  <label className="">Last Modified Date</label>
                  <input
                    type="text"
                    className="form-control"
                    {...register("infantModifyDate")}
                    disabled
                  />
                </div>
                <div className="position-relative form-group">
                  <label className="">Latitude</label>
                  <input
                    type="text"
                    className="form-control"
                    {...register("latitude")}
                    disabled
                  />
                </div>
                <div className="position-relative form-group">
                  <label className="">Longitude</label>
                  <input
                    type="text"
                    className="form-control"
                    {...register("longitude")}
                    disabled
                  />
                </div>
              </div>
            </div>
          </div>
          <div
            className="chat-area"
            style={{ background: "white", paddingLeft: "20px" }}
          >
            <div className="chat-area-header">
              <div className="row w-100">
                <div className="px-0 pb-0 mb-3">
                  <div className="form-card p-1333">
                    <div className="row">
                      <div className="col-md-12">
                        <h6 className="text-uppercase">Infant Information</h6>
                        <hr />
                      </div>
                      <div className="col-md-4 pb-20">
                        <label htmlFor="article-title">First Name</label>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Enter first name"
                          {...register("infantFirstName")}
                        />
                      </div>
                      {/* <div className="col-md-4 pb-20">
                        <label htmlFor="article-title">Middle Name</label>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Enter middle name"
                          {...register("infantMiddleName")}
                        />
                      </div> */}
                      <div className="col-md-4 pb-20">
                        <label htmlFor="article-title">Last Name</label>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Enter last name"
                          required=""
                          {...register("infantLastName")}
                        />
                      </div>
                      <div className="col-md-4 pb-20">
                        <label htmlFor="article-expiry" className="">
                          Date of Birth (A.D.)
                        </label>
                        <input
                          name="article-expiry"
                          id="infantDob"
                          type="date"
                          className="form-control"
                          {...register("infantDobEnglish")}
                          onChange={(value) => onADChange(value)}
                        />
                      </div>{" "}
                      <div className="col-md-4 pb-20">
                        <label htmlFor="article-expiry" className="">
                          Date of Birth (B.S.)
                        </label>

                        <NepaliDatePicker
                          inputClassName="form-control"
                          value={date}
                          onChange={(value) => onBSChange(value)}
                          options={{
                            calenderLocale: "ne",
                            valueLocale: "en",
                          }}
                        />
                      </div>
                      <div className="col-md-4 pb-20">
                        <label htmlFor="article-expiry" className="">
                          Age
                        </label>
                        <input
                          name="article-expiry"
                          id="article-expiry"
                          type="text"
                          className="form-control"
                          {...register("infantAge")}
                          disabled
                        />
                      </div>
                      <div className="col-md-6">
                        <label>Gender at Birth</label>
                        <div className="custom-radio custom-control">
                          <input
                            type="radio"
                            id="g-male"
                            value="Male"
                            checked={gender == "Male"}
                            name="customRadio"
                            className="custom-control-input"
                            onChange={(e) => setGender(e.currentTarget.value)}
                          />
                          <label
                            className="custom-control-label"
                            htmlFor="g-male"
                          >
                            Male
                          </label>
                        </div>

                        <div className="custom-radio custom-control">
                          <input
                            type="radio"
                            id="g-femlae"
                            value="Female"
                            checked={gender == "Female"}
                            name="customRadio"
                            className="custom-control-input"
                            onChange={(e) => setGender(e.currentTarget.value)}
                          />
                          <label
                            className="custom-control-label"
                            htmlFor="g-femlae"
                          >
                            Female
                          </label>
                        </div>
                      </div>
                      <div className="col-md-12 pt-30">
                        <h6 className="text-uppercase">Contact Information</h6>
                        <hr />
                      </div>
                      <div className="col-md-6 pb-20">
                        <label htmlFor="article-title">Contact Number</label>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Enter contact number"
                          {...register("infantPhone")}
                        />
                      </div>
                      <div className="col-md-12 pt-20">
                        <h6 className="text-uppercase">Address Information</h6>
                        <hr />
                      </div>
                      <div className="col-md-4 pb-20">
                        <label htmlFor="article-title">Village</label>
                        <input
                          type="text"
                          className="form-control"
                          {...register("infantVillagename")}
                        />
                      </div>
                      <div className="col-md-4 pb-20">
                        <label htmlFor="article-title">Ward</label>
                        <input
                          type="number"
                          className="form-control"
                          {...register("infantwardno")}
                        />
                      </div>
                      <div className="col-md-4 pb-20">
                        <label htmlFor="article-title">House Number</label>
                        <input
                          type="text"
                          className="form-control"
                          {...register("infantHouseno")}
                        />
                      </div>
                    </div>
                  </div>

                  <div className="form-card p-1333">
                    <div className="row">
                      <div className="col-md-12 pt-20">
                        <h6 className="text-uppercase">Mother Information</h6>
                        <hr />
                      </div>
                      <div className="col-md-4 pb-20">
                        <label htmlFor="article-title">Mother First Name</label>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Enter first name"
                          {...register("infantmotherfirstname")}
                        />
                      </div>

                      <div className="col-md-4 pb-20">
                        <label htmlFor="article-title">Mother Last Name</label>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Enter last name"
                          {...register("infantmotherlastname")}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
    </>
  );
}
