import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchPatientsByCHW,
  removePatient,
} from "../../../redux/actions/staff.action";
import ReactPaginate from "react-paginate";
import { useParams, Link } from "react-router-dom";
import Skeleton from "react-loading-skeleton";
import getFirstCharacters from "../../../utils/getFirstCharacters";

export default function CHWPatientTable() {
  const { patients, total, loading } = useSelector((state) => state.staff);
  const { id } = useParams();
  const [perPage, setPerPage] = useState(8);
  const [currentPage, setCurrentPage] = useState(0);
  const dispatch = useDispatch();
  const [delId, setDelId] = useState(0);
  useEffect(() => {
    dispatch(
      fetchPatientsByCHW(id, {
        perPage: perPage,
        currentPage: currentPage,
      })
    );
  }, [dispatch]);

  const handlePageClick = (event) => {
    setCurrentPage(event.selected);

    dispatch(
      fetchPatientsByCHW(id, {
        perPage: perPage,
        currentPage: event.selected,
      })
    );
  };

  const pageCount = Math.ceil(total ? total / perPage : 0);
  function onPatientDelete() {
    dispatch(removePatient(delId));
  }

  return (
    <div className="row">
      <div className="col-md-12">
        {!loading && patients?.length === 0 ? (
          <div style={{ textAlign: "center" }}>
            <img
              src="/nodata.png"
              style={{ maxWidth: "300px", paddingTop: "80px" }}
            />
            <h6 className="bold">No data found</h6>
            <p className="text-mute">
              There are no recorded patients at this moment to display.
            </p>
          </div>
        ) : (
          <>
            <table id="patientList" className="table table-hover mt-05">
              <thead>
                <tr>
                  <th
                    className="bt-0 mb-15 bb-0 font-12"
                    scope="col"
                    width="5%"
                  ></th>
                  <th
                    className="bt-0 mb-15 bb-0 font-12"
                    scope="col"
                    width="15%"
                  >
                    Patient ID.
                  </th>
                  <th
                    className="bt-0 mb-15 bb-0 font-12"
                    scope="col"
                    width="25%"
                  >
                    Basic Info
                  </th>
                  <th
                    className="bt-0 mb-15 bb-0 font-12"
                    scope="col"
                    width="15%"
                  >
                    Municipality
                  </th>
                  <th
                    className="bt-0 mb-15 bb-0 font-12"
                    scope="col"
                    width="15%"
                  >
                    Village
                  </th>
                  <th
                    className="bt-0 mb-15 bb-0 font-12"
                    scope="col"
                    width="15%"
                  >
                    Added Date
                  </th>
                  <th
                    className="bt-0 mb-15 bb-0 font-12"
                    scope="col"
                    width="10%"
                  ></th>
                </tr>
              </thead>
              {loading ? (
                new Array(8).fill("", 0, 8).map((i, index) => (
                  <tbody id="patient-list" key={index}>
                    <tr>
                      <td className="text-center bl-20">
                        <Skeleton width={20} />
                      </td>
                      <td>
                        <Skeleton width={50} />
                      </td>
                      <td>
                        <div className="row pt-10 pb-10">
                          <div className="col-md-2">
                            <Skeleton
                              height={45}
                              width={45}
                              borderRadius={50}
                            />
                          </div>
                          <div className="col-md-10">
                            <Skeleton width={200} />
                            <p className="mb-0 text-mute">
                              <Skeleton width={150} />
                            </p>
                          </div>
                        </div>
                      </td>
                      <td>
                        <Skeleton width={150} />
                      </td>
                      <td>
                        <Skeleton width={150} />
                      </td>
                      <td>
                        <Skeleton width={150} />
                      </td>
                      <td className="br-20">
                        <Skeleton width={25} />
                      </td>
                    </tr>
                  </tbody>
                ))
              ) : (
                <tbody id="patient-list">
                  {patients?.map((patient) => {
                    return (
                      <tr key={patient.patientId}>
                        <td className="text-center bl-20">
                          <div className="custom-control custom-checkbox pl-40">
                            <input
                              type="checkbox"
                              className="custom-control-input"
                              id="customCheck1"
                            />
                            <label
                              className="custom-control-label mt--20"
                              htmlFor="customCheck1"
                            ></label>
                          </div>
                        </td>
                        <td>{patient.patientId}</td>
                        <td>
                          <div className="row pt-10 pb-10">
                            <div className="col-md-2">
                              {patient.image ? (
                                <img
                                  width="42"
                                  className="rounded-circle"
                                  src={patient?.image}
                                  alt={patient?.patientFullName}
                                />
                              ) : (
                                <div
                                  className="circle rounded-circle"
                                  style={{ margin: "auto" }}
                                >
                                  <span className="initials">
                                    {getFirstCharacters(
                                      patient.patientFullName
                                    )}
                                  </span>
                                </div>
                              )}
                            </div>
                            <div className="col-md-10">
                              <Link
                                to={`../spatient_detail/${patient.patientId}?pstaff`}
                              >
                                <p className="mb-0 bold">
                                  {patient.patientFullName}
                                </p>
                                <p className="mb-0 text-mute">
                                  977 {patient.patientPhone}
                                </p>
                              </Link>
                            </div>
                          </div>
                        </td>
                        <td>{patient.patientMunicipality}</td>
                        <td>{patient.patientVillagename}</td>
                        <td>{patient.patientAddedDate}</td>
                        <td className="br-20">
                          <div className="dropdown dropleft d-inline-block mt-10">
                            <button
                              type="button"
                              aria-haspopup="true"
                              aria-expanded="false"
                              data-toggle="dropdown"
                              className="mb-2 mr-2 btn"
                            >
                              <i className="fas fa-ellipsis-h"></i>
                            </button>
                            <div
                              tabIndex="-1"
                              role="menu"
                              aria-hidden="true"
                              className="dropdown-menu"
                            >
                              <Link
                                to={`../pupdate/${patient.patientId}?pstaff`}
                              >
                                <button
                                  type="button"
                                  tabIndex="0"
                                  className="dropdown-item"
                                >
                                  <i className="fas fa-edit mr-2 text-mute"></i>
                                  Edit Patient
                                </button>
                              </Link>
                              <button
                                type="button"
                                tabIndex="0"
                                className="dropdown-item"
                                data-toggle="modal"
                                data-target="#deleteModal_11"
                                onClick={() => setDelId(patient.patientId)}
                              >
                                <i className="fas fa-trash mr-2 text-mute"></i>
                                Delete Patient
                              </button>
                            </div>
                          </div>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              )}
            </table>
            <div className="row col-sm-6 text-right">
              <ReactPaginate
                previousLabel={"← Previous"}
                nextLabel={"Next →"}
                breakLabel="..."
                pageCount={pageCount}
                onPageChange={handlePageClick}
                containerClassName={"pagination"}
                previousLinkClassName={"pagination__link"}
                nextLinkClassName={"pagination__link"}
                disabledClassName={"disabled"}
                activeClassName={"active"}
                // renderOnZeroPageCount={"<h43>Loading</h43>"}
              />
            </div>
          </>
        )}
      </div>
      <div
        className="modal fade"
        id="deleteModal_11"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLabel">
                Delete Patient
              </h5>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">×</span>
              </button>
            </div>

            <div className="modal-body">
              <p className="mb-0">
                Are you sure you want to delete this patient?
              </p>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-secondary"
                data-dismiss="modal"
              >
                Cancel
              </button>
              <input
                type="submit"
                id="submit"
                onClick={() => onPatientDelete()}
                className="btn btn-primary"
                value="Confirm"
                data-dismiss="modal"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
