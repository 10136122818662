import {
  PATIENT_FETCH,
  PATIENT_FETCHED,
  LOGOUT_USER,
  PATIENT_SINGLE_FETCH,
  PATIENT_SINGLE_FETCHED,
  PATIENT_DELETED,
  PATIENT_UPDATE,
  PATIENT_UPDATE_FAILURE,
  PATIENT_UPDATE_SUCCESS,
} from "../constants/types";
import http from "../../utils/http";
import toast from "../../utils/toast";

export const fetchPatients =
  ({ perPage, currentPage }) =>
  (dispatch) => {
    dispatch({ type: PATIENT_FETCH });
    currentPage += 1;
    http
      .GET("Patient/get", { perPage, currentPage })
      .then((res) => {
        dispatch({ type: PATIENT_FETCHED, payload: res.data });
      })
      .catch((error) => {
        if (error.response.data.code === "401") dispatch({ type: LOGOUT_USER });
        toast.error(error);
      });
  };
export const fetchPatientSingle = (id) => (dispatch) => {
  dispatch({ type: PATIENT_SINGLE_FETCH });
  http
    .GET("Patient/details/" + id)
    .then((res) => {
      dispatch({ type: PATIENT_SINGLE_FETCHED, payload: res.data });
    })
    .catch((error) => {
      if (error.response.data.code === "401") dispatch({ type: LOGOUT_USER });
      toast.error(error);
    });
};

export const removePatient = (id) => (dispatch) => {
  http
    .REMOVE("Patient/delete/" + id)
    .then((res) => {
      toast.success(res.data.message);
      dispatch({ type: PATIENT_DELETED, payload: id });
    })
    .catch((error) => {
      if (error.response.data.code === "401") dispatch({ type: LOGOUT_USER });
      toast.error(error);
    });
};

export const updatePatient = (data) => (dispatch) => {
  dispatch({ type: PATIENT_UPDATE });
  http
    .PUT("/Patient/update/" + data.pid, data)
    .then((res) => {
      dispatch({ type: PATIENT_UPDATE_SUCCESS, payload: res.data });
      toast.success("Patient Updated Successfully.");
    })
    .catch((error) => {
      if (error.response.data.code === "401") dispatch({ type: LOGOUT_USER });
      toast.error(error);
      dispatch({ type: PATIENT_UPDATE_FAILURE });
    });
};
