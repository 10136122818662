import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchInfants,
  removeInfant,
} from "./../../../redux/actions/infant.action";
import ReactPaginate from "react-paginate";
import { Link } from "react-router-dom";
import Skeleton from "react-loading-skeleton";

export default function InfantTable() {
  const { infants, total, loading } = useSelector((state) => state.infant);

  const [perPage, setPerPage] = useState(9);
  const [currentPage, setCurrentPage] = useState(0);
  const dispatch = useDispatch();
  const [delId, setDelId] = useState(0);

  useEffect(() => {
    dispatch(
      fetchInfants({
        perPage: perPage,
        currentPage: currentPage,
      })
    );
  }, [dispatch]);
  // console.log(infants);
  const handlePageClick = (event) => {
    setCurrentPage(event.selected);

    dispatch(
      fetchInfants({
        perPage: perPage,
        currentPage: event.selected,
      })
    );
  };

  const pageCount = Math.ceil(total ? total / perPage : 0);
  function onInfantDelete() {
    dispatch(removeInfant(delId));
  }
  return (
    <div className="row">
      <div className="col-md-12">
        <table id="patientList" className="table table-hover mt-05">
          <thead>
            <tr>
              <th
                className="bt-0 mb-15 bb-0 font-12"
                scope="col"
                width="5%"
              ></th>
              <th className="bt-0 mb-15 bb-0 font-12" scope="col" width="5%">
                Infant ID.
              </th>
              <th className="bt-0 mb-15 bb-0 font-12" scope="col" width="20%">
                Basic Info
              </th>
              <th className="bt-0 mb-15 bb-0 font-12" scope="col" width="20%">
                Parents Info
              </th>
              <th className="bt-0 mb-15 bb-0 font-12" scope="col" width="15%">
                Gender
              </th>
              <th className="bt-0 mb-15 bb-0 font-12" scope="col" width="15%">
                Age
              </th>
              <th className="bt-0 mb-15 bb-0 font-12" scope="col" width="15%">
                Address
              </th>

              <th className="bt-0 mb-15 bb-0 font-12" scope="col" width="15%">
                Added Date
              </th>
              <th
                className="bt-0 mb-15 bb-0 font-12"
                scope="col"
                width="10%"
              ></th>
            </tr>
          </thead>

          {loading ? (
            new Array(8).fill("", 0, 8).map((i) => (
              <tbody id="patient-list">
                <tr>
                  <td className="text-center bl-20">
                    <Skeleton width={20} />
                  </td>
                  <td>
                    <Skeleton width={50} />
                  </td>
                  <td>
                    <div className="row pt-10 pb-10">
                      <div className="col-md-10">
                        <Skeleton width={200} />
                        <p className="mb-0 text-mute">
                          <Skeleton width={150} />
                        </p>
                      </div>
                    </div>
                  </td>
                  <td>
                    <Skeleton width={150} />
                  </td>
                  <td>
                    <Skeleton width={100} />
                  </td>
                  <td>
                    <Skeleton width={100} />
                  </td>
                  <td>
                    <Skeleton width={150} />
                  </td>
                  <td>
                    <Skeleton width={150} />
                  </td>
                  <td className="br-20">
                    <Skeleton width={25} />
                  </td>
                </tr>
              </tbody>
            ))
          ) : (
            <tbody id="patient-list">
              {infants?.map((infant) => {
                return (
                  <tr key={infant.infantId}>
                    <td className="text-center bl-20">
                      <div className="custom-control custom-checkbox pl-40">
                        <input
                          type="checkbox"
                          className="custom-control-input"
                          id="customCheck1"
                        />
                        <label
                          className="custom-control-label mt--20"
                          htmlFor="customCheck1"
                        ></label>
                      </div>
                    </td>
                    <td>{infant.infantId}</td>
                    <td>
                      <div className="row pt-10 pb-10">
                        <div className="col-md-10">
                          <Link to={`infant_detail/${infant.infantId}`}>
                            <p className="mb-0 bold">{infant.infantFullName}</p>
                            <p className="mb-0 text-mute">
                              977 {infant.infantPhone}
                            </p>
                          </Link>
                        </div>
                      </div>
                    </td>
                    <td>
                      <div className="row pt-10 pb-10">
                        <div className="col-md-10">
                          <p className="mb-0 bold">
                            {infant.infantmotherfirstname +
                              " " +
                              infant.infantmotherlastname}
                          </p>
                        </div>
                      </div>
                    </td>
                    <td>{infant.infantGender}</td>
                    <td>{infant.infantAge}</td>
                    <td>
                      <div className="row pt-10 pb-10">
                        <div className="col-md-10">
                          <p className="mb-0 bold">
                            {infant.infantVillagename}
                          </p>
                          <p className="mb-0 text-mute">
                            Ward no: {infant.infantwardno}
                          </p>
                        </div>
                      </div>
                    </td>

                    <td>{infant.infantAddedDate}</td>
                    <td className="br-20">
                      <div className="dropdown dropleft d-inline-block mt-10">
                        <button
                          type="button"
                          aria-haspopup="true"
                          aria-expanded="false"
                          data-toggle="dropdown"
                          className="mb-2 mr-2 btn"
                        >
                          <i className="fas fa-ellipsis-h"></i>
                        </button>
                        <div
                          tabIndex="-1"
                          role="menu"
                          aria-hidden="true"
                          className="dropdown-menu"
                        >
                          <Link to={`iupdate/${infant.infantId}`}>
                            <button
                              type="button"
                              tabIndex="0"
                              className="dropdown-item"
                            >
                              <i className="fas fa-edit mr-2 text-mute"></i>
                              Edit Infant
                            </button>
                          </Link>
                          <button
                            type="button"
                            tabIndex="0"
                            className="dropdown-item"
                            data-toggle="modal"
                            data-target="#deleteModal_11"
                            onClick={() => setDelId(infant.infantId)}
                          >
                            <i className="fas fa-trash mr-2 text-mute"></i>
                            Delete Infant
                          </button>
                        </div>
                      </div>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          )}
        </table>
        <div className="row col-sm-6 text-right">
          <ReactPaginate
            previousLabel={"← Previous"}
            nextLabel={"Next →"}
            breakLabel="..."
            pageCount={pageCount}
            onPageChange={handlePageClick}
            containerClassName={"pagination"}
            previousLinkClassName={"pagination__link"}
            nextLinkClassName={"pagination__link"}
            disabledClassName={"disabled"}
            activeClassName={"active"}
            // renderOnZeroPageCount={"<h43>Loading</h43>"}
          />
        </div>
      </div>{" "}
      <div
        className="modal fade"
        id="deleteModal_11"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLabel">
                Delete Infant
              </h5>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">×</span>
              </button>
            </div>

            <div className="modal-body">
              <p className="mb-0">
                Are you sure you want to delete this infant?
              </p>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-secondary"
                data-dismiss="modal"
              >
                Cancel
              </button>
              <input
                type="submit"
                id="submit"
                onClick={() => onInfantDelete()}
                className="btn btn-primary"
                value="Confirm"
                data-dismiss="modal"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
