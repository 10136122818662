import React, { useEffect, useState, useMemo } from "react";

import { useDispatch, useSelector } from "react-redux";
import Highcharts, { getDeferredAnimation } from "highcharts";

import { checkSyncHistory, fetchChartByCHW } from "../../../redux/actions/staff.action";

import downloadCSV from "../../../utils/downloadCSV";
import http from "../../../utils/http";
import toast from "../../../utils/toast";

require("highcharts/highcharts-more")(Highcharts);

function StaffDetailModel({ selectedId }) {
  const { staffs, log, loadingLog, chart, loadingChart, x, y } = useSelector((state) => state.staff);

  //tab option for patient detail
  const [tabOption, setTabOption] = useState("chwDetail"); //chwDetail | chwPatients
  /**
   * array for patient detail mapping
   */
  let columns = [
    { name: "Not Visited", accessor: "notVisitedCount", count: 0 },
    { name: "Risk Pregnancy", accessor: "riskPregnancyCount", count: 0 },
    { name: "1 Trimester", accessor: "trimester1", count: 0 },
    { name: "Pregnant Women", accessor: "pregnantWomenCount", count: 0 },
    { name: "2 Trimester", accessor: "trimester2", count: 0 },
    { name: "New Pregnancy", accessor: "newPregnancyCount", count: 0 },
    { name: "3 Trimester", accessor: "trimester3", count: 0 },
    { name: "Chronic Patient", accessor: "chronicPatientCount", count: 0 },
    { name: "4 Trimester", accessor: "trimester4", count: 0 },
    { name: "New Delivery", accessor: "newDeliveryCount", count: 0 },
    { name: "Trimester Finished", accessor: "trimesterfinished", count: 0 },
    { name: "Complication Delivery", accessor: "complicationDeliveryCount", count: 0 },
    { name: "New NCDs", accessor: "newNcdCount", count: 0 },
    { name: "Risk Patient", accessor: "riskPatientCount", count: 0 },
    { name: "Infant", accessor: "infantCount", count: 0 },
  ];
  const [patientDetails, setPatientDetails] = useState(columns);

  const dispatch = useDispatch();
  useEffect(() => {
    if (!!selectedId) {
      dispatch(checkSyncHistory(selectedId));
    }
  }, [selectedId]);

  useEffect(() => {
    if (!!selectedId) {
      dispatch(fetchChartByCHW(selectedId));
    }
  }, [selectedId]);

  useEffect(() => {
    if (!!chart) {
      Highcharts.chart("patientChart", {
        chart: {
          type: "spline",
          height: 330,
          backgroundColor: "#213155",
        },
        title: {
          text: " ",
        },
        legend: {
          itemStyle: {
            color: "#fff",
          },
        },
        xAxis: {
          categories: x,
          labels: {
            style: {
              color: "#fff",
            },
          },
        },
        yAxis: {
          title: {
            text: "",
          },
          labels: {
            style: {
              color: "#fff",
            },
          },
        },
        tooltip: {
          shared: true,
          valueSuffix: " patients",
        },
        credits: {
          enabled: false,
        },

        responsive: {
          rules: [
            {
              condition: {
                maxHeight: 200,
              },
            },
          ],
        },
        colors: ["#5698F0", "#DF7383"],
        series: [
          {
            name: "Patient Count",
            data: y,
          },
        ],
      });
    }
  });

  /**
   * populating td in tr
   */
  let renderTableDatas = () => {
    return patientDetails.map((patient, index) => {
      return (
        <div key={index} className="col-md-6 p-2">
          <p className="pb-0">
            {patient.name}
            <span className="ml-15 bold font-12">{patient.count !== 0 ? patient.count : "-"}</span>
          </p>
        </div>
      );
    });
  };

  /**
   *
   * @param {click event} event | event for id and targetted element
   * changes state based on click on event with specific id
   */
  const handleTabChange = (event) => {
    event.target.id !== tabOption ? setTabOption(event.target.id) : setTabOption(tabOption);
  };

  useEffect(() => {
    async function getData() {
      console.log("getting data");
      try {
        let response = await http.GET(`patient/stat/${selectedId}`);
        let data = response?.data?.data;
        if (response.status === 200) {
          setPatientDetails((patientDetails) => {
            return patientDetails.map((patient) => {
              return { ...patient, count: data[patient.accessor] };
            });
          });
        }
      } catch (error) {
        toast.error(error);
        console.log("error while fetching patient data", error);
      }
    }
    getData();
    return console.log("component exited");
  }, [selectedId]);

  /**
   * Download csv file in pc
   */
  let handleDownload = () => {
    let csvString = "";

    let headers = ["Name", "Count"];
    let keys = ["name", "count"];
    csvString += headers.join(",");
    csvString += "\n";
    /**
     * rows of collected data
     */
    let detailsArray = patientDetails.map((patient) => {
      let row = [];
      for (let i = 0; i < keys.length; i++) {
        row.push(patient[keys[i]]);
      }
      return row;
    });

    detailsArray.forEach((row) => {
      csvString += row.join(",");
      csvString += "\n";
    });
    downloadCSV(csvString, "patientsDetails");
  };

  const details = staffs?.filter((item) => item.chw_id === selectedId);

  return (
    <div>
      <ul className="nav nav-tabs m-2">
        <li className="nav-item">
          <div id="chwDetail" onClick={handleTabChange} className={`nav-link ${tabOption === "chwDetail" ? "active" : ""}`} aria-current="page" href="#">
            CHW detail
          </div>
        </li>
        <li className="nav-item">
          <div id="chwPatients" onClick={handleTabChange} className={`nav-link ${tabOption === "chwPatients" ? "active" : ""}`} href="#">
            CHW Patients
          </div>
        </li>
      </ul>
      {/* checking chwDetail or patient tab  */}
      {tabOption === "chwDetail" ? (
        <div className="modal-body" style={{ padding: "10px" }}>
          <div className="card-body pb-0 pl-0 pr-0">
            <table className="mb-0 table table-borderless" style={{ margin: "10px" }}>
              <tbody className="text-left font-12">
                <tr>
                  <td width="50%">
                    <p className="pb-0">
                      First Name
                      <span className="ml-15 bold font-12">{details?.length > 0 ? details[0].chw_firstname_nepali : "--"}</span>
                    </p>
                  </td>
                  <td width="50%">
                    <p className="pb-0">
                      Middle Name
                      <span className="ml-20 bold font-12">{details?.length > 0 ? details[0].chw_middlename_nepali : "--"}</span>
                    </p>
                  </td>
                </tr>{" "}
                <tr>
                  <td width="50%">
                    <p className="pb-0">
                      Last Name
                      <span className="ml-20 bold font-12">{details?.length > 0 ? details[0].chw_lastname_nepali : "--"}</span>
                    </p>
                  </td>{" "}
                  <td width="50%">
                    <p className="pb-0">
                      Date of Birth
                      <span className="ml-15 bold font-12">{details?.length > 0 ? details[0].chw_dob : "--"}</span>
                    </p>
                  </td>
                </tr>
                <tr>
                  <td width="50%">
                    <p className="pb-0">
                      Date of Birth Nepali
                      <span className="ml-20 bold font-12">{details?.length > 0 ? details[0].chw_dob_nepali : "--"}</span>
                    </p>
                  </td>
                  <td width="50%">
                    <p className="pb-0">
                      Age
                      <span className="ml-20 bold font-12">{details?.length > 0 ? details[0].chw_age : "--"}</span>
                    </p>
                  </td>
                </tr>{" "}
                <tr>
                  {" "}
                  <td width="50%">
                    <p className="pb-0">
                      Doc Type
                      <span className="ml-15 bold font-12">{details?.length > 0 ? details[0].chw_doc_type : ""}</span>
                    </p>
                  </td>
                  <td width="50%">
                    <p className="pb-0">
                      Doc Reg / No
                      <span className="ml-20 bold font-12">{details?.length > 0 ? details[0].chw_doc_number : ""}</span>
                    </p>
                  </td>
                </tr>{" "}
                <tr>
                  {" "}
                  <td width="50%">
                    <p className="pb-0">
                      Conact
                      <span className="ml-15 bold font-12">{details?.length > 0 ? details[0].chw_contact : ""}</span>
                    </p>
                  </td>
                  <td width="50%">
                    <p className="pb-0">
                      Alternative Contact
                      <span className="ml-20 bold font-12">{details?.length > 0 ? details[0].chw_alt_contact : ""}</span>
                    </p>
                  </td>
                </tr>{" "}
                <tr>
                  {" "}
                  <td width="25%">
                    <p className="pb-0">
                      Province
                      <span className="ml-15 bold font-12">{details?.length > 0 ? details[0].chw_address_province : ""}</span>
                    </p>
                  </td>
                  <td width="25%">
                    <p className="pb-0">
                      District
                      <span className="ml-20 bold font-12">{details?.length > 0 ? details[0].chw_address_district : ""}</span>
                    </p>
                  </td>{" "}
                </tr>
                <tr>
                  <td width="25%">
                    <p className="pb-0">
                      Municipality
                      <span className="ml-20 bold font-12">{details?.length > 0 ? details[0].chw_address_municipality : ""}</span>
                    </p>
                  </td>{" "}
                  <td width="25%">
                    <p className="pb-0">
                      Ward
                      <span className="ml-20 bold font-12">{details?.length > 0 ? details[0].chw_address_ward : ""}</span>
                    </p>
                  </td>
                </tr>
                <tr>
                  {" "}
                  <td width="50%">
                    <p className="pb-0">
                      Edu Undergraduage
                      <span className="ml-15 bold font-12">{details?.length > 0 ? details[0].chw_edu_undergraduate : "--"}</span>
                    </p>
                  </td>
                  <td width="50%">
                    <p className="pb-0">
                      Edu Postgraduate
                      <span className="ml-20 bold font-12">{details?.length > 0 ? details[0].chw_edu_postgraduate : "--"}</span>
                    </p>
                  </td>
                </tr>{" "}
                <tr>
                  <td>Emergency Contact</td>
                </tr>
                <tr>
                  {" "}
                  <td width="50%">
                    <p className="pb-0">
                      First Name
                      <span className="ml-15 bold font-12">{details?.length > 0 ? details[0].chw_emergency_contact_firstname : ""}</span>
                    </p>
                  </td>
                  <td width="50%">
                    <p className="pb-0">
                      Middle Name
                      <span className="ml-20 bold font-12">{details?.length > 0 ? details[0].chw_emergency_contact_middlename : ""}</span>
                    </p>
                  </td>{" "}
                </tr>
                <tr>
                  <td width="50%">
                    <p className="pb-0">
                      Last Name
                      <span className="ml-20 bold font-12">{details?.length > 0 ? details[0].chw_emergency_contact_lastname : ""}</span>
                    </p>
                  </td>{" "}
                  <td width="50%">
                    <p className="pb-0">
                      Relation
                      <span className="ml-20 bold font-12">{details?.length > 0 ? details[0].chw_emergency_contact_relation : ""}</span>
                    </p>
                  </td>
                </tr>
                <tr>
                  <td width="50%">
                    <p className="pb-0">
                      Contact
                      <span className="ml-20 bold font-12">{details?.length > 0 ? details[0].chw_emergency_contact_number : ""}</span>
                    </p>
                  </td>{" "}
                </tr>
                <tr>
                  <td width="50%">
                    <p className="pb-0">
                      Created By
                      <span className="ml-20 bold font-12">{details?.length > 0 ? details[0].chw_creator : ""}</span>
                    </p>
                  </td>{" "}
                </tr>
                <tr>
                  <td width="50%">
                    <p className="pb-0">
                      Verification Document <br />
                      <img src={details?.length > 0 ? details[0].chw_verification_file : ""} />
                    </p>
                  </td>
                </tr>
              </tbody>
            </table>

            {loadingLog && <div>Loading logs...</div>}

            {!loadingLog && log && (
              <div>
                <div className="bold">Last Sync</div>
                <div>
                  <div className="font-12">{log[0].time}</div>
                  <div>{log[0].date}</div>
                </div>
              </div>
            )}
          </div>
        </div>
      ) : (
        <div className="modal-body" style={{ padding: "18px 20px" }}>
          <div className="card-body font-12 row pb-0 pl-3 pr-0">{renderTableDatas()}</div>
          <button className="btn btn-primary  mt-3" onClick={handleDownload}>
            Download CSV
          </button>
        </div>
      )}
      {loadingChart && <div className="bold">Loading Chart</div>}
      {/* {!loadingChart && chart && ( */}
      <div>
        <div id="patientChart"></div>
      </div>
      {/* )} */})
    </div>
  );
}

export default StaffDetailModel;
