import React, { useEffect } from "react";
import Calendar from "tui-calendar"; /* ES6 */
import "tui-calendar/dist/tui-calendar.css";

// If you use the default popups, use this.
import "tui-date-picker/dist/tui-date-picker.css";
import "tui-time-picker/dist/tui-time-picker.css";
export default function Schedule() {
  useEffect(() => {
    var calendar = new Calendar("#calendar", {
      defaultView: "month",
      taskView: true,
      template: {
        monthDayname: function (dayname) {
          return (
            '<span class="calendar-week-dayname-name">' +
            dayname.label +
            "</span>"
          );
        },
      },
    });
  });
  return (
    <>
      <div className="app-main__inner">
        <div className="app-page-title pt-10 mt--40">
          <div className="page-title-wrapper">
            <div className="page-title-heading">
              <div className="card-body p-0">
                <div className="mr-2 btn-group">
                  <h5
                    className="page-title"
                    style={{ color: "#4A5056 !important" }}
                  >
                    Schedule
                  </h5>
                </div>
              </div>
            </div>
            <div className="page-title-actions pt-25">
              <div className="d-inline-block">
                <a className="mb-2 mr-2 btn btn-light btn-round">
                  <span className="btn-icon-wrapper opacity-7">
                    <i className="fas fa-bell mt-06"></i>
                  </span>
                </a>
              </div>
              <div className="d-inline-block">
                <a
                  href="./patient_add.php"
                  className="mb-2 mr-2 btn btn-schedule btn-primary color-white"
                >
                  <span className="btn-icon-wrapper pr-2 opacity-7">
                    <i className="fas fa-plus"></i>
                  </span>
                  Add New Patient
                </a>
              </div>
            </div>
          </div>
        </div>

        <div className="row pt-20">
          <div id="right">
            <div className="menu-top-schedule" id="menu d-block clearfix">
              <span className="float-left" id="menu-navi">
                <button
                  type="button"
                  className="btn btn-default btn-sm"
                  onClick="cal.today();"
                >
                  Today
                </button>
                <button
                  type="button"
                  className="btn btn-default btn-sm"
                  onClick="cal.prev();"
                >
                  <i className="calendar-icon ic-arrow-line-left"></i>
                </button>
                <span id="renderRange" className="render-range"></span>
                <button
                  type="button"
                  className="btn btn-default btn-sm"
                  onClick="cal.next();"
                >
                  <i className="calendar-icon ic-arrow-line-right"></i>
                </button>
              </span>
              <div className="float-right">
                <a type="button" className="btn btn-100 btn-default btn-sm">
                  <i className="fas fa-sync font-10 mr-2"></i>
                  Sync
                </a>
                <a type="button" className="btn btn-100 btn-default btn-sm">
                  <i className="fas fa-download font-10 mr-2"></i>
                  Download
                </a>
                <a type="button" className="btn btn-100 btn-default btn-sm">
                  <i className="fas fa-user font-10 mr-2"></i>
                  Request Approval
                </a>
              </div>
            </div>

            <div id="schedule"></div>
          </div>
        </div>
      </div>

      <div
        className="modal fade pr-0"
        id="schedule_detail"
        tabindex="-1"
        role="dialog"
      >
        <div
          className="modal-dialog"
          role="document"
          style={{ margin: "0px", float: "right", minWidth: "600px" }}
        >
          <div
            className="modal-content"
            style={{ borderRadius: "0px", minHeight: "100vh" }}
          >
            <div
              className="modal-header"
              style={{ borderBottom: "0px solid #e5e5e5 !important" }}
            >
              <h5 className="modal-title bold" id="exampleModalLongTitle">
                Appointment Detail
              </h5>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <hr
              style={{
                marginTop: "0px !important",
                marginBottom: "-2px !important",
              }}
            />
            <div className="row schedule-modal-title ml-0">
              <div className="col-md-7">
                <div className="mini-article-meta">
                  <div className="mini-article-byline">
                    <div className="author-avatar">
                      <a className="author-name" href="">
                        <img
                          className="img-round"
                          src="{% static 'images/avatars/1.jpg' %}"
                          style={{ width: "50px", height: "50px" }}
                        />
                      </a>
                    </div>
                    <div className="author-name-area">
                      <p className="author-name bold mb-0">
                        <span id="patient_name"></span>
                      </p>
                      <time className="font-12 text-mute">
                        <span id="contact_no"></span>
                      </time>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-5 text-right">
                <span id="arrived_button"></span>
                <span id="reschedule_button"></span>
              </div>
            </div>

            <div
              id="schedule_list"
              className="modal-body"
              style={{ padding: "10px" }}
            >
              <div style={{ padding: "15px 20px 10px 20px" }}>
                <table style={{ width: "100%" }}>
                  <tr className="text-mute">
                    <td width="40%">Case Name</td>
                    <td width="20%">Completed</td>
                    <td width="20%">To go</td>
                    <td width="20%">Missed</td>
                  </tr>
                  <tr style={{ fontSize: "20px", fontWeight: "700" }}>
                    <td>GDM Patient</td>
                    <td id="completed"></td>
                    <td id="to_go"></td>
                    <td id="missed"></td>
                  </tr>
                </table>
              </div>
              <div>
                <hr className="mlt-210" />
                <ul className="timeline" id="appointment_list"></ul>
              </div>
            </div>

            <div>
              <form
                method="post"
                action='{% url "home:reschedule_appointment" %}'
                id="reschedule_form"
              >
                <div className="modal-body" style={{ padding: "10px" }}>
                  <div style={{ padding: "15px 20px 10px 20px" }}>
                    <div id="form-timeline">
                      <label>Select Reschedule Date</label>

                      <br />
                      <label>Select Reschedule Start Time</label>
                    </div>

                    <div id="form-timeline"></div>
                  </div>
                </div>
                <div
                  className="modal-footer modal-bottom"
                  style={{ paddingBottom: "30px !important" }}
                >
                  <button
                    type="button"
                    className="btn btn-100 btn-default btn-sm mt-20"
                    data-dismiss="modal"
                  >
                    Close
                  </button>
                  <input
                    type="submit"
                    value="Reschedule Appointment"
                    className="btn btn-100 btn-success btn-sm mt-20"
                  />
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>

      <div
        className="modal fade pr-0"
        id="new_schedule"
        tabindex="-1"
        role="dialog"
      >
        <div
          className="modal-dialog"
          role="document"
          style={{ margin: "0", float: "right", minWidth: "600px" }}
        >
          <div
            className="modal-content"
            style={{ borderRadius: "0", minHeight: "100vh" }}
          >
            <div
              className="modal-header"
              style={{ borderBottom: "0px solid #e5e5e5 !important" }}
            >
              <h5 className="modal-title bold" id="exampleModalLongTitle">
                Add New Appointment
              </h5>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <hr
              style={{
                marginTop: "0px !important",
                marginBottom: "-2px !important",
              }}
            />
            <div className="row schedule-modal-title ml-0">
              <div className="col-md-7">
                <div className="mini-article-meta">
                  <div className="mini-article-byline">
                    <div className="author-avatar">
                      <a className="author-name" href="">
                        <img
                          className="img-round"
                          src="{% static 'images/avatars/1.jpg' %}"
                          style={{ width: "50px", height: "50px" }}
                        />
                      </a>
                    </div>
                    <div className="author-name-area">
                      <p className="author-name bold mb-0">Medicord Admin</p>
                      <time className="font-12 text-mute">+977 0123456789</time>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-5 text-right">
                <button
                  type="button"
                  className="btn btn-100 btn-default btn-sm mt-20"
                >
                  <i className="fas fa-edit font-10 mr-2"></i>
                  Add Note
                </button>
              </div>
            </div>

            <div className="modal-body" style={{ padding: "10px" }}>
              <div style={{ padding: "15px 20px 10px 20px" }}>
                <ul id="form-timeline" className="timeline">
                  <li>
                    <div>
                      <h6>FIRST YOU NEED TO ADD SERVICE</h6>
                      <select id="schedule_type" className="mb-2 form-control">
                        <option value="In-Person">In-Person</option>
                        <option value="Tele-medicine">Tele-medicine</option>
                      </select>
                    </div>
                  </li>
                  <li>
                    <div>
                      <h6>Select the patient</h6>
                      <select
                        id="schedule_patient"
                        className="mb-2 form-control"
                      >
                        <option value="Medicord Admin">Medicord Admin</option>
                      </select>
                    </div>
                  </li>
                  <li>
                    <div>
                      <h6 className="text-uppercase">Time and Date</h6>
                      <p>
                        Tuesday, 24 October <br />
                        14:00 15:00
                      </p>
                      <p className="text-mute font-12">
                        * patient approval is needed
                      </p>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-100 btn-default btn-sm mt-20"
                data-dismiss="modal"
              >
                Close
              </button>
              <button
                id="book_approve"
                type="button"
                className="btn btn-100 btn-success btn-sm mt-20"
              >
                Book Appointment
              </button>
            </div>
          </div>
          <div id="calendar" style={{ height: "800px" }}></div>
        </div>
      </div>
    </>
  );
}
