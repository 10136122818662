import { Link } from "react-router-dom";
import React from "react";
import getFirstCharacters from "../../../utils/getFirstCharacters";
export default function Model(Props) {
  return (
    <div
      className="modal fade pr-0"
      id="journal_view"
      tabIndex="-1"
      role="dialog"
      aria-labelledby="journal_view"
      aria-hidden="true"
    >
      <div
        className="modal-dialog"
        role="document"
        style={{ margin: "0", float: "right", minWidth: "35%" }}
      >
        {Props.type == "Chronic Disease" ? (
          <div
            className="modal-content"
            style={{ borderRadius: "0", minHeight: "100vh" }}
          >
            <div className="modal-header pb-0 bb-0">
              <div
                className="mini-article-meta"
                style={{ marginTop: "-20px", paddingBottom: "10px" }}
              >
                <div className="mini-article-byline">
                  <div
                    className="circle rounded-circle"
                    style={{ margin: "auto" }}
                  >
                    <span className="initials">
                      {getFirstCharacters(Props.visitDetails?.name)}
                    </span>
                  </div>
                  <div className="author-name-area">
                    <Link className="author-name" to="#">
                      {Props.visitDetails?.visit_category}
                    </Link>
                    <time dateTime="2018-12-21">
                      {Props.visitDetails?.visit_lastdate_english}
                    </time>
                  </div>
                </div>
              </div>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <hr
              style={{
                marginTop: "0px !important",
                marginBottom: "-2px !important",
              }}
            />

            <div className="modal-body p-20">
              <div>
                <h6 className="bold pt-10">Health Facility</h6>
                <table className="mb-0 table table-borderless">
                  <tbody>
                    <tr>
                      <td>
                        <p className="bold font-12">Facility Name</p>
                        <p className="font-12 mt--10">
                          {Props.visitDetails?.modelVisitChronic
                            ?.cHealthFacility?.health_facility_name || ""}
                        </p>
                      </td>
                      <td>
                        <p className="bold font-12">Visit Date Nepali</p>
                        <p className="font-12 mt--10">
                          {Props.visitDetails?.modelVisitChronic
                            ?.cHealthFacility
                            ?.health_facility_last_visit_date_ne || ""}
                        </p>
                      </td>
                      <td>
                        <p className="bold font-12">Visit Date English</p>
                        <p className=" font-12 mt--10">
                          {Props.visitDetails?.modelVisitChronic
                            ?.cHealthFacility
                            ?.health_facility_last_visit_date_eng || ""}
                        </p>
                      </td>
                    </tr>
                  </tbody>
                </table>
                <h6 className="bold pt-10">Diet</h6>
                <table className="mb-0 table table-borderless">
                  <tbody>
                    <tr>
                      <td>
                        <p className="bold font-12">Green Veg</p>
                        <p className="font-12 mt--10">
                          {Props.visitDetails?.modelVisitChronic?.cDiet
                            ?.green_veg || ""}
                        </p>
                      </td>
                      <td>
                        <p className="bold font-12">Less Sugar</p>
                        <p className="font-12 mt--10">
                          {Props.visitDetails?.modelVisitChronic?.cDiet
                            ?.less_sugar == "NO_BUT_WILLING"
                            ? "No but willing to do"
                            : Props.visitDetails?.modelVisitChronic?.cDiet
                                ?.less_sugar || ""}
                        </p>
                      </td>
                      <td>
                        <p className="bold font-12">Less Salt</p>
                        <p className="font-12 mt--10">
                          {Props.visitDetails?.modelVisitChronic?.cDiet
                            ?.less_salt || ""}
                        </p>
                      </td>
                      <td>
                        <p className="bold font-12">Reduced Oil</p>
                        <p className=" font-12 mt--10">
                          {Props.visitDetails?.modelVisitChronic?.cDiet
                            ?.reduced_oil || ""}
                        </p>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <p className="bold font-12">Healthy Food</p>
                        <p className="font-12 mt--10">
                          {Props.visitDetails?.modelVisitChronic?.cDiet
                            ?.healthy_food || ""}
                        </p>
                      </td>
                      <td>
                        <p className="bold font-12">
                          Eight to ten glass water?
                        </p>
                        <p className="font-12 mt--10">
                          {
                            Props.visitDetails?.modelVisitChronic?.cDiet
                              ?.eight_to_ten_glass_water
                          }
                        </p>
                      </td>
                    </tr>
                  </tbody>
                </table>
                <h6 className="bold pt-10">Disease</h6>
                <table className="mb-0 table table-borderless">
                  <tbody>
                    <tr>
                      <td>
                        {Props.visitDetails?.modelVisitChronic?.cDisease?.diseaseName?.map(
                          (i) => (
                            <p className="font-12 mt--10" key={i}>
                              {i}
                            </p>
                          )
                        )}
                      </td>
                    </tr>
                  </tbody>
                </table>
                <h6 className="bold pt-10">Habit</h6>
                <table className="mb-0 table table-borderless">
                  <tbody>
                    <tr>
                      <td>
                        <p className="bold font-12">Drink</p>
                        <p className="font-12 mt--10">
                          {Props.visitDetails?.modelVisitChronic?.cHabit
                            ?.drink || ""}
                        </p>
                      </td>
                      <td>
                        <p className="bold font-12">Drint Amount</p>
                        <p className=" font-12 mt--10">
                          {Props.visitDetails?.modelVisitChronic?.cHabit
                            ?.drink_amount || ""}
                        </p>
                      </td>

                      <td>
                        <p className="bold font-12">Exercise</p>
                        <p className=" font-12 mt--10">
                          {Props.visitDetails?.modelVisitChronic?.cHabit
                            ?.exercise || ""}
                        </p>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <p className="bold font-12">Smoke</p>
                        <p className=" font-12 mt--10">
                          {Props.visitDetails?.modelVisitChronic?.cHabit
                            ?.smoke || ""}
                        </p>
                      </td>
                      <td>
                        <p className="bold font-12">Smoke Amount</p>
                        <p className=" font-12 mt--10">
                          {Props.visitDetails?.modelVisitChronic?.cHabit
                            ?.smoke_amount || ""}
                        </p>
                      </td>
                    </tr>
                  </tbody>
                </table>
                <h6 className="bold pt-10">Lab</h6>
                <table className="mb-0 table table-borderless">
                  <tbody>
                    <tr>
                      <td>
                        <p className="bold font-12">dbp</p>
                        <p className="font-12 mt--10">
                          {Props.visitDetails?.modelVisitChronic?.cLab?.dbp ||
                            ""}
                        </p>
                      </td>
                      <td>
                        <p className="bold font-12">sbp</p>
                        <p className=" font-12 mt--10">
                          {Props.visitDetails?.modelVisitChronic?.cLab?.sbp ||
                            ""}
                        </p>
                      </td>
                      <td>
                        <p className="bold font-12">oxygen</p>
                        <p className=" font-12 mt--10">
                          {Props.visitDetails?.modelVisitChronic?.cLab
                            ?.oxygen || ""}
                        </p>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <p className="bold font-12">sugar</p>
                        <p className=" font-12 mt--10">
                          {Props.visitDetails?.modelVisitChronic?.cLab?.sugar ||
                            ""}
                        </p>
                      </td>
                    </tr>
                  </tbody>
                </table>
                <h6 className="bold pt-10">Risk</h6>
                <p className="bold font-12">
                  is Taking Medicine Regularly?
                  <br />
                  {Props.visitDetails?.modelVisitChronic?.cRisk
                    ?.isTakingMedicineRegularly || ""}
                </p>
                <h6 className="bold pt-10 font-12">Risk Name</h6>
                {Props.visitDetails?.modelVisitChronic?.cRisk?.riskName?.map(
                  (i) => (
                    <p className="font-12 mt--10" key={i}>
                      {i || ""}
                    </p>
                  )
                )}
              </div>
            </div>
          </div>
        ) : (
          <div
            className="modal-content"
            style={{ borderRadius: "0", minHeight: "100vh" }}
          >
            <div className="modal-header pb-0 bb-0">
              <div
                className="mini-article-meta"
                style={{ marginTop: "-20px", paddingBottom: "10px" }}
              >
                <div className="mini-article-byline">
                  <div
                    className="circle rounded-circle"
                    style={{ margin: "auto" }}
                  >
                    <span className="initials">
                      {getFirstCharacters(Props.visitDetails?.name)}
                    </span>
                  </div>
                  <div className="author-name-area">
                    <Link className="author-name" to="#">
                      {Props.visitDetails?.visit_category}
                    </Link>
                    <time className=" font-12">
                      {`Type: ${Props.visitDetails?.modelVisitSafe?.visitType}`}
                    </time>
                    <br />
                    <time className="text-right font-12">
                      {`Time: ${Props.visitDetails?.modelVisitSafe?.visitTime}`}
                    </time>
                  </div>
                </div>
              </div>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>

            <div className="modal-body">
              <div>
                <h6 className="bold pt-10">Baby Details</h6>
                <table className="mb-0 table table-borderless">
                  <tbody>
                    {Props.visitDetails?.modelVisitSafe?.babyDetailList?.map(
                      (item, index) => {
                        return (
                          <tr key={index}>
                            <td>{item.babyId}</td>
                            <td>
                              <p className="bold font-12">Gender</p>
                              <p className="font-12 mt--10">
                                {item.babyGender || ""}
                              </p>
                            </td>
                            <td>
                              <p className="bold font-12">Result</p>
                              <p className="font-12 mt--10">
                                {item.babyResult || ""}
                              </p>
                            </td>
                            <td>
                              <p className="bold font-12">Weight</p>
                              <p className=" font-12 mt--10">
                                {item.babyWeight || ""}
                              </p>
                            </td>
                            <td>
                              <p className="bold font-12">is Breast Feeded?</p>
                              <p className=" font-12 mt--10">
                                {item.isBreastFeeded || ""}
                              </p>
                            </td>
                          </tr>
                        );
                      }
                    )}
                  </tbody>
                </table>
                <h6 className="bold pt-10">Checkup</h6>
                <table className="mb-0 table table-borderless">
                  <tbody>
                    <tr>
                      <td>
                        <p className="bold font-12">Risk?</p>
                        <p className="font-12 mt--10">
                          {Props.visitDetails?.modelVisitSafe?.checkup
                            ?._checkup_risk || ""}
                        </p>
                      </td>
                      <td>
                        <p className="bold font-12">dbp</p>
                        <p className="font-12 mt--10">
                          {Props.visitDetails?.modelVisitSafe?.checkup
                            ?._checkup_dbp || ""}
                        </p>
                      </td>
                      <td>
                        <p className="bold font-12">sbp</p>
                        <p className="font-12 mt--10">
                          {Props.visitDetails?.modelVisitSafe?.checkup
                            ?._checkup_sbp || ""}
                        </p>
                      </td>
                    </tr>
                  </tbody>
                </table>
                <p className="bold font-12">Risk Sign</p>
                <ol>
                  {Props.visitDetails?.modelVisitSafe?.checkup?._checkup_risk_sign?.map(
                    (item, index) => {
                      return <li key={index}>{item}</li>;
                    }
                  )}
                </ol>
                <h6 className="bold pt-10">Conception Record</h6>
                <p className="bold font-12">Family Planning? </p>
                <p className="font-12 mt--10">
                  {
                    Props.visitDetails?.modelVisitSafe?.conceptionRecord
                      ?._conception_record_family_planning
                  }
                </p>
                <h6 className="bold font-12">PNC Usage</h6>
                <ol>
                  {Props.visitDetails?.modelVisitSafe?.conceptionRecord?._conception_record_pnc_usage?.map(
                    (item, index) => {
                      return <li key={index}>{item}</li>;
                    }
                  )}
                </ol>
                <h6 className="bold pt-10">Delivery Details</h6>
                <table className="mb-0 table table-borderless">
                  <tbody>
                    <tr>
                      <td>
                        <p className="bold font-12">Donor Name</p>
                        <p className="font-12 mt--10">
                          {Props.visitDetails?.modelVisitSafe?.deliveryDetail
                            ?._delivery_detail_donor_name || ""}
                        </p>
                      </td>
                      <td>
                        <p className="bold font-12">Donor Phone</p>
                        <p className="font-12 mt--10">
                          {Props.visitDetails?.modelVisitSafe?.deliveryDetail
                            ?._delivery_detail_donor_phone || ""}
                        </p>
                      </td>
                      <td>
                        <p className="bold font-12">Money</p>
                        <p className="font-12 mt--10">
                          {Props.visitDetails?.modelVisitSafe?.deliveryDetail
                            ?._delivery_detail_money || ""}
                        </p>
                      </td>
                      <td>
                        <p className="bold font-12">Support</p>
                        <p className="font-12 mt--10">
                          {Props.visitDetails?.modelVisitSafe?.deliveryDetail
                            ?._delivery_detail_support || ""}
                        </p>
                      </td>
                      <td>
                        <p className="bold font-12">Transport</p>
                        <p className="font-12 mt--10">
                          {Props.visitDetails?.modelVisitSafe?.deliveryDetail
                            ?._delivery_detail_transport || ""}
                        </p>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <p className="bold font-12">Prefer Location</p>
                        {Props.visitDetails?.modelVisitSafe?.deliveryDetail?._delivery_detail_prefer_location?.map(
                          (i) => (
                            <p key={i} className="font-12 mt--10">
                              {i}
                            </p>
                          )
                        )}
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <p className="bold font-12">Refer Location</p>
                        <p className="font-12 mt--10">
                          {Props.visitDetails?.modelVisitSafe?.deliveryDetail?._delivery_detail_refer_location?.map(
                            (i) => (
                              <>
                                <p className="font-12 mt--10">{i}</p>
                              </>
                            )
                          )}
                        </p>
                      </td>
                    </tr>
                  </tbody>
                </table>
                <h6 className="bold pt-10">Health Detail</h6>
                <table className="mb-0 table table-borderless">
                  <tbody>
                    <tr>
                      <td>
                        <p className="bold font-12">Previous Visit?</p>
                        <p className="font-12 mt--10">
                          {Props.visitDetails?.modelVisitSafe?.healthDetail
                            ?._health_detail_previous_visit || ""}
                        </p>
                      </td>
                      <td>
                        <p className="bold font-12">Visit Location</p>
                        <p className="font-12 mt--10">
                          {Props.visitDetails?.modelVisitSafe?.healthDetail
                            ?._health_detail_visit_location || ""}
                        </p>
                      </td>
                      <td>
                        <p className="bold font-12">Visit Date Nepali</p>
                        <p className="font-12 mt--10">
                          {Props.visitDetails?.modelVisitSafe?.healthDetail
                            ?._health_detail_previous_date_nepali || ""}
                        </p>
                      </td>
                      <td>
                        <p className="bold font-12">Visit Date English</p>
                        <p className="font-12 mt--10">
                          {Props.visitDetails?.modelVisitSafe?.healthDetail
                            ?._health_detail_previous_date_english || ""}
                        </p>
                      </td>
                    </tr>
                  </tbody>
                </table>
                <h6 className="bold pt-10">Lab</h6>
                <p className="bold font-12 ">
                  {`Blood Group : ${
                    Props.visitDetails?.modelVisitSafe?.lab?._lab_blood_group ||
                    ""
                  } `}
                  <br />
                  {`Haemoglobin : ${
                    Props.visitDetails?.modelVisitSafe?.lab?._lab_haemoglobin ||
                    ""
                  } `}
                  <br />
                  {`HIV : ${
                    Props.visitDetails?.modelVisitSafe?.lab?._lab_hiv || ""
                  } `}
                  <br />
                  {`Protein : ${
                    Props.visitDetails?.modelVisitSafe?.lab?._lab_protein || ""
                  } `}
                  <br />
                  {`Sugar : ${
                    Props.visitDetails?.modelVisitSafe?.lab?._lab_sugar || ""
                  } `}
                  <br />
                  {`Taken : ${
                    Props.visitDetails?.modelVisitSafe?.lab?._lab_taken || ""
                  } `}
                  <br />
                </p>
                <h6 className="bold pt-10 ">Menstrual Date</h6>
                <p className="bold font-12">
                  {`Period Start : ${Props.visitDetails?.modelVisitSafe?.menstrualDate._menstrual_date_period_nepali} `}
                  {`(${Props.visitDetails?.modelVisitSafe?.menstrualDate._menstrual_date_period_english}) `}
                  <br />
                  {`Period End : ${Props.visitDetails?.modelVisitSafe?.menstrualDate._menstrual_date_edd_nepali} `}
                  {`(${Props.visitDetails?.modelVisitSafe?.menstrualDate._menstrual_date_edd_english}) `}
                </p>
                <h6 className="bold pt-10">Pregnancy History</h6>
                <p className="bold  font-12">
                  {`Type : ${Props.visitDetails?.modelVisitSafe?.pregnancyHistory._pregnancy_history_type} `}
                </p>
                <h6 className="bold pt-10 ">Pregnancy Termination</h6>
                <p className="bold font-12">
                  {`Type : ${Props.visitDetails?.modelVisitSafe?.pregnancyTermination} `}
                </p>
                <h6 className="bold pt-10">Termination</h6>
                <table className="mb-0 table table-borderless">
                  <tbody>
                    <tr>
                      <td>
                        <p className="bold font-12">Complication</p>
                        <p className="font-12 mt--10">
                          {Props.visitDetails?.modelVisitSafe?.termination
                            ?._termination_complication || ""}
                        </p>
                      </td>
                      <td>
                        <p className="bold font-12"> Date Nepali</p>
                        <p className="font-12 mt--10">
                          {Props.visitDetails?.modelVisitSafe?.termination
                            ?._termination_date_nepali || ""}
                        </p>
                      </td>
                      <td>
                        <p className="bold font-12"> Date English</p>
                        <p className="font-12 mt--10">
                          {Props.visitDetails?.modelVisitSafe?.termination
                            ?._termination_date_english || ""}
                        </p>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <p className="bold font-12">Location</p>
                        <p className="font-12 mt--10">
                          {Props.visitDetails?.modelVisitSafe?.termination
                            ?._termination_location || ""}
                        </p>
                      </td>
                      <td>
                        <p className="bold font-12">Type</p>
                        <p className="font-12 mt--10">
                          {Props.visitDetails?.modelVisitSafe?.termination
                            ?._termination_type || ""}
                        </p>
                      </td>
                    </tr>
                  </tbody>
                </table>
                <h6 className="bold pt-10">Preventive Care</h6>
                <table className="mb-0 table table-borderless">
                  <tbody>
                    <tr>
                      <td>
                        <p className="bold font-12">Albendazole</p>
                        <p className="font-12 mt--10">
                          {Props.visitDetails?.modelVisitSafe?.preventiveCare
                            ?._preventive_care_albendazole || ""}
                        </p>
                      </td>
                      <td>
                        <p className="bold font-12">Iron</p>
                        <p className="font-12 mt--10">
                          {Props.visitDetails?.modelVisitSafe?.preventiveCare
                            ?._preventive_care_iron || ""}
                        </p>
                      </td>
                      <td>
                        <p className="bold font-12">Vaccine</p>
                        <p className="font-12 mt--10">
                          {Props.visitDetails?.modelVisitSafe?.preventiveCare
                            ?._preventive_care_vaccine || ""}
                        </p>
                      </td>
                    </tr>
                  </tbody>
                </table>
                <h6 className="bold pt-10">Usg</h6>
                <table className="mb-0 table table-borderless">
                  <tbody>
                    <tr>
                      <td>
                        <p className="bold font-12">Cephalic</p>
                        <p className="font-12 mt--10">
                          {Props.visitDetails?.modelVisitSafe?.usg
                            ?._usg_cephalic || ""}
                        </p>
                      </td>
                      <td>
                        <p className="bold font-12">Fluid</p>
                        <p className="font-12 mt--10">
                          {Props.visitDetails?.modelVisitSafe?.usg
                            ?._usg_fluid || ""}
                        </p>
                      </td>
                      <td>
                        <p className="bold font-12">Perform</p>
                        <p className="font-12 mt--10">
                          {Props.visitDetails?.modelVisitSafe?.usg
                            ?._usg_perform || ""}
                        </p>
                      </td>
                      <td>
                        <p className="bold font-12">Singleton</p>
                        <p className="font-12 mt--10">
                          {Props.visitDetails?.modelVisitSafe?.usg
                            ?._usg_singleton || ""}
                        </p>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}
