import {
  LOGOUT_USER,
  DASH_INFO_FETCH,
  DASH_INFO_FETCHED,
} from "../constants/types";
import http from "../../utils/http";
import toast from "../../utils/toast";

export const fetchDashInfo = () => async (dispatch) => {
  dispatch({ type: DASH_INFO_FETCH });
  try {
    const censusChart = await http.GET("Census/chart");
    const presult = await http.GET("Patient/total");
    const iresult = await http.GET("Infant/total");
    const chwresult = await http.GET("CHW/total");
    const censusresult = await http.GET("Census/get");

    const result = {
      presult,
      iresult,
      chwresult,
      censusresult,
      censusChart,
    };
    dispatch({
      type: DASH_INFO_FETCHED,
      payload: result,
    });
  } catch (err) {
    console.log(err);
  }
  //   http
  //     .GET("Dash/info", { perPage, currentPage })
  //     .then((res) => {
  //       console.log(res.data);
  //     })
  //     .catch((error) => {
  //       console.log(error.response.data?.code);
  //       if (error.response.data.code === "401") dispatch({ type: LOGOUT_USER });
  //       toast.error(error);
  //     });
};
