import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { checkSyncHistory, fetchChartByCHW, fetchCHW, removeStaff } from "./../../redux/actions/staff.action";
import ReactPaginate from "react-paginate";
import { Link } from "react-router-dom";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import getFirstCharacters from "../../utils/getFirstCharacters";
import StaffDetailModel from "./Components/StaffDetailModel";

export default function Staff() {
  const { staffs, loading, total, log, loadingLog, chart, loadingChart } = useSelector((state) => state.staff);

  const [perPage, setPerPage] = useState(8);
  const [currentPage, setCurrentPage] = useState(0);
  const [selectedId, setSelectedId] = useState("");
  const [delId, setDelId] = useState(0);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(
      fetchCHW({
        perPage: perPage,
        currentPage: currentPage,
      })
    );
  }, [dispatch]);

  const handlePageClick = (event) => {
    setCurrentPage(event.selected);

    dispatch(
      fetchCHW({
        perPage: perPage,
        currentPage: event.selected,
      })
    );
  };
  const pageCount = Math.ceil(total ? total / perPage : 0);

  const details = staffs?.filter((item) => item.chw_id === selectedId);

  function onStaffDelete() {
    dispatch(removeStaff(delId));
  }
  console.log(details);
  return (
    <div className="app-main__inner">
      <div className="app-page-title pt-10">
        <div className="page-title-wrapper">
          <div className="page-title-heading">
            <div className="card-body p-0">
              <div className="mr-2 btn-group">
                <h5 className="page-title"> Staff List </h5>
              </div>
            </div>
          </div>
          <div className="page-title-actions">
            <div className="d-inline-block">
              <Link to="create" className="mb-2 mr-2 btn btn-primary color-white">
                <span className="btn-icon-wrapper pr-2 opacity-7">
                  <i className="fas fa-plus"></i>
                </span>
                Add New Staff
              </Link>
            </div>
          </div>
        </div>
      </div>
      <div className="row">
        {loading
          ? new Array(8).fill("", 0, 8).map((i, index) => (
              <div className="col-md-3 pr-05" key={index}>
                <div className="main-card mb-3 card min-h-300" style={{ maxWidth: "300px !important" }}>
                  <div className="card-body text-center pb-16">
                    <div style={{ margin: "auto" }}>
                      <Skeleton height={60} width={60} borderRadius={50} />
                    </div>
                    <div className="card-body pb-0 pl-0 pr-0">
                      <h5 className="card-title mb-0">
                        <Skeleton width={250} />
                      </h5>
                      <p className="text-mute mt-10 mb-0">
                        <Skeleton width={250} />
                      </p>
                      <p className="text-mute">
                        <Skeleton width={150} />
                      </p>
                      <Skeleton height={3} />
                      <table className="mb-0 table table-borderless" style={{ margin: "10px" }}>
                        <tbody className="text-left font-12">
                          <tr>
                            <td width="50%">
                              <Skeleton height={15} />
                            </td>
                            <td width="50%">
                              <Skeleton height={15} />
                            </td>
                          </tr>
                          <tr>
                            <td width="50%">
                              <Skeleton height={15} />
                            </td>
                            <td width="50%">
                              <Skeleton height={15} />
                            </td>
                          </tr>
                        </tbody>
                      </table>{" "}
                      <Skeleton height={30} />
                    </div>
                  </div>
                </div>
              </div>
            ))
          : staffs?.map((staff, index) => {
              return (
                <div className="col-md-3 pr-05" key={staff.chw_id}>
                  <div className="main-card mb-3 card min-h-300" style={{ maxWidth: "300px !important" }}>
                    <div className="card-body text-center pb-16">
                      <div
                        style={{
                          textAlign: "right",
                          marginRight: "-20px",
                          marginTop: "-10px",
                          marginBottom: "-20px",
                        }}
                      >
                        <Link to="" onClick={() => setSelectedId(staff.chw_id)} className="mb-2 mr-2 btn" data-toggle="modal" data-target="#staff_11" style={{ cursor: "pointer" }}>
                          <i className="fas fa-ellipsis-h"></i>
                        </Link>
                      </div>
                      <div className="circle rounded-circle" style={{ margin: "auto" }}>
                        <span className="initials">{getFirstCharacters(staff.chw_name)}</span>
                      </div>
                      <div className="card-body pb-0 pl-0 pr-0">
                        <h5 className="card-title mb-0">{staff.chw_name}</h5>
                        <p className="text-mute mt-10 mb-0">+977 {staff.phone}</p>
                        <p className="text-mute">{staff.chw_address}</p>
                        <hr />
                        <table className="mb-0 table table-borderless" style={{ margin: "10px" }}>
                          <tbody className="text-left font-12">
                            <tr>
                              <td width="50%">
                                <p className="pb-0">
                                  Education <span className="ml-10 bold font-12">---</span>
                                </p>
                              </td>
                              <td width="50%">
                                <p className="pb-0">
                                  Gender <span className="ml-15 bold font-12">{staff.chw_gender}</span>
                                </p>
                              </td>
                            </tr>
                            <tr>
                              <td width="50%">
                                <p className="pb-0">
                                  DoB
                                  <span className="ml-15 bold font-12">{staff.chw_dob}</span>
                                </p>
                              </td>
                              <td width="50%">
                                <p className="pb-0">
                                  Group
                                  <span className="ml-20 bold font-12">{staff.chw_designation}</span>
                                </p>
                              </td>
                            </tr>
                          </tbody>
                        </table>{" "}
                        <Link to={`patient_list/${staff.chw_id}`} className="btn-transition btn btn-outline-light btn-lg btn-more btn-block" style={{ marginTop: "20px" }}>
                          Patient List
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              );
            })}
      </div>
      <div className="row col-sm-6 text-right">
        <ReactPaginate
          previousLabel={"← Previous"}
          nextLabel={"Next →"}
          breakLabel="..."
          pageCount={pageCount}
          onPageChange={handlePageClick}
          containerClassName={"pagination"}
          previousLinkClassName={"pagination__link"}
          nextLinkClassName={"pagination__link"}
          disabledClasName={"disabled"}
          activeClassName={"active"}
          // renderOnZeroPageCount={"<h43>Loading</h43>"}
        />
      </div>

      <div className="modal fade pr-0" id="staff_11" tabIndex="-1" role="dialog" aria-labelledby="staff_contentView" aria-hidden="true">
        <div className="modal-dialog" role="document" style={{ margin: "0", float: "right", minWidth: "600px" }}>
          <div className="modal-content" style={{ borderRadius: 0, minHeight: "100vh" }}>
            <div className="modal-header" style={{ borderBottom: "0px solid #e5e5e5 !important" }}>
              <h5 className="modal-title bold" id="exampleModalLongTitle">
                CHW Profile
              </h5>
              <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">×</span>
              </button>
            </div>
            <hr
              style={{
                marginTop: "0px !important",
                marginBottom: "-2px !important",
              }}
            />
            <div className="row schedule-modal-title ml-0 pb-10">
              <div className="col-md-7">
                <div className="mini-article-meta">
                  <div className="mini-article-byline">
                    <div className="author-avatar">
                      <div className="circle" style={{ width: "50px", height: "50px" }}>
                        <span className="initials" style={{ fontSize: "22px" }}>
                          {details?.length > 0 ? getFirstCharacters(details[0].chw_name) : ""}
                        </span>
                      </div>
                    </div>
                    <div className="author-name-area">
                      <h5 className="card-title mb-0">{details?.length > 0 ? details[0].chw_name : ""}</h5>
                      <p className="text-mute mt-10 mb-0">{details?.length > 0 ? details[0].chw_address : ""}</p>
                      <p className="text-mute">{details?.length > 0 ? details[0].phone : ""}</p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-5 text-right mt-15">
                <Link className="btn btn-100 btn-primary btn-sm mt-20" to={`update/${details?.length > 0 ? details[0].chw_id : ""}`}>
                  <i className="fas fa-edit font-10 mr-2"></i>
                  Edit
                </Link>
                <button type="button" className="btn btn-100 btn-danger btn-sm mt-20 ml-1" data-toggle="modal" data-target="#deleteModal_11" onClick={() => setDelId(details?.length > 0 ? details[0].chw_id : 0)}>
                  <i className="fas fa-trash font-10 mr-2"></i>
                  Delete
                </button>
              </div>
            </div>

            {!!selectedId && <StaffDetailModel selectedId={selectedId} />}
          </div>
        </div>
      </div>

      <div className="modal fade" id="deleteModal_11" tabIndex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div className="modal-dialog" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLabel">
                Delete Staff Member
              </h5>
              <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">×</span>
              </button>
            </div>

            <div className="modal-body">
              <p className="mb-0">Are you sure you want to delete this staff member?</p>
            </div>
            <div className="modal-footer">
              <button type="button" className="btn btn-secondary" data-dismiss="modal" aria-label="Close">
                Cancel
              </button>
              <input type="submit" id="submit" data-dismiss="modal" aria-label="Close" onClick={() => onStaffDelete()} className="btn btn-primary" value="Confirm" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
