import React from "react";

export default function Footer() {
  return (
    <div className="app-wrapper-footer">
      <div className="app-footer">
        <div className="app-footer__inner">
          <div className="app-footer-right">
            <ul className="nav">
              <li className="nav-item font-small">
                &#169; Copyright 2020 Medicord | Product of Ayata Inc. All
                rights reserved.
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
}
