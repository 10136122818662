import {
  PATIENT_LOCATION_FETCH,
  PATIENT_LOCATION_FETCHED,
  CENSUS_LOCATION_FETCH,
  CENSUS_LOCATION_FETCHED,
  LOGOUT_USER,
  CENSUS_CHART_FETCHED,
  CENSUS_CHART_FETCH,
} from "../constants/types";
import http from "../../utils/http";
import toast from "../../utils/toast";

export const fetchPatientsLocation = () => (dispatch) => {
  dispatch({ type: PATIENT_LOCATION_FETCH });
  http
    .GET("Patient/location")
    .then((res) => {
      console.log(res.data);
      dispatch({ type: PATIENT_LOCATION_FETCHED, payload: res.data });
    })
    .catch((error) => {
      if (error.response.data.code === "401") dispatch({ type: LOGOUT_USER });
      toast.error(error);
    });
};

export const fetchCensusLocation = () => (dispatch) => {
  dispatch({ type: CENSUS_LOCATION_FETCH });
  http
    .GET("Census/location")
    .then((res) => {
      console.log(res.data);
      dispatch({ type: CENSUS_LOCATION_FETCHED, payload: res.data });
    })
    .catch((error) => {
      if (error.response.data.code === "401") dispatch({ type: LOGOUT_USER });
      toast.error(error);
    });
};

export const fetchCensusChart = () => (dispatch) => {
  dispatch({ type: CENSUS_CHART_FETCH });
  http
    .GET("Patient/chart")
    .then((res) => {
      console.log(res.data);
      dispatch({ type: CENSUS_CHART_FETCHED, payload: res.data });
    })
    .catch((error) => {
      if (error.response.data.code === "401") dispatch({ type: LOGOUT_USER });
      toast.error(error);
    });
};
