import axios from "axios";

// const BaseURL = process.env.REACT_APP_BASE_URL
// const BaseURL = "http://localhost:4012/api/v2/web/";
// const BaseURL = "https://api.asha.ayata.com.np/api/v2/web/";
// const BaseURL = "http://34.100.155.148:4012/api/v2/web/";
const BaseURL = "https://api.asha.dipanbhusal.com.np/api/v2/web/";

const http = axios.create({
  baseURL: BaseURL,
  responseType: "json",
});

const getHeaders = () => {
  let headerOptions = {
    "Content-Type": "application/json",
    Authorization: `Bearer ${localStorage.getItem("token")}`,
  };

  return headerOptions;
};

function GET(url, params) {
  return http.get(url, {
    headers: getHeaders(),
    params: params,
  });
}

function POST(url, data) {
  return http.post(url, data, {
    headers: getHeaders(),
    params: {},
  });
}

function PUT(url, data) {
  return http.put(url, data, { headers: getHeaders(), params: {} });
}
function REMOVE(url) {
  return http.delete(url, { headers: getHeaders() });
}

export default {
  GET,
  POST,
  PUT,
  REMOVE,
};
