import {
  CENSUS_DELETED,
  CENSUS_FETCH,
  CENSUS_FETCHED,
  CENSUS_SINGLE_FETCH,
  CENSUS_SINGLE_FETCHED,
  CENSUS_UPDATE,
  CENSUS_UPDATE_FAILURE,
  CENSUS_UPDATE_SUCCESS,
} from "../constants/types";

const initialValue = {
  census: [],
  total: 0,
  loading: false,
  singleCensus: {},
  updating: false,
};

function reducer(state = initialValue, action) {
  switch (action.type) {
    case CENSUS_FETCH:
      return {
        loading: true,
      };
    case CENSUS_FETCHED:
      return {
        ...state,
        loading: false,
        census: action.payload.censusList,
        total: action.payload.total,
      };
    case CENSUS_SINGLE_FETCH:
      return {
        loading: true,
      };
    case CENSUS_SINGLE_FETCHED:
      return {
        ...state,
        loading: false,
        singleCensus: action.payload.data,
      };
    case CENSUS_DELETED:
      const deleted = state.census.filter(
        (item) => item.residentId !== action.payload
      );
      const total = state.total - 1;
      return {
        ...state,
        census: deleted,
        total,
      };

    case CENSUS_UPDATE:
      return {
        ...state,
        updating: true,
      };

    case CENSUS_UPDATE_SUCCESS:
      return {
        ...state,
        updating: false,
      };
    case CENSUS_UPDATE_FAILURE:
      return {
        ...state,
        updating: false,
      };
    default:
      return {
        ...state,
      };
  }
}

export default reducer;
