import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchDoctorInfo,
  updateDoctorInfo,
} from "../../redux/actions/doctor.action";
import { useForm } from "react-hook-form";

import ChangePassword from "./Components/ChangePassword";

export default function Setting() {
  const dispatch = useDispatch();
  const { doctor, updating } = useSelector((state) => state.doctor);

  const { register, handleSubmit, reset } = useForm();

  useEffect(() => {
    dispatch(fetchDoctorInfo());
  }, [dispatch]);

  useEffect(() => {
    reset(doctor);
  }, [doctor]);

  const onFormSubmit = async (data) => {
    dispatch(updateDoctorInfo(data));
  };

  return (
    <>
      <div className="app-main__inner">
        <div className="app-page-title pt-10">
          <div className="page-title-wrapper">
            <div className="page-title-heading">
              <div className="card-body p-0">
                <div className="mr-2 btn-group">
                  <h5 className="page-title">Settings</h5>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        className="app"
        style={{ height: "89vh !important", margin: "0px 0px" }}
      >
        <div className="wrapper">
          <div className="conversation-area">
            <div className="p-20 pb-10">
              <h5
                className="page-title"
                style={{
                  fontSize: "18px",
                  fontWeight: "700",
                  paddingBottom: "10px",
                  paddingLeft: "10px",
                }}
              >
                Update your settings
              </h5>
            </div>
            <nav className="nav flex-column nav-pills nav-color nav-gap-y-1">
              <a
                href="#profile"
                data-toggle="tab"
                className="nav-item nav-link has-icon nav-link-faded msg active"
              >
                <img
                  className="setting-profile"
                  src="./assets/img/settings/1.png"
                  alt=""
                />
                <div className="msg-detail">
                  <div className="msg-username">Profile Information</div>
                  <div className="font-12 mt--5">
                    <p className="mb-0">Update your profile</p>
                  </div>
                </div>
              </a>
              <a
                href="#address"
                data-toggle="tab"
                className="nav-item nav-link has-icon nav-link-faded msg"
              >
                <img
                  className="setting-profile"
                  src="./assets/img/settings/2.png"
                  alt=""
                />
                <div className="msg-detail">
                  <div className="msg-username">Address Settings</div>
                  <div className="font-12 mt--5">
                    <p className="mb-0">Change your address settings</p>
                  </div>
                </div>
              </a>
              <a
                href="#security"
                data-toggle="tab"
                className="nav-item nav-link has-icon nav-link-faded msg"
              >
                <img
                  className="setting-profile"
                  src="./assets/img/settings/3.png"
                  alt=""
                />
                <div className="msg-detail">
                  <div className="msg-username">Security</div>
                  <div className="font-12 mt--5">
                    <p className="mb-0">Update your security preference</p>
                  </div>
                </div>
              </a>

              {/* <a
                href="#notification"
                data-toggle="tab"
                className="nav-item nav-link has-icon nav-link-faded msg"
              >
                <img
                  className="setting-profile"
                  src="./assets/img/settings/4.png"
                  alt=""
                />
                <div className="msg-detail">
                  <div className="msg-username">Notification</div>
                  <div className="font-12 mt--5">
                    <p className="mb-0">Update your notification</p>
                  </div>
                </div>
              </a> */}
              {/* 
              <a
                href="#billing"
                data-toggle="tab"
                className="nav-item nav-link has-icon nav-link-faded msg"
              >
                <img
                  className="setting-profile"
                  src="./assets/img/settings/5.png"
                  alt=""
                />
                <div className="msg-detail">
                  <div className="msg-username">Billing</div>
                  <div className="font-12 mt--5">
                    <p className="mb-0">Change your billing</p>
                  </div>
                </div>
              </a> */}
            </nav>
            <div className="overlay"></div>
          </div>

          <div className="chat-area" style={{ background: "white" }}>
            <div className="chat-area-header">
              <div className="row w-100">
                <div className="col-12">
                  <div className="card-header border-bottom mb-3 d-flex d-md-none">
                    <ul
                      className="nav nav-tabs card-header-tabs nav-gap-x-1"
                      role="tablist"
                    >
                      <li className="nav-item">
                        <a
                          href="#profile"
                          data-toggle="tab"
                          className="nav-link has-icon active"
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            fill="none"
                            stroke="currentColor"
                            strokeWidth="2"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            className="feather feather-user"
                          >
                            <path d="M20 21v-2a4 4 0 0 0-4-4H8a4 4 0 0 0-4 4v2"></path>
                            <circle cx="12" cy="7" r="4"></circle>
                          </svg>
                        </a>
                      </li>
                      <li className="nav-item">
                        <a
                          href="#address"
                          data-toggle="tab"
                          className="nav-link has-icon"
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            fill="none"
                            stroke="currentColor"
                            strokeWidth="2"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            className="feather feather-settings"
                          >
                            <circle cx="12" cy="12" r="3"></circle>
                            <path d="M19.4 15a1.65 1.65 0 0 0 .33 1.82l.06.06a2 2 0 0 1 0 2.83 2 2 0 0 1-2.83 0l-.06-.06a1.65 1.65 0 0 0-1.82-.33 1.65 1.65 0 0 0-1 1.51V21a2 2 0 0 1-2 2 2 2 0 0 1-2-2v-.09A1.65 1.65 0 0 0 9 19.4a1.65 1.65 0 0 0-1.82.33l-.06.06a2 2 0 0 1-2.83 0 2 2 0 0 1 0-2.83l.06-.06a1.65 1.65 0 0 0 .33-1.82 1.65 1.65 0 0 0-1.51-1H3a2 2 0 0 1-2-2 2 2 0 0 1 2-2h.09A1.65 1.65 0 0 0 4.6 9a1.65 1.65 0 0 0-.33-1.82l-.06-.06a2 2 0 0 1 0-2.83 2 2 0 0 1 2.83 0l.06.06a1.65 1.65 0 0 0 1.82.33H9a1.65 1.65 0 0 0 1-1.51V3a2 2 0 0 1 2-2 2 2 0 0 1 2 2v.09a1.65 1.65 0 0 0 1 1.51 1.65 1.65 0 0 0 1.82-.33l.06-.06a2 2 0 0 1 2.83 0 2 2 0 0 1 0 2.83l-.06.06a1.65 1.65 0 0 0-.33 1.82V9a1.65 1.65 0 0 0 1.51 1H21a2 2 0 0 1 2 2 2 2 0 0 1-2 2h-.09a1.65 1.65 0 0 0-1.51 1z"></path>
                          </svg>
                        </a>
                      </li>
                      <li className="nav-item">
                        <a
                          href="#security"
                          data-toggle="tab"
                          className="nav-link has-icon"
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            fill="none"
                            stroke="currentColor"
                            strokeWidth="2"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            className="feather feather-shield"
                          >
                            <path d="M12 22s8-4 8-10V5l-8-3-8 3v7c0 6 8 10 8 10z"></path>
                          </svg>
                        </a>
                      </li>
                      <li className="nav-item">
                        <a
                          href="#notification"
                          data-toggle="tab"
                          className="nav-link has-icon"
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            fill="none"
                            stroke="currentColor"
                            strokeWidth="2"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            className="feather feather-bell"
                          >
                            <path d="M18 8A6 6 0 0 0 6 8c0 7-3 9-3 9h18s-3-2-3-9"></path>
                            <path d="M13.73 21a2 2 0 0 1-3.46 0"></path>
                          </svg>
                        </a>
                      </li>
                      <li className="nav-item">
                        <a
                          href="#billing"
                          data-toggle="tab"
                          className="nav-link has-icon"
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            fill="none"
                            stroke="currentColor"
                            strokeWidth="2"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            className="feather feather-credit-card"
                          >
                            <rect
                              x="1"
                              y="4"
                              width="22"
                              height="16"
                              rx="2"
                              ry="2"
                            ></rect>
                            <line x1="1" y1="10" x2="23" y2="10"></line>
                          </svg>
                        </a>
                      </li>
                    </ul>
                  </div>
                  <div className="card-body tab-content">
                    <div className="tab-pane active" id="profile">
                      <h6>YOUR PROFILE INFORMATION</h6>
                      <hr />
                      <form onSubmit={handleSubmit(onFormSubmit)}>
                        <div className="form-group">
                          <label htmlFor="fullName">Full Name</label>
                          <input
                            type="text"
                            className="form-control"
                            id="fullName"
                            aria-describedby="fullNameHelp"
                            placeholder="Enter your fullname"
                            {...register("name")}
                          />
                          <small
                            id="fullNameHelp"
                            className="form-text text-muted"
                          >
                            Your name may appear around here where you are
                            mentioned. You can change or remove it at any time.
                          </small>
                        </div>
                        <div className="form-group">
                          <label htmlFor="bio">Your Bio</label>
                          <textarea
                            className="form-control autosize"
                            id="bio"
                            placeholder="Write something about you"
                            {...register("bio")}
                            style={{
                              overflow: "hidden",
                              overflowWrap: "break-word",
                              resize: "none",
                              height: "62px",
                            }}
                          ></textarea>
                        </div>
                        <div className="form-group small text-muted">
                          All of the fields on this page are optional and can be
                          deleted at any time, and by filling them out, you're
                          giving us consent to share this data wherever your
                          user profile appears.
                        </div>
                        {updating ? (
                          <button
                            type="submit"
                            className="btn btn-primary"
                            disabled
                          >
                            Updating Profile
                          </button>
                        ) : (
                          <button type="submit" className="btn btn-primary">
                            Update Profile
                          </button>
                        )}

                        <button type="reset" className="btn btn-light">
                          Reset Changes
                        </button>
                      </form>
                    </div>
                    <div className="tab-pane" id="address">
                      <h6>ADDRESS SETTINGS</h6>
                      <hr />
                      <form onSubmit={handleSubmit(onFormSubmit)}>
                        <div className="form-group">
                          <label htmlFor="address">Address</label>
                          <input
                            type="text"
                            className="form-control"
                            aria-describedby="addressHelp"
                            placeholder="Enter your full address"
                            {...register("address")}
                          />
                        </div>
                        {updating ? (
                          <button
                            type="submit"
                            className="btn btn-primary"
                            disabled
                          >
                            Updating Address
                          </button>
                        ) : (
                          <button type="submit" className="btn btn-primary">
                            Update Address
                          </button>
                        )}
                      </form>
                    </div>
                    <div className="tab-pane" id="security">
                      <h6>SECURITY SETTINGS</h6>
                      <hr />
                      <ChangePassword />
                      <hr />
                      <form>
                        <div className="form-group">
                          <label className="d-block">
                            Two Factor Authentication
                          </label>
                          <button className="btn btn-info" type="button">
                            Enable two-factor authentication
                          </button>
                          <p className="small text-muted mt-2">
                            Two-factor authentication adds an additional layer
                            of security to your account by requiring more than
                            just a password to log in.
                          </p>
                        </div>
                      </form>
                      <hr />
                      <form>
                        <div className="form-group mb-0">
                          <label className="d-block">Sessions</label>
                          <p className="font-size-sm text-secondary">
                            This is a list of devices that have logged into your
                            account. Revoke any sessions that you do not
                            recognize.
                          </p>
                          <ul className="list-group list-group-sm">
                            <li className="list-group-item has-icon">
                              <div>
                                <h6 className="mb-0">
                                  San Francisco City 190.24.335.55
                                </h6>
                                <small className="text-muted">
                                  Your current session seen in United States
                                </small>
                              </div>
                              <button
                                className="btn btn-light btn-sm ml-auto"
                                type="button"
                              >
                                More info
                              </button>
                            </li>
                          </ul>
                        </div>
                      </form>
                    </div>
                    {/* <div className="tab-pane" id="notification">
                      <h6>NOTIFICATION SETTINGS</h6>
                      <hr />
                      <form>
                        <div className="form-group">
                          <label className="d-block mb-0">
                            Security Alerts
                          </label>
                          <div className="small text-muted mb-3">
                            Receive security alert notifications via email
                          </div>
                          <div className="custom-control custom-checkbox">
                            <input
                              type="checkbox"
                              className="custom-control-input"
                              id="customCheck1"
                              checked=""
                            />
                            <label
                              className="custom-control-label"
                              for="customCheck1"
                            >
                              Email each time a vulnerability is found
                            </label>
                          </div>
                          <div className="custom-control custom-checkbox">
                            <input
                              type="checkbox"
                              className="custom-control-input"
                              id="customCheck2"
                              checked=""
                            />
                            <label
                              className="custom-control-label"
                              for="customCheck2"
                            >
                              Email a digest summary of vulnerability
                            </label>
                          </div>
                        </div>
                        <div className="form-group mb-0">
                          <label className="d-block">SMS Notifications</label>
                          <ul className="list-group list-group-sm">
                            <li className="list-group-item has-icon">
                              Comments
                              <div className="custom-control custom-control-nolabel custom-switch ml-auto">
                                <input
                                  type="checkbox"
                                  className="custom-control-input"
                                  id="customSwitch1"
                                  checked=""
                                />
                                <label
                                  className="custom-control-label"
                                  for="customSwitch1"
                                ></label>
                              </div>
                            </li>
                            <li className="list-group-item has-icon">
                              Updates From People
                              <div className="custom-control custom-control-nolabel custom-switch ml-auto">
                                <input
                                  type="checkbox"
                                  className="custom-control-input"
                                  id="customSwitch2"
                                />
                                <label
                                  className="custom-control-label"
                                  for="customSwitch2"
                                ></label>
                              </div>
                            </li>
                            <li className="list-group-item has-icon">
                              Reminders
                              <div className="custom-control custom-control-nolabel custom-switch ml-auto">
                                <input
                                  type="checkbox"
                                  className="custom-control-input"
                                  id="customSwitch3"
                                  checked=""
                                />
                                <label
                                  className="custom-control-label"
                                  for="customSwitch3"
                                ></label>
                              </div>
                            </li>
                            <li className="list-group-item has-icon">
                              Events
                              <div className="custom-control custom-control-nolabel custom-switch ml-auto">
                                <input
                                  type="checkbox"
                                  className="custom-control-input"
                                  id="customSwitch4"
                                  checked=""
                                />
                                <label
                                  className="custom-control-label"
                                  for="customSwitch4"
                                ></label>
                              </div>
                            </li>
                            <li className="list-group-item has-icon">
                              Pages You Follow
                              <div className="custom-control custom-control-nolabel custom-switch ml-auto">
                                <input
                                  type="checkbox"
                                  className="custom-control-input"
                                  id="customSwitch5"
                                />
                                <label
                                  className="custom-control-label"
                                  for="customSwitch5"
                                ></label>
                              </div>
                            </li>
                          </ul>
                        </div>
                      </form>
                    </div> */}
                    {/* <div className="tab-pane" id="billing">
                      <h6>BILLING SETTINGS</h6>
                      <hr />
                      <form>
                        <div className="form-group">
                          <label className="d-block mb-0">Payment Method</label>
                          <div className="small text-muted mb-3">
                            You have not added a payment method
                          </div>
                          <button className="btn btn-info" type="button">
                            Add Payment Method
                          </button>
                        </div>
                        <div className="form-group mb-0">
                          <label className="d-block">Payment History</label>
                          <div className="border border-gray-500 bg-gray-200 p-3 text-center font-size-sm">
                            You have not made any payment.
                          </div>
                        </div>
                      </form>
                    </div> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
