import React from "react";
import "./../../assets/css/message.css";

export default function Message() {
  return (
    <>
      <div className="app-main__inner">
        <div className="app-page-title pt-10">
          <div className="page-title-wrapper">
            <div className="page-title-heading">
              <div className="card-body p-0">
                <div className="mr-2 btn-group">
                  <h5 className="page-title">Message</h5>
                </div>
              </div>
            </div>
            <div className="page-title-actions pt-20">
              <div className="d-inline-block">
                <a className="mb-2 mr-2 btn btn-light btn-round">
                  <span className="btn-icon-wrapper opacity-7">
                    <i className="fas fa-bell mt-06"></i>
                  </span>
                </a>
              </div>
              <div className="d-inline-block">
                <a
                  href="./patient_add.php"
                  className="mb-2 mr-2 btn btn-primary color-white"
                >
                  <span className="btn-icon-wrapper pr-2 opacity-7">
                    <i className="fas fa-plus"></i>
                  </span>
                  Add New Patient
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="app">
        <div className="wrapper">
          <div className="conversation-area">
            <div className="text-center chat-area-header">
              <div className="search-bar w-100">
                <input type="text" placeholder="Search..." />
              </div>
            </div>

            <div className="msg online">
              <img
                className="msg-profile"
                src="./assets/images/avatars/4.png"
                alt=""
              />
              <div className="msg-detail">
                <div className="msg-username">Madison Jones</div>
                <div className="msg-content">
                  <span className="msg-message">What time was our meet</span>
                  <span className="msg-date">20m</span>
                </div>
              </div>
            </div>
            <div className="msg">
              <img
                className="msg-profile"
                src="./assets/images/avatars/3.png"
                alt=""
              />
              <div className="msg-detail">
                <div className="msg-username">Miguel Cohen</div>
                <div className="msg-content">
                  <span className="msg-message">
                    Adaptogen taiyaki austin jean shorts brunch
                  </span>
                  <span className="msg-date">20m</span>
                </div>
              </div>
            </div>
            <div className="msg active">
              <div className="msg-profile group">
                <img src="./assets/images/logo/logo.png" width="30" />
              </div>
              <div className="msg-detail">
                <div className="msg-username">Medicord Team</div>
                <div className="msg-content">
                  <span className="msg-message">Aysenur: I love CSS</span>
                  <span className="msg-date">28m</span>
                </div>
              </div>
            </div>
            <div className="msg online">
              <img
                className="msg-profile"
                src="./assets/images/avatars/2.png"
                alt=""
              />
              <div className="msg-detail">
                <div className="msg-username">Lea Debere</div>
                <div className="msg-content">
                  <span className="msg-message">
                    Shoreditch iPhone jianbing
                  </span>
                  <span className="msg-date">45m</span>
                </div>
              </div>
            </div>
            <div className="msg online">
              <img
                className="msg-profile"
                src="./assets/images/avatars/5.png"
                alt=""
              />
              <div className="msg-detail">
                <div className="msg-username">Jordan Smith</div>
                <div className="msg-content">
                  <span className="msg-message">
                    Snackwave craft beer raclette, beard kombucha{" "}
                  </span>
                  <span className="msg-date">2h</span>
                </div>
              </div>
            </div>
            <div className="msg">
              <img
                className="msg-profile"
                src="./assets/images/avatars/6.png"
                alt=""
              />
              <div className="msg-detail">
                <div className="msg-username">Jared Jackson</div>
                <div className="msg-content">
                  <span className="msg-message">
                    Tattooed brooklyn typewriter gastropub
                  </span>
                  <span className="msg-date">18m</span>
                </div>
              </div>
            </div>
            <div className="msg online">
              <img
                className="msg-profile"
                src="./assets/images/avatars/7.png"
                alt=""
              />
              <div className="msg-detail">
                <div className="msg-username">Henry Clark</div>
                <div className="msg-content">
                  <span className="msg-message">
                    Ethical typewriter williamsburg lo-fi street art
                  </span>
                  <span className="msg-date">2h</span>
                </div>
              </div>
            </div>
            <div className="msg">
              <img
                className="msg-profile"
                src="./assets/images/avatars/8.png"
                alt=""
              />
              <div className="msg-detail">
                <div className="msg-username">Jason Mraz</div>
                <div className="msg-content">
                  <span className="msg-message">
                    I'm lucky I'm in love with my best friend
                  </span>
                  <span className="msg-date">4h</span>
                </div>
              </div>
            </div>

            <div className="overlay"></div>
          </div>
          <div className="chat-area">
            <div className="chat-area-header">
              <div className="chat-area-title">Medicord Team</div>
              <div className="chat-area-group">
                <img
                  className="chat-area-profile"
                  src="./assets/images/avatars/1.jpg"
                  alt=""
                />
                <img
                  className="chat-area-profile"
                  src="./assets/images/avatars/2.png"
                  alt=""
                />
                <img
                  className="chat-area-profile"
                  src="./assets/images/avatars/3.png"
                  alt=""
                />
                <span>+4</span>
              </div>
            </div>
            <div className="chat-area-main">
              <div className="chat-msg">
                <div className="chat-msg-profile">
                  <img
                    className="chat-msg-img"
                    src="./assets/images/avatars/4.png"
                    alt=""
                  />
                  <div className="chat-msg-date">Message seen 1.22pm</div>
                </div>
                <div className="chat-msg-content">
                  <div className="chat-msg-text">
                    Luctus et ultrices posuere cubilia curae.
                  </div>

                  <div className="chat-msg-text">
                    Neque gravida in fermentum et sollicitudin ac orci phasellus
                    egestas. Pretium lectus quam id leo.
                  </div>
                </div>
              </div>
              <div className="chat-msg owner">
                <div className="chat-msg-profile">
                  <img
                    className="chat-msg-img"
                    src="./assets/images/avatars/3.png"
                    alt=""
                  />
                  <div className="chat-msg-date">Message seen 1.22pm</div>
                </div>
                <div className="chat-msg-content">
                  <div className="chat-msg-text">
                    Sit amet risus nullam eget felis eget. Dolor sed viverra
                    ipsum😂😂😂
                  </div>
                  <div className="chat-msg-text">
                    Cras mollis nec arcu malesuada tincidunt.
                  </div>
                </div>
              </div>
              <div className="chat-msg">
                <div className="chat-msg-profile">
                  <img
                    className="chat-msg-img"
                    src="./assets/images/avatars/1.png"
                    alt=""
                  />
                  <div className="chat-msg-date">Message seen 2.45pm</div>
                </div>
                <div className="chat-msg-content">
                  <div className="chat-msg-text">
                    Aenean tristique maximus tortor non tincidunt. Vestibulum
                    ante ipsum primis in faucibus orci luctus et ultrices
                    posuere cubilia curae😊
                  </div>
                  <div className="chat-msg-text">
                    Ut faucibus pulvinar elementum integer enim neque volutpat.
                  </div>
                </div>
              </div>
              <div className="chat-msg owner">
                <div className="chat-msg-profile">
                  <img
                    className="chat-msg-img"
                    src="./assets/images/avatars/3.png"
                    alt=""
                  />
                  <div className="chat-msg-date">Message seen 2.50pm</div>
                </div>
                <div className="chat-msg-content">
                  <div className="chat-msg-text">
                    posuere eget augue sodales, aliquet posuere eros.
                  </div>
                  <div className="chat-msg-text">
                    Cras mollis nec arcu malesuada tincidunt.
                  </div>
                </div>
              </div>
              <div className="chat-msg">
                <div className="chat-msg-profile">
                  <img
                    className="chat-msg-img"
                    src="./assets/images/avatars/4.png"
                    alt=""
                  />
                  <div className="chat-msg-date">Message seen 3.16pm</div>
                </div>
                <div className="chat-msg-content">
                  <div className="chat-msg-text">
                    Egestas tellus rutrum tellus pellentesque
                  </div>
                </div>
              </div>
              <div className="chat-msg">
                <div className="chat-msg-profile">
                  <img
                    className="chat-msg-img"
                    src="./assets/images/avatars/2.png"
                    alt=""
                    className="account-profile"
                    alt=""
                  />
                  <div className="chat-msg-date">Message seen 3.16pm</div>
                </div>
                <div className="chat-msg-content">
                  <div className="chat-msg-text">
                    Consectetur adipiscing elit pellentesque habitant morbi
                    tristique senectus et.
                  </div>
                </div>
              </div>
              <div className="chat-msg owner">
                <div className="chat-msg-profile">
                  <img
                    className="chat-msg-img"
                    src="./assets/images/avatars/4.png"
                    alt=""
                  />
                  <div className="chat-msg-date">Message seen 2.50pm</div>
                </div>
                <div className="chat-msg-content">
                  <div className="chat-msg-text">
                    Tincidunt arcu non sodales😂
                  </div>
                </div>
              </div>
              <div className="chat-msg">
                <div className="chat-msg-profile">
                  <img
                    className="chat-msg-img"
                    src="./assets/images/avatars/2.png"
                    alt=""
                  />
                  <div className="chat-msg-date">Message seen 3.16pm</div>
                </div>
                <div className="chat-msg-content">
                  <div className="chat-msg-text">
                    Consectetur adipiscing elit pellentesque habitant morbi
                    tristique senectus et🥰
                  </div>
                </div>
              </div>
            </div>
            <div className="chat-area-footer">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
                className="feather feather-video"
              >
                <path d="M23 7l-7 5 7 5V7z" />
                <rect x="1" y="5" width="15" height="14" rx="2" ry="2" />
              </svg>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
                className="feather feather-image"
              >
                <rect x="3" y="3" width="18" height="18" rx="2" ry="2" />
                <circle cx="8.5" cy="8.5" r="1.5" />
                <path d="M21 15l-5-5L5 21" />
              </svg>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
                className="feather feather-plus-circle"
              >
                <circle cx="12" cy="12" r="10" />
                <path d="M12 8v8M8 12h8" />
              </svg>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
                className="feather feather-paperclip"
              >
                <path d="M21.44 11.05l-9.19 9.19a6 6 0 01-8.49-8.49l9.19-9.19a4 4 0 015.66 5.66l-9.2 9.19a2 2 0 01-2.83-2.83l8.49-8.48" />
              </svg>
              <input type="text" placeholder="Type something here..." />
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
                className="feather feather-smile"
              >
                <circle cx="12" cy="12" r="10" />
                <path d="M8 14s1.5 2 4 2 4-2 4-2M9 9h.01M15 9h.01" />
              </svg>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
                className="feather feather-thumbs-up"
              >
                <path d="M14 9V5a3 3 0 00-3-3l-4 9v11h11.28a2 2 0 002-1.7l1.38-9a2 2 0 00-2-2.3zM7 22H4a2 2 0 01-2-2v-7a2 2 0 012-2h3" />
              </svg>
            </div>
          </div>
          <div className="detail-area">
            <div className="detail-area-header">
              <div className="msg-profile group">
                <img src="./assets/images/logo/logo.png" width="40" />
              </div>
              <div className="detail-title">Medicord Team</div>
              <div className="detail-buttons">
                <button className="detail-button">
                  <svg
                    viewbox="0 0 24 24"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="currentColor"
                    stroke="currentColor"
                    stroke-width="0"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    className="feather feather-phone"
                  >
                    <path d="M22 16.92v3a2 2 0 01-2.18 2 19.79 19.79 0 01-8.63-3.07 19.5 19.5 0 01-6-6 19.79 19.79 0 01-3.07-8.67A2 2 0 014.11 2h3a2 2 0 012 1.72 12.84 12.84 0 00.7 2.81 2 2 0 01-.45 2.11L8.09 9.91a16 16 0 006 6l1.27-1.27a2 2 0 012.11-.45 12.84 12.84 0 002.81.7A2 2 0 0122 16.92z" />
                  </svg>
                  Call Group
                </button>
                <button className="detail-button">
                  <svg
                    viewbox="0 0 24 24"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="currentColor"
                    stroke="currentColor"
                    stroke-width="0"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    className="feather feather-video"
                  >
                    <path d="M23 7l-7 5 7 5V7z" />
                    <rect x="1" y="5" width="15" height="14" rx="2" ry="2" />
                  </svg>
                  Video Chat
                </button>
              </div>
            </div>
            <div className="detail-changes">
              <div className="detail-photo-title">Contact Info</div>
              <div>
                <h6 className="text-mute font-12 bold mb-02">Email Address</h6>
                <p>ideau98@gmail.com</p>
              </div>
              <div>
                <h6 className="text-mute font-12 bold mb-02">Phone Number</h6>
                <p>(+977) 9851 226367</p>
              </div>
              <div>
                <h6 className="text-mute font-12 bold mb-02">Address</h6>
                <p>Kathmandu, Nepal</p>
              </div>
            </div>
            <div className="detail-photos">
              <div className="detail-photo-title">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewbox="0 0 24 24"
                  fill="none"
                  stroke="currentColor"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  className="feather feather-image"
                >
                  <rect x="3" y="3" width="18" height="18" rx="2" ry="2" />
                  <circle cx="8.5" cy="8.5" r="1.5" />
                  <path d="M21 15l-5-5L5 21" />
                </svg>
                Shared photos
              </div>
              {/* <div className="detail-photo-grid">
                   <img src="https://images.unsplash.com/photo-1523049673857-eb18f1d7b578?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=2168&q=80" />
                   <img src="https://images.unsplash.com/photo-1516085216930-c93a002a8b01?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=2250&q=80" />
                   <img src="https://images.unsplash.com/photo-1458819714733-e5ab3d536722?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=933&q=80" />
                   <img src="https://images.unsplash.com/photo-1520013817300-1f4c1cb245ef?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=2287&q=80" />
                   <img src="https://images.unsplash.com/photo-1494438639946-1ebd1d20bf85?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=2247&q=80" />
                   <img src="https://images.unsplash.com/photo-1559181567-c3190ca9959b?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1300&q=80" />
                   <img src="https://images.unsplash.com/photo-1560393464-5c69a73c5770?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1301&q=80" />
                   <img src="https://images.unsplash.com/photo-1506619216599-9d16d0903dfd?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=2249&q=80" />
                   <img src="https://images.unsplash.com/photo-1481349518771-20055b2a7b24?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=2309&q=80" />
                   <img src="https://images.unsplash.com/photo-1473170611423-22489201d919?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=2251&q=80" />
                   <img src="https://images.unsplash.com/photo-1579613832111-ac7dfcc7723f?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=2250&q=80" />
                   <img src="https://images.unsplash.com/photo-1523275335684-37898b6baf30?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=2189&q=80" />
                </div> */}
              <div className="view-more">View More</div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
