import {
  DOCTOR_CHANGE_PASSWORD,
  DOCTOR_CHANGE_PASSWORD_FAILURE,
  DOCTOR_CHANGE_PASSWORD_SUCCESS,
  DOCTOR_INFO_UPDATE,
  DOCTOR_INFO_UPDATE_FAILURE,
  DOCTOR_INFO_UPDATE_SUCCESS,
  DOCTOR_SINGLE_FETCH,
  DOCTOR_SINGLE_FETCHED,
  LOGOUT_USER,
} from "../constants/types";
import http from "../../utils/http";
import toast from "../../utils/toast";

export const fetchDoctorInfo = () => (dispatch) => {
  dispatch({ type: DOCTOR_SINGLE_FETCH });
  http
    .GET("Doctor/details")
    .then((res) => {
      // console.log(res.data);
      dispatch({ type: DOCTOR_SINGLE_FETCHED, payload: res.data });
    })
    .catch((error) => {
      console.log(error.response.data?.code);
      if (error.response.data.code === "401") dispatch({ type: LOGOUT_USER });
      toast.error(error);
    });
};

export const updateDoctorInfo = (data) => (dispatch) => {
  dispatch({ type: DOCTOR_INFO_UPDATE });
  http
    .PUT("Doctor/details", data)
    .then((res) => {
      toast.success(res.data.message);
      dispatch({ type: DOCTOR_INFO_UPDATE_SUCCESS, payload: res.data });
    })
    .catch((error) => {
      if (error.response.data.code === "401") dispatch({ type: LOGOUT_USER });
      toast.error(error);
      dispatch({ type: DOCTOR_INFO_UPDATE_FAILURE });
      // dispatch({ type: LOGIN_FAIL, message: error.response?.data.message });
    });
};

export const updatePassword = (data) => (dispatch) => {
  dispatch({ type: DOCTOR_CHANGE_PASSWORD });
  http
    .PUT("Doctor/changepassword", data)
    .then((res) => {
      toast.success(res.data.message);
      dispatch({ type: DOCTOR_CHANGE_PASSWORD_SUCCESS, payload: res.data });
      dispatch({ type: LOGOUT_USER });
    })
    .catch((error) => {
      if (error.response.data.code === "401") dispatch({ type: LOGOUT_USER });
      toast.error(error);
      dispatch({ type: DOCTOR_CHANGE_PASSWORD_FAILURE });
      // dispatch({ type: LOGIN_FAIL, message: error.response?.data.message });
    });
};
