import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchInfantSingle } from "../../../redux/actions/infant.action";
import { useParams, Link } from "react-router-dom";
import "./../../../assets/css/patientDetail.css";
import { useLocation, useNavigate } from "react-router-dom";
import getFirstCharacters from "../../../utils/getFirstCharacters";
export default function PDetails() {
  const { infant } = useSelector((state) => state.infant);
  const { id } = useParams();
  let { search } = useLocation();
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(fetchInfantSingle(id));
  }, [dispatch]);

  const [visitDetails, setVisitDetails] = useState({});
  const onVisitDetails = (id) => {
    const data = infant?.infantVisitList?.filter(
      (item) => item.visit_id === id
    );
    setVisitDetails(data[0]);
  };
  const navigate = useNavigate();
  const onPatientClick = () => {
    navigate(-1);
  };

  return (
    <>
      <div className="app-main__inner">
        <div className="app-page-title pt-0 pb-0">
          <div className="page-title-wrapper">
            <div className="page-title-heading">
              <div className="card-body p-0">
                <ul className="body-tabs body-tabs-layout tabs-animated body-tabs-animated nav">
                  <li className="nav-item">
                    <h5
                      style={{
                        marginTop: "16px",
                        fontSize: "18px",
                        fontWeight: "700",
                        paddingBottom: "10px",
                      }}
                    >
                      {search == "?istaff" ? (
                        <>
                          <Link to="/staff">Staff List</Link> &emsp;
                          <i className="fas fa-angle-right"></i> &emsp;
                          <Link to="" onClick={onPatientClick}>
                            Infant List
                          </Link>
                          &emsp; <i className="fas fa-angle-right"></i> &emsp;
                        </>
                      ) : (
                        <>
                          <Link to="/patient?infant">Infant List</Link> &emsp;
                          <i className="fas fa-angle-right"></i>
                        </>
                      )}
                      &emsp;
                    </h5>
                  </li>
                  <li className="nav-item">
                    <a
                      role="tab"
                      className="nav-link active font-small-14 bold"
                      id="tab-0"
                      data-toggle="tab"
                      href="#patient-overview"
                    >
                      <span>Overview</span>
                    </a>
                  </li>
                  <li className="nav-item">
                    <a
                      role="tab"
                      className="nav-link font-small-14 bold"
                      id="tab-3"
                      data-toggle="tab"
                      href="#medical-journal"
                    >
                      <span>Medical Journal</span>
                    </a>
                  </li>
                  <li className="nav-item">
                    <a
                      role="tab"
                      className="nav-link font-small-14 bold"
                      id="tab-4"
                      data-toggle="tab"
                      href="#prescription"
                    >
                      <span>Prescription</span>
                    </a>
                  </li>
                  <li className="nav-item">
                    <a
                      role="tab"
                      className="nav-link font-small-14 bold"
                      id="tab-1"
                      data-toggle="tab"
                      href="#document"
                    >
                      <span>Documents</span>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>

        <div className="tab-content pt-20">
          <div
            className="tab-pane tabs-animation fade show active"
            id="patient-overview"
            role="tabpanel"
          >
            <div className="row">
              <div className="col-md-8 pr-0">
                <div className="row">
                  <div className="col-md-4 pr-05">
                    <div className="main-card mb-3 card min-h-300">
                      <div className="card-body text-center pb-16">
                        <div
                          className="circle rounded-circle"
                          style={{ margin: "auto" }}
                        >
                          <span className="initials">
                            {getFirstCharacters(infant?.infantFullName)}
                          </span>
                        </div>

                        <div className="card-body pb-0 pl-0 pr-0">
                          <h5 className="card-title mb-0">
                            {infant?.infantFullName}
                          </h5>
                          <p className="text-mute">{infant?.infantPhone}</p>
                          <table className="mb-0 table table-borderless">
                            <tbody>
                              <tr>
                                <td
                                  width="50%"
                                  style={{ borderRight: "2px solid #E5E8E8" }}
                                >
                                  <h5 className="bold">15</h5>
                                  <p className="text-mute font-small mt--10 mb-0">
                                    Past
                                  </p>
                                </td>
                                <td width="50%">
                                  <h5 className="bold">2</h5>
                                  <p className="text-mute font-small mt--10 mb-0">
                                    Upcoming
                                  </p>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                          <a
                            className="btn-transition btn btn-outline-light btn-lg btn-more btn-block"
                            style={{ marginTop: "20px" }}
                          >
                            More Detail
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-8 pl-0">
                    <div className="main-card mb-3 card min-h-300">
                      <div className="card-body">
                        <h5 className="card-title">Infant Details</h5>
                        <table className="mb-0 table table-borderless">
                          <tbody>
                            <tr>
                              <td>
                                <p className="bold">Gender</p>
                                <p className="text-mute font-12 mt--10">
                                  {infant?.infantGender}
                                </p>
                              </td>
                              <td>
                                <p className="bold">D.O.B. (Nepali)</p>
                                <p className="text-mute font-12 mt--10">
                                  {infant?.infantDobNepali}
                                </p>
                              </td>
                              <td>
                                <p className="bold">D.O.B. (English)</p>
                                <p className="text-mute font-12 mt--10">
                                  {infant?.infantDobEnglish}
                                </p>
                              </td>
                              <td>
                                <p className="bold">Phone</p>
                                <p className="text-mute font-12 mt--10">
                                  (977) {infant?.infantPhone}
                                </p>
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <p className="bold">Age</p>
                                <p className="text-mute font-12 mt--10">
                                  {infant?.infantAge}
                                </p>
                              </td>
                              <td>
                                <p className="bold">Age in Days</p>
                                <p className="text-mute font-12 mt--10">
                                  {infant?.infantAgeInDays}
                                </p>
                              </td>
                              <td>
                                <p className="bold">Age in Months</p>
                                <p className="text-mute font-12 mt--10">
                                  {infant?.infantAgeInMonth}
                                </p>
                              </td>
                              <td>
                                <p className="bold">Age in Year</p>
                                <p className="text-mute font-12 mt--10">
                                  {infant?.infantAgeInYear}
                                </p>
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <p className="bold">Village</p>
                                <p className="text-mute font-12 mt--10">
                                  {"Ward no " +
                                    infant?.infantwardno +
                                    ", " +
                                    infant?.infantVillagename}
                                </p>
                              </td>
                              <td>
                                <p className="bold">House No</p>
                                <p className="text-mute font-12 mt--10">
                                  {infant?.infantHouseno}
                                </p>
                              </td>
                              <td>
                                <p className="bold">Registered Date</p>
                                <p className="text-mute font-12 mt--10">
                                  {infant?.infantAddedDate}
                                </p>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="mb-3 card h-400 mt--10">
                  <div className="card-body">
                    <ul className="tabs-animated-shadow tabs-animated nav appointment-tab">
                      <li className="nav-item">
                        <a
                          role="tab"
                          className="nav-link schedule-link show active"
                          id="tab-c-0"
                          data-toggle="tab"
                          href="#tab-animated-0"
                          aria-selected="true"
                        >
                          <span className="font-12">Upcoming Appointments</span>
                        </a>
                      </li>
                      <li className="nav-item">
                        <a
                          role="tab"
                          className="nav-link schedule-link show"
                          id="tab-c-1"
                          data-toggle="tab"
                          href="#tab-animated-1"
                          aria-selected="false"
                        >
                          <span className="font-12">Past Appointments</span>
                        </a>
                      </li>
                    </ul>

                    <div className="tab-content">
                      <div
                        className="scroll-area-md tab-pane show active"
                        id="tab-animated-0"
                        role="tabpanel"
                      >
                        <div className="scrollbar-container ps--active-y ps back-gray">
                          <ul className="timeline">
                            {infant?.NextVisitDates?.map((item, index) => {
                              return (
                                <li key={index}>
                                  <div className="list-group-item">
                                    <div className="widget-content p-0">
                                      <div className="widget-content-wrapper">
                                        <div className="row w100">
                                          <div className="col-md-2 text-center">
                                            <img
                                              width="50"
                                              className="rounded-circle"
                                              src="assets/images/avatars/4.png"
                                              alt=""
                                            />
                                          </div>
                                          <div className="col-md-5 pl-0 pt-05">
                                            <div className="widget-heading bold">
                                              {item.visit_followupdate_nepali}
                                            </div>
                                            {/* <div className="widget-subheading text-mute">
                                              Surgery
                                            </div> */}
                                          </div>
                                          <div className="col-md-5 text-right pt-05">
                                            <div className="font-size-xlg text-muted">
                                              <h6>
                                                {
                                                  item.visit_followupdate_english
                                                }
                                              </h6>
                                              {/* <p className="opacity-5 pr-1 font-14 mt--5 mb-0">
                                                09:00 AM - 10:00 AM
                                              </p> */}
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </li>
                              );
                            })}
                          </ul>
                        </div>
                      </div>
                      <div
                        className="scroll-area-md tab-pane show"
                        id="tab-animated-1"
                        role="tabpanel"
                      >
                        <div className="scrollbar-container ps--active-y ps back-gray">
                          <ul className="timeline">
                            {infant?.pastVisitDates?.map((item, index) => {
                              return (
                                <li key={index}>
                                  <div className="list-group-item">
                                    <div className="widget-content p-0">
                                      <div className="widget-content-wrapper">
                                        <div className="row w100">
                                          <div className="col-md-2 text-center">
                                            <img
                                              width="50"
                                              className="rounded-circle"
                                              src="assets/images/avatars/4.png"
                                              alt=""
                                            />
                                          </div>
                                          <div className="col-md-5 pl-0 pt-05">
                                            <div className="widget-heading bold">
                                              {item.visit_lastdate_nepali}
                                            </div>
                                            <div className="widget-subheading text-mute">
                                              {item.visit_lastdate_english}
                                            </div>
                                          </div>
                                          <div className="col-md-5 text-right pt-05">
                                            <div className="font-size-xlg text-muted">
                                              <h6>
                                                {item.visit_lastdate_nepali}
                                              </h6>
                                              <p className="opacity-5 pr-1 font-14 mt--5 mb-0">
                                                {item.visit_lastdate_english}
                                              </p>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </li>
                              );
                            })}
                          </ul>
                        </div>
                      </div>
                      <div
                        className="scroll-area-md tab-pane show"
                        id="tab-animated-2"
                        role="tabpanel"
                      >
                        <div className="scrollbar-container ps--active-y ps">
                          <h5
                            className="card-title"
                            style={{ paddingLeft: "20px" }}
                          >
                            File / Documents
                          </h5>
                          <div style={{ padding: "20px" }}>
                            <table className="mb-0 table table-hover">
                              <tbody>
                                <tr>
                                  <td>
                                    <i
                                      className="fas fa-file-alt"
                                      style={{ color: "#CCD1D1" }}
                                    ></i>
                                  </td>
                                  <td>Checkup Result PDF</td>
                                  <td>123 Kb</td>
                                </tr>
                                <tr>
                                  <td>
                                    <i
                                      className="fas fa-file-alt"
                                      style={{ color: "#CCD1D1" }}
                                    ></i>
                                  </td>
                                  <td>Checkup Result PDF</td>
                                  <td>123 Kb</td>
                                </tr>
                                <tr>
                                  <td>
                                    <i
                                      className="fas fa-file-alt"
                                      style={{ color: "#CCD1D1" }}
                                    ></i>
                                  </td>
                                  <td>Checkup Result PDF</td>
                                  <td>123 Kb</td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-4">
                <div className="main-card mb-3 card">
                  <div className="card-body">
                    <h5 className="card-title">Notes</h5>
                    <div className="card-body p-0">
                      <form className="">
                        <div className="position-relative form-group">
                          <textarea
                            name="text"
                            id="exampleText"
                            className="form-control"
                          ></textarea>
                        </div>
                        <div className="row">
                          <div
                            className="col-md-8"
                            style={{ marginTop: "10px" }}
                          >
                            <i
                              className="fas fa-user"
                              style={{ color: "#5DADE2" }}
                            ></i>
                            &nbsp;&nbsp;Medicord Admin
                          </div>
                          <div className="col-md-4">
                            <div style={{ textAlign: "right" }}>
                              <button className="mt-1 btn btn-primary">
                                Save
                              </button>
                            </div>
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
                <div className="main-card mb-3 card h-400 mt--10">
                  <div className="card-body">
                    <h5 className="card-title">Medical Info</h5>
                    <div id="accordion" className="accordion-wrapper mb-3">
                      <div className="card">
                        <div id="headingOne" className="card-header">
                          <button
                            type="button"
                            data-toggle="collapse"
                            data-target="#medical-problems"
                            aria-expanded="false"
                            aria-controls="collapseOne"
                            className="text-left m-0 p-0 btn btn-link btn-block collapsed font-14"
                          >
                            <i
                              className="fas fa-file-alt"
                              style={{ color: "#3f6ad8" }}
                            ></i>
                            &emsp;
                            <span style={{ color: "#616A6B" }}>
                              Medical Problems
                            </span>
                          </button>
                        </div>
                        <div
                          data-parent="#accordion"
                          id="medical-problems"
                          aria-labelledby="headingOne"
                          className="collapse"
                        >
                          <div className="card-body">
                            1. Anim pariatur cliche reprehenderit, enim eiusmod
                            high life accusamus terry richardson ad squid. 3
                            wolf moon officia aute, non cupidatat skateboard
                            dolor brunch. Food truck quinoa nesciunt laborum
                            eiusmod. Brunch 3 wolf moon tempor, sunt aliqua put
                            a bird on it squid single-origin coffee nulla
                            assumenda shoreditch et. Nihil anim keffiyeh
                            helvetica, craft beer labore wes anderson cred
                            nesciunt sapiente ea proident. Ad vegan excepteur
                            butcher vice lomo. Leggings occaecat craft beer
                            farm-to-table, raw denim aesthetic synth nesciunt
                            you probably haven't heard of them accusamus labore
                            sustainable VHS.
                          </div>
                        </div>
                      </div>
                      <div className="card">
                        <div id="headingTwo" className="b-radius-0 card-header">
                          <button
                            type="button"
                            data-toggle="collapse"
                            data-target="#allergies"
                            aria-expanded="false"
                            aria-controls="collapseTwo"
                            className="text-left m-0 p-0 btn btn-link btn-block font-14"
                          >
                            <i
                              className="fas fa-file-alt"
                              style={{ color: "#3f6ad8" }}
                            ></i>
                            &emsp;
                            <span style={{ color: "#616A6B" }}>Allergies</span>
                          </button>
                        </div>
                        <div
                          data-parent="#accordion"
                          id="allergies"
                          className="collapse"
                        >
                          <div className="card-body">
                            2. Anim pariatur cliche reprehenderit, enim eiusmod
                            high life accusamus terry richardson ad squid. 3
                            wolf moon officia aute, non cupidatat skateboard
                            dolor brunch. Food truck quinoa nesciunt laborum
                            eiusmod. Brunch 3 wolf moon tempor, sunt aliqua put
                            a bird on it squid single-origin coffee nulla
                            assumenda shoreditch et. Nihil anim keffiyeh
                            helvetica, craft beer labore wes anderson cred
                            nesciunt sapiente ea proident. Ad vegan excepteur
                            butcher vice lomo. Leggings occaecat craft beer
                            farm-to-table, raw denim aesthetic synth nesciunt
                            you probably haven't heard of them accusamus labore
                            sustainable VHS.
                          </div>
                        </div>
                      </div>
                      <div className="card">
                        <div id="headingThree" className="card-header">
                          <button
                            type="button"
                            data-toggle="collapse"
                            data-target="#immunization"
                            aria-expanded="false"
                            aria-controls="collapseThree"
                            className="text-left m-0 p-0 btn btn-link btn-block font-14"
                          >
                            <i
                              className="fas fa-file-alt"
                              style={{ color: "#3f6ad8" }}
                            ></i>
                            &emsp;
                            <span style={{ color: "#616A6B" }}>
                              Immunization
                            </span>
                          </button>
                        </div>
                        <div
                          data-parent="#accordion"
                          id="immunization"
                          className="collapse"
                        >
                          <div className="card-body">
                            3. Anim pariatur cliche reprehenderit, enim eiusmod
                            high life accusamus terry richardson ad squid. 3
                            wolf moon officia aute, non cupidatat skateboard
                            dolor brunch. Food truck quinoa nesciunt laborum
                            eiusmod. Brunch 3 wolf moon tempor, sunt aliqua put
                            a bird on it squid single-origin coffee nulla
                            assumenda shoreditch et. Nihil anim keffiyeh
                            helvetica, craft beer labore wes anderson cred
                            nesciunt sapiente ea proident. Ad vegan excepteur
                            butcher vice lomo. Leggings occaecat craft beer
                            farm-to-table, raw denim aesthetic synth nesciunt
                            you probably haven't heard of them accusamus labore
                            sustainable VHS.
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div
            className="tab-pane tabs-animation fade"
            id="medical-journal"
            role="tabpanel"
          >
            <div className="row">
              <div className="col-md-2">
                <table className="mb-0 table table-borderless">
                  <tbody>
                    <tr>
                      <td>
                        <i className="fas fa-calendar-alt font-22"></i>
                      </td>
                      <td className="bold font-12">Upcoming Visits</td>
                    </tr>
                  </tbody>
                </table>

                <ul className="timeline ml--12">
                  <li>
                    <p className="mb-0 font-small text-mute">Nepali</p>
                    <p className="bold mb-3 font-12">
                      {infant?.NextVisitDates[0]?.visit_followupdate_nepali}
                    </p>
                    <p className="mb-0 font-small text-mute">English</p>
                    <p className="bold mb-3 font-12">
                      {infant?.NextVisitDates[0]?.visit_followupdate_english}
                    </p>

                    <p className="mb-0 font-small text-mute">TYPE</p>
                    <p className="bold font-12">Followup</p>
                  </li>

                  <div className="mt-40">
                    <p className="bold font-small">Previous Visits</p>
                  </div>
                  {infant?.pastVisitDates?.map((item) => (
                    <li>
                      <p className="mb-0 font-small text-mute">Nepali</p>
                      <p className="bold mb-3 font-12">
                        {item.visit_lastdate_nepali}
                      </p>
                    </li>
                  ))}
                </ul>
              </div>

              <div className="col-md-10">
                <div className="main-card mb-3 card">
                  <div className="card-body">
                    <h6 className="bold">Medical Journal Timeline</h6>
                    <hr />
                    <div>
                      <ul className="timeline">
                        {infant?.infantVisitList?.map((item, index) => {
                          return (
                            <li key={index}>
                              <a
                                className="journal-toggle"
                                data-toggle="modal"
                                data-target="#journal_view"
                                href="#"
                                onClick={() => onVisitDetails(item.visit_id)}
                              >
                                <div className="mb-3 card card-body bg-gray pb-0">
                                  <h6 className="mb-0">
                                    {item.visit_category}
                                  </h6>
                                  {/* <p>Dr. Sharma, Community Hospital</p> */}
                                  <p className="font-small">
                                    {item?.modelVisitSafe
                                      ?.visit_lastdate_nepali ||
                                      item?.visit_lastdate_nepali}
                                  </p>{" "}
                                </div>
                              </a>
                            </li>
                          );
                        })}
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div
            className="tab-pane tabs-animation fade"
            id="prescription"
            role="tabpanel"
          >
            <div className="row">
              <div className="col-md-4">
                <h6 className="bold">Active Prescriptions</h6>
                <hr />
              </div>
              <div className="col-md-4">
                <h6 className="bold">Previous Prescriptions</h6>
                <hr />
              </div>
              <div className="col-md-4">
                <h6 className="bold">Patient Notes</h6>
                <hr />
              </div>
            </div>
            <div className="row">
              <div className="col-md-12" style={{ textAlign: "center" }}>
                <img
                  src="/nodata.png"
                  style={{ maxWidth: "300px", paddingTop: "80px" }}
                />
                <h6 className="bold">No data found</h6>
                <p className="text-mute">
                  There are no recorded prescriptions at this moment to display.
                </p>
              </div>
            </div>
          </div>

          <div
            className="tab-pane tabs-animation fade"
            id="document"
            role="tabpanel"
          >
            <div className="row">
              <div className="col-md-12" style={{ textAlign: "center" }}>
                <img
                  src="/nodata.png"
                  style={{ maxWidth: "300px", paddingTop: "80px" }}
                />
                <h6 className="bold">No data found</h6>
                <p className="text-mute">
                  There are no recorded documents at this moment to display.
                </p>
              </div>
            </div>
          </div>
        </div>

        {/* //////////////////////////////////// */}
        <div
          className="modal fade pr-0"
          id="journal_view"
          tabIndex="-1"
          role="dialog"
          aria-labelledby="journal_view"
          aria-hidden="true"
        >
          <div
            className="modal-dialog"
            role="document"
            style={{ margin: "0", float: "right", minWidth: "35%" }}
          >
            <div
              className="modal-content"
              style={{ borderRadius: "0", minHeight: "100vh" }}
            >
              <div className="modal-header pb-0 bb-0">
                <div
                  className="mini-article-meta"
                  style={{ marginTop: "-20px", paddingBottom: "10px" }}
                >
                  <div className="mini-article-byline">
                    <div className="author-avatar">
                      <div
                        className="circle rounded-circle"
                        style={{ margin: "auto" }}
                      >
                        <span className="initials">
                          {getFirstCharacters(infant?.infantFullName)}
                        </span>
                      </div>
                    </div>
                    <div className="author-name-area">
                      <Link className="author-name" to="#">
                        {visitDetails?.visit_category}
                      </Link>
                      <time dateTime="2018-12-21">
                        {visitDetails?.visit_lastdate_english}
                      </time>
                    </div>
                  </div>
                </div>
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>

              <div className="modal-body p-20">
                <div>
                  <h6 className="bold pt-10">Checkup</h6>
                  <table className="mb-0 table table-borderless">
                    <tbody>
                      <tr>
                        <td>
                          <p className="bold font-12">isBellyCold</p>
                          <p className="font-12 mt--10">
                            {visitDetails?.iCheckup?.isBellyCold || ""}
                          </p>
                        </td>
                        <td>
                          <p className="bold font-12">Temperature</p>
                          <p className="font-12 mt--10">
                            {visitDetails?.iCheckup?.temperature || ""}
                          </p>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  <h6 className="bold pt-10">Condition</h6>
                  <table className="mb-0 table table-borderless">
                    <tbody>
                      <tr>
                        <td>
                          <p className="bold font-12">Group</p>
                        </td>
                        <td>
                          <p className="bold font-12">Question</p>
                        </td>
                        <td>
                          <p className="bold font-12">isYes?</p>
                        </td>
                      </tr>
                      {visitDetails?.iCondition?.map((item, index) => (
                        <tr key={index}>
                          <td>
                            <p className="font-12 mt--10">{item.group || ""}</p>
                          </td>
                          <td>
                            <p className="font-12 mt--10">
                              {" "}
                              {item.question || ""}
                            </p>
                          </td>
                          <td>
                            <p className="font-12 mt--10">
                              {" "}
                              {item.isYes ? "True" : "False"}
                            </p>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                  <h6 className="bold pt-10">Diarrhoea</h6>
                  <table className="mb-0 table table-borderless">
                    <tbody>
                      <tr>
                        <td>
                          <p className="bold font-12">does Have Diarrhoea?</p>
                          <p className="font-12 mt--10">
                            {visitDetails?.iDiarrhoea?.doesHaveDiarrhoea || ""}
                          </p>
                        </td>
                        <td>
                          <p className="bold font-12">any Diarrhoea Sign?</p>
                          {visitDetails?.iDiarrhoea?.anyDiarrhoeaSign?.map(
                            (i) => (
                              <p className="font-12 mt--10" key={i}>
                                {i}
                              </p>
                            )
                          )}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  <h6 className="bold pt-10">Heart Rate</h6>
                  <p className="bold font-12">
                    Time of Breathing Per Min?
                    <br />
                    {visitDetails?.iHeartRate?.timeOfBreathingPerMin || ""}
                  </p>
                  <h6 className="bold pt-10">Jaundice</h6>
                  <table className="mb-0 table table-borderless">
                    <tbody>
                      <tr>
                        <td>
                          <p className="bold font-12">does Have Jaundice?</p>
                          <p className="font-12 mt--10">
                            {visitDetails?.iJaundice?.doesHaveJaundice || ""}
                          </p>
                        </td>
                        <td>
                          <p className="bold font-12">is Palms Soles Yellow?</p>
                          {visitDetails?.iJaundice?.isPalmsSolesYellow || ""}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  <h6 className="bold pt-10">Mother</h6>
                  <table className="mb-0 table table-borderless">
                    <tbody>
                      <tr>
                        <td>
                          <p className="bold font-12">list Of Vaccines</p>
                          {visitDetails?.iMother?.listOfVaccines?.map((i) => (
                            <p className="font-12 mt--10" key={i}>
                              {i}
                            </p>
                          ))}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  <h6 className="bold pt-10">Oral Ulcer</h6>
                  <table className="mb-0 table table-borderless">
                    <tbody>
                      <tr>
                        <td>
                          <p className="bold font-12">does Have Oral Ulcer?</p>
                          <p className="font-12 mt--10">
                            {visitDetails?.iOralUlcer?.doesHaveOralUlcer || ""}
                          </p>
                        </td>
                        <td>
                          <p className="bold font-12">does Have Oral Ulcer?</p>
                          <p className="font-12 mt--10">
                            {visitDetails?.iOralUlcer?.ulcerPresentDesc || ""}
                          </p>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  <h6 className="bold pt-10">Risk</h6>
                  <table className="mb-0 table table-borderless">
                    <tbody>
                      <tr>
                        <td>
                          <p className="bold font-12">does Have Risk?</p>
                          <p className="font-12 mt--10">
                            {visitDetails?.iRisk?.does_have_risk || ""}
                          </p>
                        </td>
                        <td>
                          <p className="bold font-12">Risk Signs</p>
                          {visitDetails?.iRisk?.riskSigns?.map((i) => (
                            <p className="font-12 mt--10" key={i}>
                              {i}
                            </p>
                          ))}
                        </td>
                      </tr>
                    </tbody>
                  </table>{" "}
                  <h6 className="bold pt-10">Training</h6>
                  <table className="mb-0 table table-borderless">
                    <tbody>
                      <tr>
                        <td>
                          <p className="bold font-12">
                            list of Problem Feeding?
                          </p>

                          {visitDetails?.infantTraining?.listProblemFeeding?.map(
                            (i) => (
                              <p className="font-12 mt--10" key={i}>
                                {i}
                              </p>
                            )
                          )}
                        </td>
                        <td>
                          <p className="bold font-12">
                            is Success After Training?
                          </p>

                          {visitDetails?.infantTraining
                            ?.isSuccessAfterTraining || ""}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* //////////////////////////////////// */}
        <div
          className="modal fade pr-0"
          id="document_view"
          tabIndex="-1"
          role="dialog"
          aria-labelledby="document_view"
          aria-hidden="true"
        >
          <div
            className="modal-dialog"
            role="document"
            style={{ margin: "0", float: "right", minWidth: "60%" }}
          >
            <div
              className="modal-content"
              style={{ bordeRadius: "0", minHeight: "100vh" }}
            >
              <div className="modal-header pb-0 bb-0">
                <h5
                  className="modal-title bold font-16"
                  id="exampleModalLongTitle"
                >
                  Blood Test Report
                </h5>
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <hr
                style={{
                  marginTop: "0px !important",
                  marginBottom: "-2px !important",
                }}
              />
              <div className="row schedule-modal-title ml-0 pb-10">
                <div className="col-md-7">
                  <div className="mini-article-meta">
                    <div className="mini-article-byline">
                      <div className="author-avatar">
                        <img
                          className="img-round"
                          src="./assets/images/hospital/dh.png"
                          style={{ width: "50px", height: "50px" }}
                        />
                      </div>
                      <div className="author-name-area">
                        <a className="author-name" href="#">
                          Dhulikhel Hospital
                        </a>
                        <time dateTime="2018-12-21">24 Nov 2020</time>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-5 text-right mt-30">
                  <h6 className="font-12">
                    Reporting Doctor:{" "}
                    <span className="bold">Dr. Andy Lipmann</span>
                  </h6>
                  <h6 className="font-12">
                    Approving Doctor:{" "}
                    <span className="bold">Dr. Lucy Adams</span>
                  </h6>
                </div>
              </div>

              <div className="modal-body" style={{ padding: "10px" }}>
                <div className="invoice">
                  <div className="invoice-body">
                    <div className="row">
                      <div className="col-md-5">
                        <div className="panel panel-default">
                          <div className="panel-heading">
                            <h6 className="font-16 bold panel-title">
                              Test Details
                            </h6>
                          </div>
                          <div className="panel-body text-left">
                            <dl className="dl-horizontal" />
                            <dt>Ordering Dr.</dt>
                            <dd>Dr. Andy Lipmann</dd>
                            <dt>Physician Copy For</dt>
                            <dd>Dr. Shristy Rawal</dd>
                            <dt>Status</dt>
                            <dd>Routine</dd>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-7">
                        <div className="panel panel-default">
                          <div className="panel-heading">
                            <h6 className="font-16 bold panel-title">
                              Specimen Details
                            </h6>
                          </div>
                          <div className="panel-body">
                            <dl className="dl-horizontal" />
                            <dt>Specimen</dt>
                            <dd>Serum</dd>
                            <dt>Ordered</dt>
                            <dd>Basic Metabolic Panel (BMP)</dd>
                            <dt>Comments</dt>
                            <dd>Specimen is non-fasting: slight hemolysis</dd>
                          </div>
                        </div>
                      </div>
                    </div>
                    <hr />
                    <div className="panel panel-default">
                      <div className="panel-heading">
                        <h6 className="font-16 bold panel-title">
                          Test Details
                        </h6>
                      </div>
                      <table className="table table-bordered table-condensed">
                        <thead>
                          <tr>
                            <th className="pl-10">Item / Details</th>
                            <th className="text-center colfix">Unit Cost</th>
                            <th className="text-center colfix">Sum Cost</th>
                            <th className="text-center colfix">Discount</th>
                            <th className="text-center colfix">Tax</th>
                            <th className="text-center colfix">Total</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td className="pl-10">
                              Lorem Ipsum Dolor
                              <br />
                              <small className="text-muted">
                                The best lorem in town, try it now or leave
                                forever
                              </small>
                            </td>
                            <td className="text-right">
                              <span className="mono">$1,000.00</span>
                              <br />
                              <small className="text-muted">Before Tax</small>
                            </td>
                            <td className="text-right">
                              <span className="mono">$18,000.00</span>
                              <br />
                              <small className="text-muted">18 Units</small>
                            </td>
                            <td className="text-right">
                              <span className="mono">- $1,800.00</span>
                              <br />
                              <small className="text-muted">Special -10%</small>
                            </td>
                            <td className="text-right">
                              <span className="mono">+ $3,240.00</span>
                              <br />
                              <small className="text-muted">VAT 20%</small>
                            </td>
                            <td className="text-right">
                              <strong className="mono">$19,440.00</strong>
                              <br />
                              <small className="text-muted mono">
                                $16,200.00
                              </small>
                            </td>
                          </tr>
                          <tr>
                            <td className="pl-10">
                              Sit Amet Dolo
                              <br />
                              <small className="text-muted">
                                Now you may sit
                              </small>
                            </td>
                            <td className="text-right">
                              <span className="mono">$120.00</span>
                              <br />
                              <small className="text-muted">Before Tax</small>
                            </td>
                            <td className="text-right">
                              <span className="mono">$240.00</span>
                              <br />
                              <small className="text-muted">2 Units</small>
                            </td>
                            <td className="text-right">
                              <span className="mono">- $0.00</span>
                              <br />
                              <small className="text-muted">-</small>
                            </td>
                            <td className="text-right">
                              <span className="mono">+ $72.00</span>
                              <br />
                              <small className="text-muted">
                                VAT:20% S:10%
                              </small>
                            </td>
                            <td className="text-right">
                              <strong className="mono">$312.00</strong>
                              <br />
                              <small className="text-muted mono">$240.00</small>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                    <div className="panel panel-default">
                      <table className="table table-bordered table-condensed">
                        <thead>
                          <tr>
                            <td className="text-center col-xs-1">Sub Total</td>
                            <td className="text-center col-xs-1">Discount</td>
                            <td className="text-center col-xs-1">Total</td>
                            <td className="text-center col-xs-1">Tax</td>
                            <td className="text-center col-xs-1">Final</td>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <th className="text-center rowtotal mono">
                              $18,240.00
                            </th>
                            <th className="text-center rowtotal mono">
                              -$1,800.00
                            </th>
                            <th className="text-center rowtotal mono">
                              $16,440.00
                            </th>
                            <th className="text-center rowtotal mono">
                              $3,312.00
                            </th>
                            <th className="text-center rowtotal mono">
                              $19,752.00
                            </th>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                    <div className="row">
                      <div className="col-md-12">
                        <div className="panel panel-default">
                          <div className="panel-body">
                            <i>Comments / Notes</i>
                            <hr style={{ margin: "3px 0 5px" }} />
                            Lorem ipsum dolor sit amet, consectetur adipisicing
                            elit. Odit repudiandae numquam sit facere
                            blanditiis, quasi distinctio ipsam? Libero odit ex
                            expedita, facere sunt, possimus consectetur dolore,
                            nobis iure amet vero.
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="invoice-footer">
                    Thank you for choosing our services.
                    <br /> We hope to see you again soon
                    <br />
                    <strong>~ACME~</strong>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
