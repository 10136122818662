import React, { Component } from "react";
import GoogleMapReact from "google-map-react";

const K_WIDTH = 2;
const K_HEIGHT = 2;

const greatPlaceStyle = {
  // initially any map object has left top corner at lat lng coordinates
  // it's on you to set object origin to 0,0 coordinates
  position: "absolute",
  width: K_WIDTH,
  height: K_HEIGHT,
  left: -K_WIDTH / 2,
  top: -K_HEIGHT / 2,

  border: "2px solid #38bdf8",
  borderRadius: K_HEIGHT * 2,
  backgroundColor: "#38bdf8",
  textAlign: "center",
  color: "#3f51b5",
  fontSize: 2,
  fontWeight: "bold",
  padding:"1px",
};

const AnyReactComponent = ({ text }) => (
  <div style={greatPlaceStyle}></div>
);

// class SimpleMap extends Component {
//   static defaultProps = {
//     center: {
//       lat: 59.95,
//       lng: 30.33
//     },
//     zoom: 11
//   };

//   render() {
//     return (
//       // Important! Always set the container height explicitly
//       <div style={{ height: '100vh', width: '100%' }}>
//         <GoogleMapReact
//           bootstrapURLKeys={{ key: /* YOUR KEY HERE */ }}
//           defaultCenter={this.props.center}
//           defaultZoom={this.props.zoom}
//         >
//           <AnyReactComponent
//             lat={59.955413}
//             lng={30.337844}
//             text="My Marker"
//           />
//         </GoogleMapReact>
//       </div>
//     );
//   }
// }

function SimpleMap({ data }) {
  console.log(data, "SimpleMap");
  const defaultProps = {
    center: {
      lat: 27.871936699079576,
      lng: 82.26328660718491,
    },
    zoom: 11,
  };
  return (
    <div style={{ height: "100vh", width: "100%" }}>
      <GoogleMapReact
        //   bootstrapURLKeys={{ key: /* YOUR KEY HERE */ }}
        defaultCenter={defaultProps.center}
        defaultZoom={defaultProps.zoom}
      >
        {data.map((d) => {
          const { longitude, latitude, firstname } = d;
          return (
            <AnyReactComponent
              lat={latitude}
              lng={longitude}
              text={firstname}
            />
          );
        })}
      </GoogleMapReact>
    </div>
  );
}

export default SimpleMap;
