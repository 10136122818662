import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchCensus,
  removeCensus,
} from "./../../../redux/actions/census.action";
import ReactPaginate from "react-paginate";
import { Link } from "react-router-dom";
import Skeleton from "react-loading-skeleton";
import getFirstCharacters from "../../../utils/getFirstCharacters";
export default function CensusTable() {
  const { census, total, loading } = useSelector((state) => state.census);

  const [perPage, setPerPage] = useState(8);
  const [currentPage, setCurrentPage] = useState(0);
  const dispatch = useDispatch();
  const [delId, setDelId] = useState(0);

  useEffect(() => {
    dispatch(
      fetchCensus({
        perPage: perPage,
        currentPage: currentPage,
      })
    );
  }, [dispatch]);

  const handlePageClick = (event) => {
    setCurrentPage(event.selected);

    dispatch(
      fetchCensus({
        perPage: perPage,
        currentPage: event.selected,
      })
    );
  };

  function onCensusDelete() {
    dispatch(removeCensus(delId));
  }

  const pageCount = Math.ceil(total ? total / perPage : 0);
  return (
    <div className="row">
      <div className="col-md-12">
        {" "}
        <table id="patientList" className="table table-hover mt-05">
          <thead>
            <tr>
              <th
                className="bt-0 mb-15 bb-0 font-12"
                scope="col"
                width="5%"
              ></th>
              <th className="bt-0 mb-15 bb-0 font-12" scope="col" width="15%">
                Census ID.
              </th>
              <th className="bt-0 mb-15 bb-0 font-12" scope="col" width="25%">
                Basic Info
              </th>
              <th className="bt-0 mb-15 bb-0 font-12" scope="col" width="15%">
                Municipality
              </th>
              <th className="bt-0 mb-15 bb-0 font-12" scope="col" width="15%">
                Village
              </th>
              <th className="bt-0 mb-15 bb-0 font-12" scope="col" width="15%">
                Gender
              </th>
              <th
                className="bt-0 mb-15 bb-0 font-12"
                scope="col"
                width="10%"
              ></th>
            </tr>
          </thead>
          {loading ? (
            new Array(7).fill("", 0, 7).map((i, index) => (
              <tbody id="patient-list" key={index}>
                <tr>
                  <td className="text-center bl-20">
                    <Skeleton width={20} />
                  </td>
                  <td>
                    <Skeleton width={50} />
                  </td>
                  <td>
                    <div className="row pt-10 pb-10">
                      <div className="col-md-2">
                        <Skeleton height={45} width={45} borderRadius={50} />
                      </div>
                      <div className="col-md-10">
                        <Skeleton width={200} />
                        <p className="mb-0 text-mute">
                          <Skeleton width={150} />
                        </p>
                      </div>
                    </div>
                  </td>
                  <td>
                    <Skeleton width={150} />
                  </td>
                  <td>
                    <Skeleton width={150} />
                  </td>
                  <td>
                    <Skeleton width={150} />
                  </td>
                  <td className="br-20">
                    <Skeleton width={25} />
                  </td>
                </tr>
              </tbody>
            ))
          ) : (
            <tbody id="patient-list">
              {census?.map((item) => {
                return (
                  <tr key={item.residentId}>
                    <td className="text-center bl-20">
                      <div className="custom-control custom-checkbox pl-40">
                        <input
                          type="checkbox"
                          className="custom-control-input"
                          id="customCheck1"
                        />
                        <label
                          className="custom-control-label mt--20"
                          htmlFor="customCheck1"
                        ></label>
                      </div>
                    </td>
                    <td>{item.residentId}</td>
                    <td>
                      <div className="row pt-10 pb-10">
                        <div className="col-md-2">
                          {item.image ? (
                            <img
                              width="42"
                              className="rounded-circle"
                              src={item?.image}
                              alt={item?.patientFullName}
                            />
                          ) : (
                            <div
                              className="circle rounded-circle"
                              style={{ margin: "auto" }}
                            >
                              <span className="initials">
                                {getFirstCharacters(
                                  item.resident.resident_first_name +
                                    " " +
                                    item.resident.resident_last_name +
                                    " " +
                                    item.resident.resident_middle_name
                                )}
                              </span>
                            </div>
                          )}
                        </div>
                        <div className="col-md-10">
                          <Link to={`census_detail/${item.residentId}`}>
                            <p className="mb-0 bold">
                              {item.resident.resident_first_name +
                                " " +
                                item.resident.resident_last_name +
                                " " +
                                item.resident.resident_middle_name}
                            </p>
                          </Link>
                        </div>
                      </div>
                    </td>
                    <td>{item.residentAddress.resident_city}</td>
                    <td>{item.residentAddress.resident_village}</td>
                    <td>{item.residentDetail.resident_gender}</td>
                    <td className="br-20">
                      <div className="dropdown dropleft d-inline-block mt-10">
                        <button
                          type="button"
                          aria-haspopup="true"
                          aria-expanded="false"
                          data-toggle="dropdown"
                          className="mb-2 mr-2 btn"
                        >
                          <i className="fas fa-ellipsis-h"></i>
                        </button>
                        <div
                          tabIndex="-1"
                          role="menu"
                          aria-hidden="true"
                          className="dropdown-menu"
                        >
                          <Link to={`update/${item.residentId}`}>
                            <button
                              type="button"
                              tabIndex="0"
                              className="dropdown-item"
                            >
                              <i className="fas fa-edit mr-2 text-mute"></i>
                              Edit Census
                            </button>
                          </Link>
                          <button
                            type="button"
                            tabIndex="0"
                            className="dropdown-item"
                            data-toggle="modal"
                            data-target="#deleteModal_11"
                            onClick={() => setDelId(item.residentId)}
                          >
                            <i className="fas fa-trash mr-2 text-mute"></i>
                            Delete Census
                          </button>
                        </div>
                      </div>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          )}
        </table>
        <div className="row col-sm-6 text-right">
          <ReactPaginate
            previousLabel={"← Previous"}
            nextLabel={"Next →"}
            breakLabel="..."
            pageCount={pageCount}
            onPageChange={handlePageClick}
            containerClassName={"pagination"}
            previousLinkClassName={"pagination__link"}
            nextLinkClassName={"pagination__link"}
            disabledClassName={"disabled"}
            activeClassName={"active"}
            // renderOnZeroPageCount={"<h43>Loading</h43>"}
          />
        </div>
      </div>
      <div
        className="modal fade"
        id="deleteModal_11"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLabel">
                Delete Census
              </h5>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">×</span>
              </button>
            </div>

            <div className="modal-body">
              <p className="mb-0">
                Are you sure you want to delete this census?
              </p>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-secondary"
                data-dismiss="modal"
              >
                Cancel
              </button>
              <input
                type="submit"
                id="submit"
                onClick={() => onCensusDelete()}
                className="btn btn-primary"
                value="Confirm"
                data-dismiss="modal"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
