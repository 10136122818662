import React, { useEffect } from "react";
import logoInverse from "./../../assets/images/logo/logo-inverse.png";
import { useForm } from "react-hook-form";
import { login } from "../../redux/actions/auth.action";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

export default function Login() {
  const { isLoggedIn, loading } = useSelector((store) => store.auth);
  let navigate = useNavigate();
  useEffect(() => {
    if (isLoggedIn) {
      navigate("/");
    }
  }, [isLoggedIn]);

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const dispatch = useDispatch();

  const onSubmit = async (data) => {
    dispatch(login(data));
  };

  let errorStyle = {
    borderWidth: "1px 1px 1px 10px",
    borderStyle: "solid",
    borderColor:
      "rgb(191, 22, 80) rgb(191, 22, 80) rgb(191, 22, 80) rgb(236, 89, 144)",
    borderImage: "initial",
    background: "rgb(251, 236, 242)",
  };

  let Btn = () =>
    loading ? (
      <button className="btn-login" disabled>
        Logging
      </button>
    ) : (
      <button className="btn-login">Login</button>
    );

  return (
    <div className="app-container app-theme-white body-tabs-shadow">
      <div className="app-container">
        <div className="h-100">
          <div className="h-100 no-gutters row">
            <div className="d-none d-lg-block col-lg-4">
              <div
                className="position-relative h-100 d-flex justify-content-center align-items-center background-login"
                style={{ zIndex: 2 }}
              >
                <div
                  style={{
                    padding: "30px",
                    color: "white",
                    zIndex: 10,
                    zIndex: 9,
                    opacity: 1,
                  }}
                >
                  <h3 style={{ fontWeight: 700, paddingTop: "300px" }}>
                    Perfect Balance
                  </h3>
                  <p style={{ fontWeight: 600 }}>
                    ArchitectUI is like a dream. Some think it's too good to be
                    true! Extensive collection of unified React Boostrap
                    Components and Elements.
                  </p>
                </div>
              </div>
            </div>
            <div className="h-100 d-flex bg-white justify-content-center align-items-center col-md-12 col-lg-8">
              <div className="mx-auto app-login-box col-sm-12 col-md-10 col-lg-9">
                <div className="app-logo">
                  <img
                    style={{ width: "200px", paddingBottom: "40px" }}
                    src={logoInverse}
                  />
                </div>
                <h4 className="mb-0">
                  <span
                    className="d-block"
                    style={{
                      color: "#3498DB",
                      fontWeight: 700,
                      paddingBottom: "10px",
                      fontSize: "22px",
                    }}
                  >
                    Welcome back,
                  </span>
                  <span
                    style={{
                      fontSize: "16px",
                      lineHeight: "0.9",
                      color: "#99A3A4",
                    }}
                  >
                    Please sign in to your account.
                  </span>
                </h4>

                <div className="divider row"></div>
                <div>
                  <form onSubmit={handleSubmit(onSubmit)}>
                    <div className="form-row">
                      <div className="col-md-6">
                        <div className="position-relative form-group">
                          <label htmlFor="examplePhone" className="">
                            Phone
                          </label>
                          <input
                            name="phone"
                            id="examplePhone"
                            placeholder="Phone here..."
                            type="number"
                            className="form-control"
                            {...register("phone", {
                              required: true,
                              required: "Phone is required",
                            })}
                            style={errors.phone ? errorStyle : {}}
                          />{" "}
                          {errors.phone && (
                            <span className="errorText">
                              {errors.phone?.message}
                            </span>
                          )}
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="position-relative form-group">
                          <label htmlFor="examplePassword" className="">
                            Password
                          </label>
                          <input
                            name="password"
                            id="examplePassword"
                            placeholder="Password here..."
                            type="password"
                            className="form-control"
                            {...register("password", {
                              required: "Password is required",
                            })}
                            style={errors.password ? errorStyle : {}}
                          />{" "}
                          {errors.password && (
                            <span className="errorText">
                              {errors.password?.message}
                            </span>
                          )}
                        </div>
                      </div>
                    </div>
                    <div className="position-relative form-check">
                      <input
                        name="check"
                        id="exampleCheck"
                        type="checkbox"
                        className="form-check-input"
                      />
                      <label
                        htmlFor="exampleCheck"
                        className="form-check-label"
                      >
                        Keep me logged in
                      </label>
                    </div>
                    <div className="divider row"></div>
                    <div className="d-flex align-items-center">
                      <div className="ml-auto">
                        <a
                          //href="javascript:void(0);"
                          className="btn-lg btn btn-link"
                        >
                          Recover Password
                        </a>
                        <Btn />
                      </div>
                    </div>
                  </form>
                </div>
                <div className="text-mute btr font-small">
                  &#169; 2018 All Rights Reserved. Ayata Inc. Cookie
                  Preferences, Privacy and Terms.
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
