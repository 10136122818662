import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import TopHeader from "./TopHeader";
import Nav from "./Nav";

const ProtectedLayouts = () => {
  const { isLoggedIn } = useSelector((store) => store.auth);
  let navigate = useNavigate();
  useEffect(() => {
    if (!isLoggedIn) {
      navigate("/login");
    }
  }, [isLoggedIn]);
  const [isOpen, setIsOpen] = useState(false);
  const mobileMenuToggle = () => {
    if (isOpen) {
      setIsOpen(false);
      document
        .querySelector(".app-container")
        .classList.remove("sidebar-mobile-open");
      document
        .querySelector(".mobile-toggle-nav")
        .classList.remove("is-active");
    } else {
      setIsOpen(true);
      document
        .querySelector(".app-container")
        .classList.add("sidebar-mobile-open");
      document.querySelector(".mobile-toggle-nav").classList.add("is-active");
    }
  };
  return (
    <div className="app-container app-theme-white fixed-sidebar closed-sidebar closed-sidebar-mobile ">
      <TopHeader
        mobileMenuToggle={mobileMenuToggle}
        mobileMenuToggleIsOpen={isOpen}
      />
      <Nav />
    </div>
  );
};

export default ProtectedLayouts;
