import {
  LOGOUT_USER,
  CENSUS_FETCH,
  CENSUS_FETCHED,
  CENSUS_SINGLE_FETCH,
  CENSUS_SINGLE_FETCHED,
  CENSUS_DELETED,
  CENSUS_UPDATE,
  CENSUS_UPDATE_SUCCESS,
  CENSUS_UPDATE_FAILURE,
} from "../constants/types";
import http from "../../utils/http";
import toast from "../../utils/toast";

export const fetchCensus =
  ({ perPage, currentPage }) =>
  (dispatch) => {
    dispatch({ type: CENSUS_FETCH });
    currentPage += 1;
    http
      .GET("Census/get", { perPage, currentPage })
      .then((res) => {
        dispatch({ type: CENSUS_FETCHED, payload: res.data });
      })
      .catch((error) => {
        console.log(error.response.data?.code);
        if (error.response.data.code === "401") dispatch({ type: LOGOUT_USER });
        toast.error(error);
      });
  };
export const fetchCensusSingle = (id, update) => (dispatch) => {
  dispatch({ type: CENSUS_SINGLE_FETCH });

  http
    .GET("Census/details/" + id, { update })
    .then((res) => {
      dispatch({ type: CENSUS_SINGLE_FETCHED, payload: res.data });
    })
    .catch((error) => {
      console.log(error);
      if (error.response.data.code === "401") dispatch({ type: LOGOUT_USER });
      toast.error(error);
    });
};

export const removeCensus = (id) => (dispatch) => {
  http
    .REMOVE("Census/delete/" + id)
    .then((res) => {
      toast.success(res.data.message);
      dispatch({ type: CENSUS_DELETED, payload: id });
    })
    .catch((error) => {
      console.log(error.response.data?.code);
      if (error.response.data.code === "401") dispatch({ type: LOGOUT_USER });
      toast.error(error);
    });
};

export const updateCensus = (data) => (dispatch) => {
  dispatch({ type: CENSUS_UPDATE });
  http
    .PUT("/Census/update/" + data.residentId, data)
    .then((res) => {
      dispatch({ type: CENSUS_UPDATE_SUCCESS, payload: res.data });
      toast.success("Census Updated Successfully.");
    })
    .catch((error) => {
      if (error.response.data.code === "401") dispatch({ type: LOGOUT_USER });
      toast.error(error);
      dispatch({ type: CENSUS_UPDATE_FAILURE });
    });
};
