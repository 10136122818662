import {
  INFANT_DELETED,
  INFANT_FETCH,
  INFANT_FETCHED,
  INFANT_SINGLE_FETCH,
  INFANT_SINGLE_FETCHED,
  INFANT_UPDATE,
  INFANT_UPDATE_FAILURE,
  INFANT_UPDATE_SUCCESS,
  LOGOUT_USER,
} from "../constants/types";
import http from "../../utils/http";
import toast from "../../utils/toast";

export const fetchInfants =
  ({ perPage, currentPage }) =>
  (dispatch) => {
    dispatch({ type: INFANT_FETCH });
    currentPage += 1;
    http
      .GET("Infant/get", { perPage, currentPage })
      .then((res) => {
        dispatch({ type: INFANT_FETCHED, payload: res.data });
      })
      .catch((error) => {
        console.log(error.response.data.code);
        if (error.response.data.code === "401") dispatch({ type: LOGOUT_USER });
        toast.error(error);
      });
  };

export const fetchInfantSingle = (id) => (dispatch) => {
  dispatch({ type: INFANT_SINGLE_FETCH });
  http
    .GET("Infant/details/" + id)
    .then((res) => {
      dispatch({ type: INFANT_SINGLE_FETCHED, payload: res.data });
    })
    .catch((error) => {
      console.log(error.response.data.code);
      if (error.response.data.code === "401") dispatch({ type: LOGOUT_USER });
      toast.error(error);
    });
};

export const removeInfant = (id) => (dispatch) => {
  http
    .REMOVE("Infant/delete/" + id)
    .then((res) => {
      toast.success(res.data.message);
      dispatch({ type: INFANT_DELETED, payload: id });
    })
    .catch((error) => {
      console.log(error.response.data?.code);
      if (error.response.data.code === "401") dispatch({ type: LOGOUT_USER });
      toast.error(error);
    });
};

export const updateInfant = (data) => (dispatch) => {
  dispatch({ type: INFANT_UPDATE });
  http
    .PUT("/Infant/update/" + data.infantId, data)
    .then((res) => {
      dispatch({ type: INFANT_UPDATE_SUCCESS, payload: res.data });
      toast.success("Infant Updated Successfully.");
    })
    .catch((error) => {
      if (error.response.data.code === "401") dispatch({ type: LOGOUT_USER });
      toast.error(error);
      dispatch({ type: INFANT_UPDATE_FAILURE });
    });
};
