import React from "react";

export default function Payment() {
  return (
    <div className="app-main__inner">
      <div className="app-page-title pt-10">
        <div className="page-title-wrapper">
          <div className="page-title-heading">
            <div className="card-body p-0">
              <div className="mr-2 btn-group">
                <h5 className="page-title">Payment Information</h5>
              </div>
            </div>
          </div>
          <div className="page-title-actions pt-20">
            <div className="d-inline-block">
              <a className="mb-2 mr-2 btn btn-light btn-round">
                <span className="btn-icon-wrapper opacity-7">
                  <i className="fas fa-bell mt-06"></i>
                </span>
              </a>
            </div>
            <div className="d-inline-block">
              <a
                href="./patient_add.php"
                className="mb-2 mr-2 btn btn-primary color-white"
              >
                <span className="btn-icon-wrapper pr-2 opacity-7">
                  <i className="fas fa-plus"></i>
                </span>
                Add New Patient
              </a>
            </div>
          </div>
        </div>
      </div>

      <div className="row">
        <div className="col-md-7">
          <h6 className="bold">Recent Payout</h6>
          <table className="table first-table table-hover mt-20">
            <thead>
              <tr>
                <th className="bt-0 mb-15 bb-0 font-12 pl-30" scope="col">
                  Date
                </th>
                <th className="bt-0 mb-15 bb-0 font-12" scope="col">
                  Payment
                </th>
                <th className="bt-0 mb-15 bb-0 font-12" scope="col">
                  Method
                </th>
                <th className="bt-0 mb-15 bb-0 font-12" scope="col">
                  Notes
                </th>
                <th className="bt-0 mb-15 bb-0 font-12" scope="col">
                  Amount
                </th>
              </tr>
            </thead>
            <tbody id="patient-list">
              <tr>
                <td className="bl-10 pl-30">24 Feb 2020</td>
                <td>
                  <div className="badge badge-warning">Pending</div>
                </td>
                <td>Cash</td>
                <td>SLV98213BS</td>
                <td className="br-10">Rs. 250</td>
              </tr>
              <tr>
                <td className="bl-10 pl-30">24 Feb 2020</td>
                <td>
                  <div className="badge badge-success">Completed</div>
                </td>
                <td>Cash</td>
                <td>SLV98213BS</td>
                <td className="br-10">Rs. 250</td>
              </tr>
              <tr>
                <td className="bl-10 pl-30">24 Feb 2020</td>
                <td>
                  <div className="badge badge-success">Completed</div>
                </td>
                <td>Cash</td>
                <td>SLV98213BS</td>
                <td className="br-10">Rs. 250</td>
              </tr>
            </tbody>
          </table>

          <h6 className="bold">Recent Treatment</h6>
          <table className="table table-hover mt-20">
            <tbody id="patient-list">
              <tr>
                <td className="bl-10">
                  <div className="row pt-10 pb-10 pl-30">
                    <div className="col-md-2">
                      <img
                        width="42"
                        className="rounded-circle"
                        src="./assets/images/avatars/1.png"
                        alt=""
                      />
                    </div>
                    <div className="col-md-10">
                      <a href="./gdm_new.php">
                        <p className="mb-0 bold">Medicord Admin</p>
                        <p className="mb-0 text-mute">ayatadev@gmail.com</p>
                      </a>
                    </div>
                  </div>
                </td>
                <td>Rs. 250</td>
                <td>24 Feb 2020</td>
                <td className="br-10">
                  <div className="dropdown dropleft d-inline-block mt-10">
                    <button
                      type="button"
                      aria-haspopup="true"
                      aria-expanded="false"
                      data-toggle="dropdown"
                      className="mb-2 mr-2 btn"
                    >
                      <i className="fas fa-ellipsis-h"></i>
                    </button>
                    <div
                      tabindex="-1"
                      role="menu"
                      aria-hidden="true"
                      className="dropdown-menu"
                    >
                      <button
                        type="button"
                        tabindex="0"
                        className="dropdown-item"
                      >
                        <i className="fas fa-edit mr-2 text-mute"></i>
                        Edit Patient
                      </button>
                      <button
                        type="button"
                        tabindex="0"
                        className="dropdown-item"
                      >
                        <i className="fas fa-trash mr-2 text-mute"></i>
                        Delete Patient
                      </button>
                    </div>
                  </div>
                </td>
              </tr>
              <tr>
                <td className="bl-10">
                  <div className="row pt-10 pb-10 pl-30">
                    <div className="col-md-2">
                      <img
                        width="42"
                        className="rounded-circle"
                        src="./assets/images/avatars/2.png"
                        alt=""
                      />
                    </div>
                    <div className="col-md-10">
                      <a href="./gdm_new.php">
                        <p className="mb-0 bold">Medicord Admin</p>
                        <p className="mb-0 text-mute">ideau98@gmail.com</p>
                      </a>
                    </div>
                  </div>
                </td>
                <td>Rs. 250</td>
                <td>24 Feb 2020</td>
                <td className="br-10">
                  <div className="dropdown dropleft d-inline-block mt-10">
                    <button
                      type="button"
                      aria-haspopup="true"
                      aria-expanded="false"
                      data-toggle="dropdown"
                      className="mb-2 mr-2 btn"
                    >
                      <i className="fas fa-ellipsis-h"></i>
                    </button>
                    <div
                      tabindex="-1"
                      role="menu"
                      aria-hidden="true"
                      className="dropdown-menu"
                    >
                      <button
                        type="button"
                        tabindex="0"
                        className="dropdown-item"
                      >
                        <i className="fas fa-edit mr-2 text-mute"></i>
                        Edit Patient
                      </button>
                      <button
                        type="button"
                        tabindex="0"
                        className="dropdown-item"
                      >
                        <i className="fas fa-trash mr-2 text-mute"></i>
                        Delete Patient
                      </button>
                    </div>
                  </div>
                </td>
              </tr>
              <tr>
                <td className="bl-10">
                  <div className="row pt-10 pb-10 pl-30">
                    <div className="col-md-2">
                      <img
                        width="42"
                        className="rounded-circle"
                        src="./assets/images/avatars/3.png"
                        alt=""
                      />
                    </div>
                    <div className="col-md-10">
                      <a href="./gdm_new.php">
                        <p className="mb-0 bold">Medicord Admin</p>
                        <p className="mb-0 text-mute">ideau98@gmail.com</p>
                      </a>
                    </div>
                  </div>
                </td>
                <td>Rs. 250</td>
                <td>24 Feb 2020</td>
                <td className="br-10">
                  <div className="dropdown dropleft d-inline-block mt-10">
                    <button
                      type="button"
                      aria-haspopup="true"
                      aria-expanded="false"
                      data-toggle="dropdown"
                      className="mb-2 mr-2 btn"
                    >
                      <i className="fas fa-ellipsis-h"></i>
                    </button>
                    <div
                      tabindex="-1"
                      role="menu"
                      aria-hidden="true"
                      className="dropdown-menu"
                    >
                      <button
                        type="button"
                        tabindex="0"
                        className="dropdown-item"
                      >
                        <i className="fas fa-edit mr-2 text-mute"></i>
                        Edit Patient
                      </button>
                      <button
                        type="button"
                        tabindex="0"
                        className="dropdown-item"
                      >
                        <i className="fas fa-trash mr-2 text-mute"></i>
                        Delete Patient
                      </button>
                    </div>
                  </div>
                </td>
              </tr>
              <tr>
                <td className="bl-10">
                  <div className="row pt-10 pb-10 pl-30">
                    <div className="col-md-2">
                      <img
                        width="42"
                        className="rounded-circle"
                        src="./assets/images/avatars/4.png"
                        alt=""
                      />
                    </div>
                    <div className="col-md-10">
                      <a href="./gdm_new.php">
                        <p className="mb-0 bold">Medicord Admin</p>
                        <p className="mb-0 text-mute">ideau98@gmail.com</p>
                      </a>
                    </div>
                  </div>
                </td>
                <td>Rs. 250</td>
                <td>24 Feb 2020</td>
                <td className="br-10">
                  <div className="dropdown dropleft d-inline-block mt-10">
                    <button
                      type="button"
                      aria-haspopup="true"
                      aria-expanded="false"
                      data-toggle="dropdown"
                      className="mb-2 mr-2 btn"
                    >
                      <i className="fas fa-ellipsis-h"></i>
                    </button>
                    <div
                      tabindex="-1"
                      role="menu"
                      aria-hidden="true"
                      className="dropdown-menu"
                    >
                      <button
                        type="button"
                        tabindex="0"
                        className="dropdown-item"
                      >
                        <i className="fas fa-edit mr-2 text-mute"></i>
                        Edit Patient
                      </button>
                      <button
                        type="button"
                        tabindex="0"
                        className="dropdown-item"
                      >
                        <i className="fas fa-trash mr-2 text-mute"></i>
                        Delete Patient
                      </button>
                    </div>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div className="col-md-5">
          <div className="card mb-3 h-450 bg-plot">
            <h6 className="p-20 bold pb-10 text-white">Earning Statistics</h6>
            <figure className="highcharts-figure pl-10">
              <div id="earning"></div>
            </figure>
          </div>

          <div className="row">
            <div className="col-md-6 pr-0">
              <div className="card p-20">
                <h6 className=" bold">Total Patient</h6>
                <h1 className="bold font-28 text-mute">103</h1>
                <p className="font-12 text-mute mb-05 pb-06">
                  This Month So Far
                </p>
                <a className="mr-2 btn btn-light btn-more w-200">
                  <span className="btn-icon-wrapper pr-2 opacity-7">
                    <i className="fas fa-arrow-right"></i>
                  </span>
                  View More
                </a>
              </div>
            </div>
            <div className="col-md-6">
              <div className="card p-20">
                <h6 className=" bold">Total Earning</h6>
                <h1 className="bold font-28 text-mute">Rs. 230,000</h1>
                <p className="font-12 text-mute mb-05 pb-06">
                  This Month So Far
                </p>
                <a className="mr-2 btn btn-light btn-more w-200">
                  <span className="btn-icon-wrapper pr-2 opacity-7">
                    <i className="fas fa-arrow-right"></i>
                  </span>
                  View More
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
