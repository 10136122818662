/**
 *
 * @param {json} data - data to download
 * @param {string} csvFileName -filename
 */
const downloadCSV = (data, csvFileName) => {
  const blob = new Blob([data], { type: "text/csv" });
  const url = window.URL.createObjectURL(blob);
  const a = document.createElement("a");
  a.setAttribute("hidden", "");
  a.setAttribute("href", url);
  a.setAttribute("download", `${csvFileName}.csv`);
  document.body.appendChild(a);
  a.click();
  document.body.removeChild(a);
};

export default downloadCSV;
