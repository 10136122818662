import {
  DOCTOR_CHANGE_PASSWORD,
  DOCTOR_CHANGE_PASSWORD_FAILURE,
  DOCTOR_CHANGE_PASSWORD_SUCCESS,
  DOCTOR_INFO_UPDATE,
  DOCTOR_INFO_UPDATE_FAILURE,
  DOCTOR_INFO_UPDATE_SUCCESS,
  DOCTOR_SINGLE_FETCH,
  DOCTOR_SINGLE_FETCHED,
} from "../constants/types";

const initialValue = {
  doctor: {},
  loading: false,
  updating: false,
};

function reducer(state = initialValue, action) {
  switch (action.type) {
    case DOCTOR_SINGLE_FETCH:
      return {
        loading: true,
      };
    case DOCTOR_SINGLE_FETCHED:
      return {
        ...state,
        loading: false,
        doctor: action.payload.details,
      };
    case DOCTOR_INFO_UPDATE:
      return {
        updating: true,
      };
    case DOCTOR_INFO_UPDATE_SUCCESS:
      return {
        ...state,
        updating: false,
        doctor: action.payload.details,
      };
    case DOCTOR_INFO_UPDATE_FAILURE:
      return {
        updating: false,
      };
    case DOCTOR_CHANGE_PASSWORD:
      return {
        updating: true,
      };
    case DOCTOR_CHANGE_PASSWORD_SUCCESS:
      return {
        ...state,
        updating: false,
      };
    case DOCTOR_CHANGE_PASSWORD_FAILURE:
      return {
        updating: false,
      };
    default:
      return {
        ...state,
      };
  }
}

export default reducer;
