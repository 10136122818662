import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { fetchCensusSingle } from "../../../redux/actions/census.action";
import { useParams } from "react-router-dom";
import bwipjs from "bwip-js";
import "./../../../assets/css/patientDetail.css";
import Skeleton from "react-loading-skeleton";
import getFirstCharacters from "../../../utils/getFirstCharacters";
export default function CDetails() {
  const { singleCensus, loading } = useSelector((state) => state.census);
  const { id } = useParams();
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(fetchCensusSingle(id));
  }, [dispatch]);

  useEffect(() => {
    bwipjs.toCanvas("mycanvas", {
      bcid: "code39", // Barcode type
      text: singleCensus?.residentAssignId || "XXXXXXX", // Text to encode
      scale: 3, // 3x scaling factor
      height: 10, // Bar height, in millimeters
      includetext: true, // Show human-readable text
      textxalign: "center", // Always good to set this
    });
  }, [singleCensus]);

  return (
    <>
      <div className="app-main__inner">
        <div className="app-page-title pt-0 pb-0">
          <div className="page-title-wrapper">
            <div className="page-title-heading">
              <div className="card-body p-0">
                <ul className="body-tabs body-tabs-layout tabs-animated body-tabs-animated nav">
                  <li className="nav-item">
                    <h5
                      style={{
                        marginTop: "16px",
                        fontSize: "18px",
                        fontWeight: "700",
                        paddingBottom: "10px",
                      }}
                    >
                      <Link to="/census">Census</Link> &emsp;
                      <i className="fas fa-angle-right"></i> &emsp;
                    </h5>
                  </li>
                  <li className="nav-item">
                    <a
                      role="tab"
                      className="nav-link active font-small-14 bold"
                      id="tab-0"
                      data-toggle="tab"
                      href="#patient-overview"
                    >
                      <span>Overview</span>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>

        <div
          className="tab-pane tabs-animation fade show active"
          id="patient-overview"
          role="tabpanel"
        >
          {loading ? (
            <div className="row">
              <div className="col-md-8 pr-0">
                <div className="row">
                  <div className="col-md-4 pr-05">
                    <div className="main-card mb-3 card min-h-300">
                      <div className="card-body text-center pb-16">
                        <Skeleton height={45} width={45} borderRadius={50} />
                        <div className="card-body pb-0 pl-0 pr-0">
                          <h5 className="bold mb-0">
                            <Skeleton width={150} />
                          </h5>

                          <table className="mb-0 table table-borderless mt-5">
                            <tbody>
                              <tr>
                                <td
                                  width="50%"
                                  style={{ borderRight: "2px solid #E5E8E8" }}
                                >
                                  <h5 className="bold card-title">
                                    <Skeleton width={50} />
                                  </h5>
                                  <p className="text-mute font-small mt--10 mb-0">
                                    <Skeleton width={50} />
                                  </p>
                                </td>
                                <td width="50%">
                                  <h5 className="bold card-title">
                                    <Skeleton width={50} />
                                  </h5>
                                  <p className="text-mute font-small mt--10 mb-0">
                                    <Skeleton width={50} />
                                  </p>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                          <a
                            className="btn-transition btn btn-outline-light btn-lg btn-more btn-block"
                            style={{ marginTop: "20px" }}
                          >
                            <Skeleton width={50} />
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-8 pl-0">
                    <div className="main-card mb-3 card min-h-300">
                      <div className="card-body">
                        <h5 className="card-title">
                          <Skeleton width={120} />
                        </h5>
                        <table className="mb-0 table table-borderless">
                          <tbody>
                            <tr>
                              <td>
                                <p className="bold">
                                  <Skeleton width={50} />
                                </p>
                                <p className="text-mute font-12 mt--10">
                                  <Skeleton width={50} />
                                </p>
                              </td>
                              <td>
                                <p className="bold">
                                  <Skeleton width={50} />
                                </p>
                                <p className="text-mute font-12 mt--10">
                                  <Skeleton width={50} />
                                </p>
                              </td>
                              <td>
                                <p className="bold">
                                  <Skeleton width={150} />
                                </p>
                                <p className="text-mute font-12 mt--10">
                                  <Skeleton width={50} />
                                </p>
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <p className="bold">
                                  <Skeleton width={150} />
                                </p>
                                <p className="text-mute font-12 mt--10">
                                  <Skeleton width={200} />
                                </p>
                              </td>
                              <td>
                                <p className="bold">
                                  <Skeleton width={50} />
                                </p>
                                <p className="text-mute font-12 mt--10">
                                  <Skeleton width={80} />
                                </p>
                              </td>
                              <td>
                                <p className="bold">
                                  <Skeleton width={80} />
                                </p>
                                <p className="text-mute font-12 mt--10">
                                  <Skeleton width={50} />
                                </p>
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <p className="bold">
                                  <Skeleton width={50} />
                                </p>
                                <p className="text-mute font-12 mt--10">
                                  <Skeleton width={80} />
                                </p>
                              </td>
                              <td>
                                <p className="bold">
                                  <Skeleton width={50} />
                                </p>
                                <p className="text-mute font-12 mt--10">
                                  <Skeleton width={80} />
                                </p>
                              </td>
                              <td>
                                <p className="bold">
                                  <Skeleton width={50} />
                                </p>
                                <p className="text-mute font-12 mt--10">
                                  <Skeleton width={50} />
                                </p>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="mb-3 card h-400 mt--10">
                  <div className="card-body">
                    <h5 className="card-title">
                      <Skeleton width={120} />
                    </h5>
                    <table className="mb-0 table table-borderless">
                      <tbody>
                        <tr>
                          <td>
                            <p className="bold">
                              <Skeleton width={80} />
                            </p>
                            <p className="text-mute font-12 mt--10">
                              <Skeleton width={50} />
                            </p>
                          </td>
                          <td>
                            <p className="bold">
                              <Skeleton width={80} />
                            </p>
                            <p className="text-mute font-12 mt--10">
                              <Skeleton width={50} />
                            </p>
                          </td>
                          <td>
                            <p className="bold">
                              <Skeleton width={80} />
                            </p>
                            <p className="text-mute font-12 mt--10">
                              <Skeleton width={50} />
                            </p>
                          </td>
                          <td>
                            <p className="bold">
                              <Skeleton width={80} />
                            </p>
                            <p className="text-mute font-12 mt--10">
                              <Skeleton width={50} />
                            </p>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <p className="bold">
                              <Skeleton width={80} />
                            </p>
                            <p className="text-mute font-12 mt--10">
                              <Skeleton width={50} />
                            </p>
                          </td>
                          <td>
                            <p className="bold">
                              <Skeleton width={80} />
                            </p>
                            <p className="text-mute font-12 mt--10">
                              <Skeleton width={50} />
                            </p>
                          </td>
                          <td>
                            <p className="bold">
                              <Skeleton width={80} />
                            </p>
                            <p className="text-mute font-12 mt--10">
                              <Skeleton width={50} />
                            </p>
                          </td>
                          <td>
                            <p className="bold">
                              <Skeleton width={80} />
                            </p>
                            <p className="text-mute font-12 mt--10">
                              <Skeleton width={50} />
                            </p>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
              <div className="col-md-4">
                <div className="main-card mb-3 card">
                  <div className="card-body">
                    <h5 className="card-title">
                      {" "}
                      <Skeleton width={80} />
                    </h5>
                    <div className="card-body p-0">
                      <Skeleton height={109} width={430} />{" "}
                      <canvas
                        id="mycanvas"
                        style={{ display: "none" }}
                      ></canvas>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <div className="row">
              <div className="col-md-8 pr-0">
                <div className="row">
                  <div className="col-md-4 pr-05">
                    <div className="main-card mb-3 card min-h-300">
                      <div className="card-body text-center pb-16">
                        {singleCensus?.image ? (
                          <img
                            width="80"
                            className="rounded-circle"
                            src={singleCensus?.image}
                            alt={singleCensus?.patientFullName}
                          />
                        ) : (
                          <div
                            className="circle rounded-circle"
                            style={{ margin: "auto" }}
                          >
                            <span className="initials">
                              {getFirstCharacters(
                                singleCensus?.resident?.resident_first_name +
                                  " " +
                                  singleCensus?.resident?.resident_last_name +
                                  " " +
                                  singleCensus?.resident?.resident_middle_name
                              )}
                            </span>
                          </div>
                        )}
                        <div className="card-body pb-0 pl-0 pr-0">
                          <h5 className="bold mb-0">
                            {singleCensus?.resident?.resident_first_name +
                              " " +
                              singleCensus?.resident?.resident_last_name +
                              " " +
                              singleCensus?.resident?.resident_middle_name}
                          </h5>

                          <table className="mb-0 table table-borderless mt-5">
                            <tbody>
                              <tr>
                                <td
                                  width="50%"
                                  style={{ borderRight: "2px solid #E5E8E8" }}
                                >
                                  <h5 className="bold card-title">
                                    {singleCensus?.residentId}
                                  </h5>
                                  <p className="text-mute font-small mt--10 mb-0">
                                    ResidentId
                                  </p>
                                </td>
                                <td width="50%">
                                  <h5 className="bold card-title">
                                    {singleCensus?.residentAssignId}
                                  </h5>
                                  <p className="text-mute font-small mt--10 mb-0">
                                    ResidentAssignId
                                  </p>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                          <div width="100%" className="flex">
                            <div width="50%">
                              <h5 className="bold card-title">
                                {singleCensus?.resident?.resident_first_name}
                              </h5>
                              <p className="text-mute font-small mt--10 mb-0">
                                Firstname
                              </p>
                            </div>
                            <div width="50%">
                              <h5 className="bold card-title">
                                {singleCensus?.resident?.resident_last_name}
                              </h5>
                              <p className="text-mute font-small mt--10 mb-0">
                                Lastname
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-8 pl-0">
                    <div className="main-card mb-3 card min-h-300">
                      <div className="card-body">
                        <h5 className="card-title">Resident Details</h5>
                        <table className="mb-0 table table-borderless">
                          <tbody>
                            <tr>
                              <td>
                                <p className="bold">Gender</p>
                                <p className="text-mute font-12 mt--10">
                                  {
                                    singleCensus?.residentDetail
                                      ?.resident_gender
                                  }
                                </p>
                              </td>
                              <td>
                                <p className="bold">Age</p>
                                <p className="text-mute font-12 mt--10">
                                  {singleCensus?.residentDetail?.resident_age}
                                </p>
                              </td>
                              <td>
                                <p className="bold">Is Living There?</p>
                                <p className="text-mute font-12 mt--10">
                                  {
                                    singleCensus?.residentDetail
                                      ?.resident_is_resident_living_there
                                  }
                                </p>
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <p className="bold">Marital Status</p>
                                <p className="text-mute font-12 mt--10">
                                  {
                                    singleCensus?.residentDetail
                                      ?.resident_marital_status
                                  }
                                </p>
                              </td>
                              <td>
                                <p className="bold">State</p>
                                <p className="text-mute font-12 mt--10">
                                  {
                                    singleCensus?.residentAddress
                                      ?.resident_province
                                  }
                                </p>
                              </td>
                              <td>
                                <p className="bold">District</p>
                                <p className="text-mute font-12 mt--10">
                                  {
                                    singleCensus?.residentAddress
                                      ?.resident_district
                                  }
                                </p>
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <p className="bold">City</p>
                                <p className="text-mute font-12 mt--10">
                                  {singleCensus?.residentAddress?.resident_city}
                                </p>
                              </td>
                              <td>
                                <p className="bold">Village</p>
                                <p className="text-mute font-12 mt--10">
                                  {
                                    singleCensus?.residentAddress
                                      ?.resident_village
                                  }
                                </p>
                              </td>
                              <td>
                                <p className="bold">Ward</p>
                                <p className="text-mute font-12 mt--10">
                                  {singleCensus?.residentAddress?.resident_ward}
                                </p>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="mb-3 card h-400 mt--10">
                  <div className="card-body">
                    <h5 className="card-title">Resident Details</h5>
                    <table className="mb-0 table table-borderless">
                      <tbody>
                        <tr>
                          <td>
                            <p className="bold">Ethnicity</p>
                            <p className="text-mute font-12 mt--10">
                              {
                                singleCensus?.residentDetailFinal
                                  ?.resident_ethnicity
                              }
                            </p>
                          </td>
                          <td>
                            <p className="bold">No of People</p>
                            <p className="text-mute font-12 mt--10">
                              {
                                singleCensus?.residentDetailFinal
                                  ?.resident_no_of_people
                              }
                            </p>
                          </td>
                          <td>
                            <p className="bold">Respondent Aggreement?</p>
                            <p className="text-mute font-12 mt--10">
                              {
                                singleCensus?.residentDetailFinal
                                  ?.resident_respondent_aggreement
                              }
                            </p>
                          </td>
                          <td>
                            <p className="bold">Taken Interview Previously?</p>
                            <p className="text-mute font-12 mt--10">
                              {
                                singleCensus?.residentDetailFinal
                                  ?.resident_taken_interview_previously
                              }
                            </p>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <p className="bold">Head First Name</p>
                            <p className="text-mute font-12 mt--10">
                              {
                                singleCensus?.residentHead
                                  ?.resident_head_first_name
                              }
                            </p>
                          </td>
                          <td>
                            <p className="bold">Head Middle Name</p>
                            <p className="text-mute font-12 mt--10">
                              {singleCensus?.residentHead
                                ?.resident_head_middle_name || " "}
                            </p>
                          </td>
                          <td>
                            <p className="bold">Head Last Name</p>
                            <p className="text-mute font-12 mt--10">
                              {
                                singleCensus?.residentHead
                                  ?.resident_head_last_name
                              }
                            </p>
                          </td>
                          <td>
                            <p className="bold">Relation with Househead</p>
                            <p className="text-mute font-12 mt--10">
                              {
                                singleCensus?.residentHead
                                  ?.resident_relation_with_househead
                              }
                            </p>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <p className="bold">Latitude</p>
                            <p className="text-mute font-12 mt--10">
                              {singleCensus?.resident?.latitude}
                            </p>
                          </td>
                          <td>
                            <p className="bold">Longitude</p>
                            <p className="text-mute font-12 mt--10">
                              {singleCensus?.resident?.longitude}
                            </p>
                          </td>
                          <td>
                            <p className="bold">Entered Date</p>
                            <p className="text-mute font-12 mt--10">
                              {convertDate(
                                singleCensus?.enteredDateTime?.dateTime?.date
                              )}
                            </p>
                          </td>
                          <td>
                            <p className="bold">Entered Time</p>
                            <p className="text-mute font-12 mt--10">
                              {convertTime(
                                singleCensus?.enteredDateTime?.dateTime?.time
                              )}
                            </p>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
              <div className="col-md-4">
                <div className="main-card mb-3 card">
                  <div className="card-body">
                    <h5 className="card-title">Bar Code</h5>
                    <div className="card-body p-0">
                      <canvas id="mycanvas"></canvas>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
}

const convertDate = (date) => {
  if(!date) return;

  return `${date.year}/${date.month}/${date.day}`;
};

const convertTime = (time) => {
  if(!time) return;
  return `${time.hour}-${time.minute}`;
};
