import {
  CHW_ADD,
  CHW_ADD_FAILURE,
  CHW_ADD_SUCCESS,
  CHW_FETCH,
  CHW_FETCHED,
  LOGOUT_USER,
  CHW_PATIENT_FETCH,
  CHW_PATIENT_FETCHED,
  CHW_INFANT_FETCH,
  CHW_INFANT_FETCHED,
  CHW_CENSUS_FETCH,
  CHW_CENSUS_FETCHED,
  PATIENT_DELETED,
  INFANT_DELETED,
  STAFF_DELETED,
  CHW_SINGLE_FETCH,
  CHW_SINGLE_FETCHED,
  CHW_SINGLE_LOG_FETCH,
  CHW_SINGLE_LOG_FETCHED,
  CHW_UPDATE,
  CHW_UPDATE_SUCCESS,
  CHW_UPDATE_FAILURE,
  CHW_SINGLE_CHART_FETCHED,
  CHW_SINGLE_CHART_FETCH,
  CHW_SINGLE_CHART_FETCH_ERROR
} from "../constants/types";
import http from "../../utils/http";
import toast from "../../utils/toast";

export const fetchCHW =
  ({ perPage, currentPage }) =>
  (dispatch) => {
    dispatch({ type: CHW_FETCH });
    currentPage += 1;
    http
      .GET("CHW/get", { perPage, currentPage })
      .then((res) => {
        dispatch({ type: CHW_FETCHED, payload: res.data });
      })
      .catch((error) => {
        console.log(error.response.data?.code);
        if (error.response.data.code === "401") dispatch({ type: LOGOUT_USER });
        toast.error(error);
        dispatch({ type: CHW_SINGLE_CHART_FETCH_ERROR })
      });
  };

export const addCHW = (data, navigate) => (dispatch) => {
  dispatch({ type: CHW_ADD });
  http
    .POST("CHW/create", data)
    .then((res) => {
      toast.success("CHW successfully added");
      dispatch({ type: CHW_ADD_SUCCESS, payload: res.data });
      navigate("/staff");
    })
    .catch((error) => {
      if (error?.response?.data?.code === "401")
        dispatch({ type: LOGOUT_USER });
      toast.error(error);
      dispatch({
        type: CHW_ADD_FAILURE,
      });
    });
};
export const fetchPatientsByCHW =
  (id, { perPage, currentPage }) =>
  (dispatch) => {
    dispatch({ type: CHW_PATIENT_FETCH });
    currentPage += 1;
    http
      .GET("CHW/findpatient/" + id, { perPage, currentPage })
      .then((res) => {
        dispatch({ type: CHW_PATIENT_FETCHED, payload: res.data });
      })
      .catch((error) => {
        console.log(error.response.data?.code);
        if (error.response.data.code === "401") dispatch({ type: LOGOUT_USER });
        toast.error(error);
      });
  };

export const fetchChartByCHW = (id) => (dispatch) => {
  dispatch({ type: CHW_SINGLE_CHART_FETCH });
  http
    .GET("CHW/patientchart/" + id, {})
    .then((res) => {
      dispatch({ type: CHW_SINGLE_CHART_FETCHED, payload: res.data });
    })
    .catch((error) => {
      console.log(error.response.data?.code);
      if (error.response.data.code === "401") dispatch({ type: LOGOUT_USER });
      toast.error(error);
    });
};

export const fetchPatientsChartByCHW =
  (id, { perPage, currentPage }) =>
  (dispatch) => {
    dispatch({ type: CHW_PATIENT_FETCH });
    currentPage += 1;
    http
      .GET("CHW/findpatient/" + id, { perPage, currentPage })
      .then((res) => {
        dispatch({ type: CHW_PATIENT_FETCHED, payload: res.data });
      })
      .catch((error) => {
        console.log(error.response.data?.code);
        if (error.response.data.code === "401") dispatch({ type: LOGOUT_USER });
        toast.error(error);
      });
  };
export const fetchinfantsByCHW =
  (id, { perPage, currentPage }) =>
  (dispatch) => {
    dispatch({ type: CHW_INFANT_FETCH });
    currentPage += 1;
    http
      .GET("CHW/findinfant/" + id, { perPage, currentPage })
      .then((res) => {
        dispatch({ type: CHW_INFANT_FETCHED, payload: res.data });
      })
      .catch((error) => {
        console.log(error.response.data?.code);
        if (error.response.data.code === "401") dispatch({ type: LOGOUT_USER });
        toast.error(error);
      });
  };

export const fetchcensusByCHW =
  (id, { perPage, currentPage }) =>
  (dispatch) => {
    dispatch({ type: CHW_CENSUS_FETCH });
    currentPage += 1;
    http
      .GET("CHW/findcensus/" + id, { perPage, currentPage })
      .then((res) => {
        console.log(res.data);
        dispatch({ type: CHW_CENSUS_FETCHED, payload: res.data });
      })
      .catch((error) => {
        console.log(error.response.data?.code);
        if (error.response.data.code === "401") dispatch({ type: LOGOUT_USER });
        toast.error(error);
      });
  };
export const removePatient = (id) => (dispatch) => {
  http
    .REMOVE("Patient/delete/" + id)
    .then((res) => {
      toast.success(res.data.message);
      dispatch({ type: PATIENT_DELETED, payload: id });
    })
    .catch((error) => {
      console.log(error.response.data?.code);
      if (error.response.data.code === "401") dispatch({ type: LOGOUT_USER });
      toast.error(error);
    });
};
export const removeInfant = (id) => (dispatch) => {
  http
    .REMOVE("Infant/delete/" + id)
    .then((res) => {
      toast.success(res.data.message);
      dispatch({ type: INFANT_DELETED, payload: id });
    })
    .catch((error) => {
      console.log(error.response.data?.code);
      if (error.response.data.code === "401") dispatch({ type: LOGOUT_USER });
      toast.error(error);
    });
};
export const removeStaff = (id) => (dispatch) => {
  http
    .REMOVE("CHW/delete/" + id)
    .then((res) => {
      toast.success(res.data.message);
      dispatch({ type: STAFF_DELETED, payload: id });
    })
    .catch((error) => {
      console.log(error.response.data?.code);
      if (error.response.data.code === "401") dispatch({ type: LOGOUT_USER });
      toast.error(error);
    });
};
export const fetchCHWSingle = (id) => (dispatch) => {
  dispatch({ type: CHW_SINGLE_FETCH });
  http
    .GET("CHW/details/" + id)
    .then((res) => {
      dispatch({ type: CHW_SINGLE_FETCHED, payload: res.data });
    })
    .catch((error) => {
      if (error.response.data.code === "401") dispatch({ type: LOGOUT_USER });
      toast.error(error);
    });
};

export const checkSyncHistory = (id) => (dispatch) => {
  dispatch({ type: CHW_SINGLE_LOG_FETCH });
  http
    .GET("checkSyncHistory/" + id)
    .then((res) => {
      dispatch({ type: CHW_SINGLE_LOG_FETCHED, payload: res.data });
    })
    .catch((error) => {
      if (error.response.data.code === "401") dispatch({ type: LOGOUT_USER });
      toast.error(error);
    });
};

export const updateCHW = (data, id) => (dispatch) => {
  dispatch({ type: CHW_UPDATE });
  http
    .PUT("/CHW/update/" + id, data)
    .then((res) => {
      dispatch({ type: CHW_UPDATE_SUCCESS, payload: res.data });
      toast.success("Staff Updated Successfully.");
    })
    .catch((error) => {
      if (error.response.data.code === "401") dispatch({ type: LOGOUT_USER });
      toast.error(error);
      dispatch({ type: CHW_UPDATE_FAILURE });
    });
};
