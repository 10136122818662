export const LOGIN_USER = "LOGIN_USER";
export const LOGOUT_USER = "LOGOUT_USER";

export const LOGIN_START = "LOGIN_START";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_FAIL = "LOGIN_FAIL";

export const PATIENT_FETCH = "PATIENT_FETCH";
export const PATIENT_FETCHED = "PATIENT_FETCHED";
export const PATIENT_SINGLE_FETCH = "PATIENT_SINGLE_FETCH";
export const PATIENT_SINGLE_FETCHED = "PATIENT_SINGLE_FETCHED";

export const INFANT_FETCH = "INFANT_FETCH";
export const INFANT_FETCHED = "INFANT_FETCHED";
export const INFANT_SINGLE_FETCH = "INFANT_SINGLE_FETCH";
export const INFANT_SINGLE_FETCHED = "INFANT_SINGLE_FETCHED";

export const CHW_FETCH = "CHW_FETCH";
export const CHW_FETCHED = "CHW_FETCHED";
export const CHW_ADD = "CHW_ADD";
export const CHW_ADD_SUCCESS = "CHW_ADD_SUCCESS";
export const CHW_ADD_FAILURE = "CHW_ADD_FAILURE";

export const CENSUS_FETCH = "CENSUS_FETCH";
export const CENSUS_FETCHED = "CENSUS_FETCHED";
export const CENSUS_SINGLE_FETCH = "CENSUS_SINGLE_FETCH";
export const CENSUS_SINGLE_FETCHED = "CENSUS_SINGLE_FETCHED";
export const CHW_PATIENT_FETCH = "CHW_PATIENT_FETCH";
export const CHW_PATIENT_FETCHED = "CHW_PATIENT_FETCHED";
export const CHW_INFANT_FETCH = "CHW_INFANT_FETCH";
export const CHW_INFANT_FETCHED = "CHW_INFANT_FETCHED";
export const CHW_CENSUS_FETCH = "CHW_CENSUS_FETCH";
export const CHW_CENSUS_FETCHED = "CHW_CENSUS_FETCHED";

export const DOCTOR_SINGLE_FETCH = "DOCTOR_SINGLE_FETCH";
export const DOCTOR_SINGLE_FETCHED = "DOCTOR_SINGLE_FETCHED";
export const DOCTOR_INFO_UPDATE = "DOCTOR_INFO_UPDATE";
export const DOCTOR_INFO_UPDATE_SUCCESS = "DOCTOR_INFO_UPDATE_SUCCESS";
export const DOCTOR_INFO_UPDATE_FAILURE = "DOCTOR_INFO_UPDATE_FAILURE";
export const DOCTOR_CHANGE_PASSWORD = "DOCTOR_CHANGE_PASSWORD";
export const DOCTOR_CHANGE_PASSWORD_SUCCESS = "DOCTOR_CHANGE_PASSWORD_SUCCESS";
export const DOCTOR_CHANGE_PASSWORD_FAILURE = "DOCTOR_CHANGE_PASSWORD_FAILURE";

export const PATIENT_DELETED = "PATIENT_DELETED";
export const INFANT_DELETED = "INFANT_DELETED";
export const CENSUS_DELETED = "CENSUS_DELETED";
export const STAFF_DELETED = "SAFF_DELETED";

export const PATIENT_UPDATE = "PATIENT_UPDATE";
export const PATIENT_UPDATE_SUCCESS = "PATIENT_UPDATE_SUCCESS";
export const PATIENT_UPDATE_FAILURE = "PATIENT_UPDATE_FAILURE";

export const DASH_INFO_FETCH = "DASH_INFO_FETCH";
export const DASH_INFO_FETCHED = "DASH_INFO_FETCHED";

export const INFANT_UPDATE = "INFANT_UPDATE";
export const INFANT_UPDATE_SUCCESS = "INFANT_UPDATE_SUCCESS";
export const INFANT_UPDATE_FAILURE = "INFANT_UPDATE_FAILURE";

export const CENSUS_UPDATE = "CENSUS_UPDATE";
export const CENSUS_UPDATE_SUCCESS = "CENSUS_UPDATE_SUCCESS";
export const CENSUS_UPDATE_FAILURE = "CENSUS_UPDATE_FAILURE";

export const CHW_SINGLE_FETCH = "CHW_SINGLE_FETCH";
export const CHW_SINGLE_FETCHED = "CHW_SINGLE_FETCHED";
export const CHW_SINGLE_LOG_FETCH = "CHW_SINGLE_LOG_FETCH";
export const CHW_SINGLE_LOG_FETCHED = "CHW_SINGLE_LOG_FETCHED";
export const CHW_SINGLE_CHART_FETCH = "CHW_SINGLE_CHART_FETCH";
export const CHW_SINGLE_CHART_FETCHED = "CHW_SINGLE_CHART_FETCHED";
export const CHW_SINGLE_CHART_FETCH_ERROR = "CHW_SINGLE_CHART_FETCH_ERROR";

export const CHW_UPDATE = "CHW_UPDATE";
export const CHW_UPDATE_SUCCESS = "CHW_UPDATE_SUCCESS";
export const CHW_UPDATE_FAILURE = "CHW_UPDATE_FAILURE";

export const PATIENT_LOCATION_FETCH = "PATIENT_LOCATION_FETCH";
export const PATIENT_LOCATION_FETCHED = "PATIENT_LOCATION_FETCHED";
export const CENSUS_LOCATION_FETCH = "CENSUS_LOCATION_FETCH";
export const CENSUS_LOCATION_FETCHED = "CENSUS_LOCATION_FETCHED";
export const CENSUS_CHART_FETCH = "CENSUS_CHART_FETCH";
export const CENSUS_CHART_FETCHED = "CENSUS_CHART_FETCHED";
