import Login from "./../pages/Login/index";
import { Routes, Route, Navigate } from "react-router-dom";
import ProtectedLayouts from "../layouts/ProtectedLayouts";
import Dashboard from "../pages/Dashboard";
import Patient from "../pages/Patient";
import Staff from "../pages/Staff";
import Message from "../pages/Message";
import Payment from "../pages/Payment";
import Schedule from "../pages/Schedule";
import Setting from "../pages/Setting";
import PDetails from "../pages/Patient/Components/PDetails";
import Census from "../pages/Census";
import CDetails from "../pages/Census/Components/CDetails";
import IDetails from "../pages/Patient/Components/IDetails";
import StaffCreate from "../pages/Staff/Components/Create";
import CHWPatientList from "../pages/Staff/Components/CHWPatientList";
import Analytics from "../pages/Aanalytics";
import PUpdate from "../pages/Patient/Components/PUpdate";
import IUpdate from "../pages/Patient/Components/IUpdate";
import CUpdate from "../pages/Census/Components/CUpdate";
import StaffUpdate from "../pages/Staff/Components/Update";

const AppRoute = () => {
  return (
    <Routes>
      <Route path="/login" element={<Login />} />
      <Route path="/" element={<ProtectedLayouts />}>
        <Route index element={<Dashboard />} />
        <Route path="/patient">
          <Route index element={<Patient />} />
          <Route path="patient_detail/:id" element={<PDetails />} />
          <Route path="infant_detail/:id" element={<IDetails />} />
          <Route path="pupdate/:id" element={<PUpdate />} />{" "}
          <Route path="iupdate/:id" element={<IUpdate />} />
        </Route>
        <Route path="staff">
          <Route index element={<Staff />} />
          <Route path="patient_list/:patientId/census_detail/:id" element={<CDetails />} />
          <Route path="patient_list/:id" element={<CHWPatientList />} />
          <Route path="spatient_detail/:id" element={<PDetails />} />
          <Route path="sinfant_detail/:id" element={<IDetails />} />
          <Route path="create" element={<StaffCreate />} />
          <Route path="update/:id" element={<StaffUpdate />} />
          <Route path="pupdate/:id" element={<PUpdate />} />
          <Route path="iupdate/:id" element={<IUpdate />} />
        </Route>
        <Route path="census">
          <Route index element={<Census />} />
          <Route path="census_detail/:id" element={<CDetails />} />
          <Route path="update/:id" element={<CUpdate />} />
        </Route>
        <Route path="message" element={<Message />} />
        <Route path="payment" element={<Payment />} />
        <Route path="schedule" element={<Schedule />} />{" "}
        <Route path="analytics" element={<Analytics />} />
        <Route path="settings" element={<Setting />} />
      </Route>
    </Routes>
  );
};

export default AppRoute;

function NotFoundPage() {
  return <h1>Page Not Found</h1>;
}
