import {
  LOGOUT_USER,
  LOGIN_SUCCESS,
  LOGIN_FAIL,
  LOGIN_START,
} from "./../constants/types";

const initialValue = {
  message: null,
  isLoggedIn: !!localStorage.getItem("token"),
  loading: false,
};

function reducer(state = initialValue, action) {
  switch (action.type) {
    case LOGIN_START:
      return {
        loading: true,
      };
    case LOGIN_SUCCESS:
      localStorage.setItem("token", action.payload?.details.token);
      localStorage.setItem("user", action.payload?.details.name);
      return {
        loading: false,
        isLoggedIn: true,
      };
    case LOGIN_FAIL:
      return {
        loading: false,
        message: action.message,
      };
    case LOGOUT_USER:
      localStorage.clear();
      return {
        isLoggedIn: false,
      };
    default:
      return {
        ...state,
      };
  }
}

export default reducer;
