import {
  CHW_ADD,
  CHW_ADD_FAILURE,
  CHW_ADD_SUCCESS,
  CHW_FETCH,
  CHW_FETCHED,
  CHW_INFANT_FETCH,
  CHW_INFANT_FETCHED,
  CHW_PATIENT_FETCH,
  CHW_PATIENT_FETCHED,
  CHW_CENSUS_FETCH,
  CHW_CENSUS_FETCHED,
  CHW_SINGLE_FETCH,
  CHW_SINGLE_FETCHED,
  CHW_SINGLE_LOG_FETCH,
  CHW_SINGLE_LOG_FETCHED,
  CHW_SINGLE_CHART_FETCH,
  CHW_SINGLE_CHART_FETCHED,
  CHW_SINGLE_CHART_FETCH_ERROR,
  CHW_UPDATE,
  CHW_UPDATE_FAILURE,
  CHW_UPDATE_SUCCESS,
  INFANT_DELETED,
  PATIENT_DELETED,
  STAFF_DELETED,
} from "./../constants/types";

const initialValue = {
  staffs: [],
  loading: false,
  loadingLog: false,
  loadingChart: false,
  chart: null,
  log: null,
  total: 0,
  addBtnLoading: false,
  isAdded: false,
  patients: [],
  infants: [],
  staff: {},
  updating: false,
};

function reducer(state = initialValue, action) {
  switch (action.type) {
    case CHW_FETCH:
      return {
        loading: true,
      };
    case CHW_FETCHED:
      return {
        loading: false,
        staffs: action.payload.chwsList,
        total: action.payload.total,
      };

    case CHW_ADD:
      return {
        ...state,
        addBtnLoading: true,
      };
    case CHW_ADD_SUCCESS:
      return {
        ...state,
        staffs: [action.payload.details, ...state.staffs],
        addBtnLoading: false,
        isAdded: true,
      };
    case CHW_ADD_FAILURE:
      return {
        ...state,
        addBtnLoading: false,
      };
    case CHW_PATIENT_FETCH:
      return {
        loading: true,
      };
    case CHW_PATIENT_FETCHED:
      return {
        ...state,
        loading: false,
        patients: action.payload.patients,
        total: action.payload.total,
      };
    case CHW_INFANT_FETCH:
      return {
        loading: true,
      };
    case CHW_INFANT_FETCHED:
      return {
        ...state,
        loading: false,
        infants: action.payload.infants,
        total: action.payload.total,
      };
    case CHW_CENSUS_FETCH:
      return {
        loading: true,
      };
    case CHW_CENSUS_FETCHED:
      return {
        ...state,
        loading: false,
        censuses: action.payload.censuses,
        total: action.payload.total,
      };
    case PATIENT_DELETED:
      const deleted = state.patients.filter(
        (item) => item.patientId !== action.payload
      );
      const total = state.total - 1;

      return {
        ...state,
        patients: deleted,
        total,
      };
    case INFANT_DELETED:
      const deletedi = state.infants.filter(
        (item) => item.infantId !== action.payload
      );
      const totalInfant = state.total - 1;
      return {
        ...state,
        infants: deletedi,
        totalInfant,
      };
    case STAFF_DELETED:
      const deletedSTAFF = state.staffs.filter(
        (item) => item.chw_id !== action.payload
      );
      const totalstaff = state.total - 1;
      return {
        ...state,
        staffs: deletedSTAFF,
        total: totalstaff,
      };

    case CHW_SINGLE_FETCH:
      return {
        loading: true,
      };
    case CHW_SINGLE_FETCHED:
      return {
        ...state,
        loading: false,
        staff: action.payload.data,
      };
    case CHW_SINGLE_LOG_FETCH:
      return {
        ...state,
        loadingLog: true,
        log: null,
      };
    case CHW_SINGLE_LOG_FETCHED:
      return {
        ...state,
        loadingLog: false,
        log: action.payload.details,
      };
    case CHW_SINGLE_CHART_FETCH:
      return { ...state, loadingChart: true };
    case CHW_SINGLE_CHART_FETCHED:
      return { ...state, loadingChart: false, chart: action.payload.chart, x: action.payload.x, y: action.payload.y };
    case CHW_SINGLE_CHART_FETCH_ERROR:
      return {...state, loadingChart: false,}
      case CHW_UPDATE:
      return {
        ...state,
        updating: true,
      };

    case CHW_UPDATE_SUCCESS:
      return {
        ...state,
        updating: false,
      };
    case CHW_UPDATE_FAILURE:
      return {
        ...state,
        updating: false,
      };
    default:
      return {
        ...state,
      };
  }
}

export default reducer;
