import React, { useEffect } from "react";
import HighchartsReact from "highcharts-react-official";
import Highcharts from "highcharts";

function DashChart({censusChart}) {
  useEffect(() => {
    if (censusChart) {
        console.log(censusChart, "censusChart")
      Highcharts.chart("censusChart", {
        chart: {
          type: "spline",
          height: 330,
          backgroundColor: "#213155",
        },
        title: {
          text: " ",
        },
        legend: {
          itemStyle: {
            color: "#fff",
          },
        },
        xAxis: {
          categories: censusChart?.x ,
          labels: {
            style: {
              color: "#fff",
            },
          },
        },
        yAxis: {
          title: {
            text: "",
          },
          labels: {
            style: {
              color: "#fff",
            },
          },
        },
        tooltip: {
          shared: true,
          valueSuffix: " units",
        },
        credits: {
          enabled: false,
        },

        responsive: {
          rules: [
            {
              condition: {
                maxHeight: 200,
              },
            },
          ],
        },
        colors: ["#5698F0", "#DF7383"],
        series: [
          {
            name: "This Week",
            data: censusChart?.y,
          },
        ],
      });
    }
  }, [censusChart]);
  return <div id="censusChart"></div>;
}

export default DashChart;
